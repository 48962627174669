import { Action, createReducer, on } from '@ngrx/store';
import {
    getRequestAssessmentEmailsRequestSuccess,
    getRequestAssessmentsRequestSuccess,
    getRequestLatestAssessmentRequestFailed,
    getRequestLatestAssessmentRequestSuccess,
} from './actions/assessments.actions';
import { getRelationshipSuccess } from './actions/relationship.actions';
import {
    combineSupplementalQuestionnaireConfiguration,
    getRequestPrimaryVendorContactRequestSuccess,
    resetRelationshipState,
} from './request.actions';
import {
    initialIiqrResponsesState,
    initialRelationshipFrameworkMappingsState,
    initialState,
    LastInteractiveIqrResponsesByRelationshipState,
    RelationshipFrameworkMappingsState,
    RelationshipState,
} from './request.state';
import {
    askQuestionRequestFailed,
    askQuestionRequestSuccess,
    loadLocalStorageIiqrResponsesState,
} from './actions/question-answering.actions';
import { getRelationshipArtifactSupersessionRequestSuccess } from './actions/artifacts.actions';
import { getRelationshipAssessmentSummaryConfigRequestSuccess } from './actions/assessment-summary-config.actions';
import { getFrameworkMappingsRequestSuccess } from './actions/framework-mapping.actions';
import { getBusinessUnitsRequestSuccess } from '../../business-unit-management/redux/business-unit-management.actions';
import { getContactsRequestSuccess } from '../../contact-management/redux/contact-management.actions';
import { getOrgDomainsRequestSuccess } from './actions/organization.actions';

const reducerRelationship = createReducer(
    initialState,
    on(resetRelationshipState, (state) => ({
        relationshipId: null,
        assessments: {
            latestAssessmentLoaded: false,
            latestAssessment: null,
            allAssessments: [],
            propertiesMap: {},
        },
        primaryVendorContact: null,
        suggestedVendorContacts: [],
        artifactSupersession: new Map<number, number>(),
        businessOwner: null,
        subscribers: [],
        assessmentSummaryConfig: [],
        supplementalQuestionnaire: {
            usingOrgDefaults: false,
            questionnaires: [],
        },
        mappings: null,
        vendorName: null,
        clientId: null,
        riskAccepted: false,
        latestRiskAssessment: null,
        riskOverride: {
            riskLevel: null,
            lastAppliedByFirstName: null,
            lastAppliedByLastName: null,
            lastAppliedByEmail: null,
            lastAppliedDate: null,
        },
        clientContacts: [],
        businessUnits: [],
        clientDomains: [],
        dataTypes: [],
    })),
    on(getRelationshipSuccess, (state, { relationship }) => ({
        ...state,
        relationshipId: relationship.id,
        primaryVendorContact: relationship.primaryContact,
        suggestedVendorContacts: relationship.vendorSuggestedContacts,
        businessOwner: relationship.businessOwner,
        subscribers: relationship.subscribers,
        assessmentSummaryConfig: relationship.assessmentSummarySections,
        vendorName: relationship.vendorName,
        clientId: relationship.clientId,
        riskAccepted: relationship.riskAccepted,
        latestRiskAssessment: relationship.latestRiskAssessment,
        riskOverride: {
            riskLevel: relationship.latestRiskAssessment.legacy ? relationship.latestRiskAssessment.riskLevel : null,
            lastAppliedByFirstName: relationship.latestRiskAssessment.lastUserToSetLegacyFirstName,
            lastAppliedByLastName: relationship.latestRiskAssessment.lastUserToSetLegacyLastName,
            lastAppliedByEmail: relationship.latestRiskAssessment.lastUserToSetLegacyEmail,
            lastAppliedDate: relationship.riskOverrideAppliedDate,
        },
        dataTypes: relationship.dataTypes,
    })),
    on(getRelationshipAssessmentSummaryConfigRequestSuccess, (state, { assessmentSummarySections }) => ({
        ...state,
        assessmentSummaryConfig: assessmentSummarySections,
    })),
    on(getRequestPrimaryVendorContactRequestSuccess, (state, { primaryVendorContact }) => ({
        ...state,
        primaryVendorContact,
    })),
    on(getRequestAssessmentsRequestSuccess, (state, { assessments }) => ({
        ...state,
        assessments: {
            ...state.assessments,
            allAssessments: assessments,
        },
    })),
    on(getRequestLatestAssessmentRequestSuccess, (state, { latestAssessment }) => ({
        ...state,
        assessments: {
            ...state.assessments,
            latestAssessment,
            latestAssessmentLoaded: true,
        },
    })),
    on(getRequestLatestAssessmentRequestFailed, (state) => ({
        ...state,
        assessments: {
            ...state.assessments,
            latestAssessment: null,
            latestAssessmentLoaded: true,
        },
    })),
    on(getRequestAssessmentEmailsRequestSuccess, (state, { assessmentId, emails }) => {
        const newMap = { ...state.assessments.propertiesMap };
        newMap[assessmentId] = { emails };
        return {
            ...state,
            assessments: {
                ...state.assessments,
                propertiesMap: newMap,
            },
        };
    }),
    on(getRelationshipArtifactSupersessionRequestSuccess, (state, { artifactSupersession }) => ({
        ...state,
        artifactSupersession,
    })),
    on(getBusinessUnitsRequestSuccess, (state, { businessUnits }) => ({
        ...state,
        businessUnits,
    })),
    on(getContactsRequestSuccess, (state, { contacts }) => ({
        ...state,
        clientContacts: contacts,
    })),
    on(getOrgDomainsRequestSuccess, (state, { orgDomains }) => ({
        ...state,
        clientDomains: orgDomains,
    })),
    on(combineSupplementalQuestionnaireConfiguration, (state, { usingOrgDefaults, questionnaires }) => ({
        ...state,
        supplementalQuestionnaire: {
            usingOrgDefaults: usingOrgDefaults,
            questionnaires: questionnaires,
        },
    })),
);

const reducerIiqr = createReducer(
    initialIiqrResponsesState,
    on(loadLocalStorageIiqrResponsesState, (state, { localStorageState }) => ({ ...state, ...localStorageState })),
    on(askQuestionRequestSuccess, (state, { relationshipId, iqrResponse }) => ({
        ...state,
        [relationshipId]: iqrResponse,
    })),
    on(askQuestionRequestFailed, (state, { relationshipId }) => ({
        ...state,
        [relationshipId]: null,
    })),
);

export function relationshipReducer(state: RelationshipState = initialState, action: Action): RelationshipState {
    return reducerRelationship(state, action);
}

export function iiqrResponsesReducer(
    state: LastInteractiveIqrResponsesByRelationshipState = initialIiqrResponsesState,
    action: Action,
): LastInteractiveIqrResponsesByRelationshipState {
    return reducerIiqr(state, action);
}

const frameworkMappings = createReducer(
    initialRelationshipFrameworkMappingsState,
    on(getFrameworkMappingsRequestSuccess, (state, { relationshipId, mappings, frameworkMappingType }) => ({
        ...state,
        mappings,
        [relationshipId]: frameworkMappingType,
    })),
);

export function frameworkMappingsReducer(
    state: RelationshipFrameworkMappingsState = initialRelationshipFrameworkMappingsState,
    action: Action,
): RelationshipFrameworkMappingsState {
    return frameworkMappings(state, action);
}
