import { Component, OnInit } from '@angular/core';
import { SnackbarService } from '@shared/components/snackbar/snackbar.service';
import { Store } from '@ngrx/store';
import {
    confirmUploadedImportRequest,
    confirmUploadedImportRequestFailed,
    confirmUploadedImportRequestSuccess,
    uploadImportCsvRequest,
    uploadImportCsvRequestCancelled,
    uploadImportCsvRequestFailed,
    uploadImportCsvRequestProgress,
    uploadImportCsvRequestSuccess,
} from '../redux/bulk-import.actions';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { BulkImportService, parseImportErrors } from '../bulk-import.service';

@Component({
    selector: 'app-import-relationships',
    templateUrl: './import-relationships.component.html',
    styleUrls: ['./import-relationships.component.scss'],
})
export class ImportRelationshipsComponent implements OnInit {
    uploadedFileName: string;

    numberOfRelationshipsToImport: number;
    importErrors: { key: string; value: string }[] = [];
    importJobId: number;
    supportId: string;

    fileToUpload: File;

    isUploading: boolean;
    isUploaded: boolean;
    hasExistingRelationships: boolean = true;

    errorUploading: boolean;
    processingImport: boolean;
    percentUploaded: number;

    private readonly newRelationshipsTemplate: string =
        'https://static.visotrust.com/csv/new_relationship_template.csv';
    private readonly existingRelationshipsTemplate: string =
        'https://static.visotrust.com/csv/existing_relationship_template.csv';

    private _unsub = new Subject<void>();

    constructor(
        private _snackbarService: SnackbarService,
        private _store$: Store,
        private _actions$: Actions,
        private _importService: BulkImportService,
    ) {}

    get isImportDisabled(): boolean {
        return (
            (!this.isUploaded && !this.uploadedFileName) ||
            this.errorUploading ||
            this.isUploading ||
            this.numberOfRelationshipsToImport === 0 ||
            this.processingImport
        );
    }

    ngOnInit(): void {
        this.isUploaded = false;
        this.isUploading = false;

        this._actions$
            .pipe(
                ofType(uploadImportCsvRequestFailed),
                tap(() => {
                    this.resetUpload();
                    this._snackbarService.error('An error occurred when uploading the csv.');
                }),
                takeUntil(this._unsub),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(confirmUploadedImportRequestSuccess, confirmUploadedImportRequestFailed),
                tap(() => {
                    this.resetUpload();
                }),
                takeUntil(this._unsub),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(uploadImportCsvRequestProgress),
                tap(({ percent }) => {
                    this.percentUploaded = percent;
                }),
                takeUntil(this._unsub),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(uploadImportCsvRequestSuccess),
                tap(({ importReport }) => {
                    this.importJobId = importReport?.importJob?.id;
                    this.supportId = importReport?.importJob?.supportId;
                    const importErrors: Map<string, string> = parseImportErrors(importReport?.errors);
                    if (importReport?.errors?.errorCount > 0) {
                        this.errorUploading = true;
                        for (let errorType in importErrors) {
                            this.importErrors.push(importErrors[errorType]);
                        }
                    }

                    this.numberOfRelationshipsToImport = this._importService.parseImportDataByLine(
                        importReport?.relationshipsByLine,
                    ).length;

                    this.isUploading = false;
                    this.isUploaded = true;
                }),
                takeUntil(this._unsub),
            )
            .subscribe();
    }

    uploadFile(event: any): void {
        if (event.target && event.target.files.length === 1) {
            this.fileToUpload = event.target.files[0];
        } else {
            this.fileToUpload = event[0];
        }
        this.uploadImport(this.fileToUpload);
    }

    uploadImport(file: File) {
        if (this.validateMimeType(file) && this.validateFileSize(file) && this.validateFileLength(file)) {
            this.isUploading = true;
            this.uploadedFileName = file.name;
            this._store$.dispatch(
                uploadImportCsvRequest({
                    file: file,
                }),
            );
        } else {
            this.resetUpload();
        }
    }

    cancelUpload(): void {
        this._store$.dispatch(uploadImportCsvRequestCancelled());
        this.resetUpload();
    }

    resetUpload(): void {
        this.resetState();
        this.uploadedFileName = null;
        this.fileToUpload = null;
    }

    resetState() {
        this.isUploaded = false;
        this.isUploading = false;
        this.percentUploaded = 0;
        this.numberOfRelationshipsToImport = 0;
        this.importErrors = [];
        this.importJobId = null;
        this.hasExistingRelationships = false;
        this.errorUploading = false;
        this.processingImport = false;
    }

    doImport(): void {
        this.processingImport = true;
        this._store$.dispatch(confirmUploadedImportRequest({ importJobId: this.importJobId }));
    }

    getImportSpreadsheet() {
        window.location.href = this.hasExistingRelationships
            ? this.existingRelationshipsTemplate
            : this.newRelationshipsTemplate;
    }

    retryUpload() {
        this.resetState();
        this.uploadImport(this.fileToUpload);
    }

    private validateFileSize(file: File): boolean {
        if (file.size > 100000000 || file.size === 0) {
            this._snackbarService.error('File size must not be empty and less than 100MB.');
            return false;
        }
        return true;
    }

    private validateFileLength(file: File): boolean {
        if (file.name.length > 100) {
            this._snackbarService.error('File name too long. Max size: 100 characters');
            return false;
        }
        return true;
    }

    private validateMimeType(file: File): boolean {
        const isValidMimeType: boolean =
            file.type === 'application/csv' || file.type === 'text/csv' || file.type === 'application/vnd.ms-excel';
        if (!isValidMimeType) {
            this._snackbarService.error('Only csv files are supported');
        }
        return isValidMimeType;
    }
}
