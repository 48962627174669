import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Relationship, RequestStatus } from '../../../entities/relationship';
import { LicenseType, VisoUser, VisoUserRole } from '../../../entities/viso-user';
import { AssessmentStatus } from '../../../entities/assessment';
import { MarkActions } from '../redux/actions/mark.actions';
import { AssessmentUtilsService } from '@shared/utils/assessment-utils.service';

@Component({
    selector: 'app-head-button-group',
    templateUrl: './head-button-group.component.html',
    styleUrls: ['./head-button-group.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeadButtonGroupComponent {
    @Input()
    relationship: Relationship;

    @Input({ required: true })
    businessOwner: VisoUser;

    @Input()
    relationshipStatus: RequestStatus;

    @Input()
    latestAssessmentStatus: AssessmentStatus;

    @Input()
    onPrint: boolean;

    @Input()
    isUpForRecertification: boolean;

    @Input()
    hasOrgUserOnboardEnabled: boolean;

    @Input()
    currentUser: VisoUser;

    @Output()
    onboard = new EventEmitter<void>();

    @Output()
    archive = new EventEmitter<void>();

    @Output()
    markAsNotOnboarded = new EventEmitter<void>();

    @Output()
    startAssessment = new EventEmitter<void>();

    @Output()
    confirmRecertifyAssessment = new EventEmitter<void>();

    @Output()
    downloadPDF = new EventEmitter<void>();

    @Output()
    openCreateRiskAdvisory = new EventEmitter<void>();

    get isRelationshipArchived() {
        return this.relationshipStatus === RequestStatus.ARCHIVED;
    }

    get showStartAssessmentButton(): boolean {
        const latestAssessmentIsCompleteOrDoesNotExist =
            !this.latestAssessmentStatus || this._assessmentUtils.isAssessmentComplete(this.latestAssessmentStatus);
        return (
            latestAssessmentIsCompleteOrDoesNotExist &&
            !this.isRelationshipArchived &&
            !this.isUpForRecertification &&
            !this.isCurrentUserSupportOrReadOnly
        );
    }

    get showRecertifyButton(): boolean {
        return (
            !this.isRelationshipArchived &&
            this.isUpForRecertification &&
            this._assessmentUtils.isAssessmentComplete(this.latestAssessmentStatus) &&
            !this.isCurrentUserSupportOrReadOnly
        );
    }

    get showCreateRiskAdvisoryButton(): boolean {
        return !this.relationship?.vendorManagedById;
    }

    get doesCurrentUserHaveTrialLicense() {
        const currentUserLicenseType = this.currentUser?.clientLicense?.licenseType;
        return currentUserLicenseType == LicenseType.TRIAL;
    }

    get doesCurrentUserHaveProdTrialLicense() {
        const currentUserLicenseType = this.currentUser?.clientLicense?.licenseType;
        return currentUserLicenseType == LicenseType.PROD_TRIAL;
    }

    get showOnboardOffboardButton(): boolean {
        return (
            this.currentUser?.authorities.includes(VisoUserRole.OrgAdmin) ||
            this.isCurrentUserSupportOrReadOnly ||
            (this.currentUser?.authorities.includes(VisoUserRole.OrgUser) && this.hasOrgUserOnboardEnabled)
        );
    }

    get isCurrentUserSupportOrReadOnly(): boolean {
        return (
            this.currentUser?.authorities.includes(VisoUserRole.Support) ||
            this.currentUser?.authorities.includes(VisoUserRole.ReadOnly)
        );
    }

    RequestStatus = RequestStatus;
    MarkActions = MarkActions;
    LicenseType = LicenseType;
    Roles = VisoUserRole;

    constructor(private _assessmentUtils: AssessmentUtilsService) {}
}
