import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import {
    getImportedSupplementalQuestionnaireReport,
    getSupplementalQuestionnaireImportErrors,
    getSupplementalQuestionnaireImportFileName,
    getSupplementalQuestionnaireImportJobId,
    getSupplementalQuestionnaireImportPercentUploaded,
    getSupplementalQuestionnaires,
    getSupplementalQuestionnaireSupportId,
} from '../../redux/questionnaire-management.selectors';
import {
    cancelCreateSupplementalQuestionnaireDialog,
    deleteSupplementalQuestionnaireRequest,
    importSupplementalQuestionnaireRequest,
    importSupplementalQuestionnaireRequestCancelled,
    onWhenAddingImportFileFailed,
} from '../../redux/questionnaire-management.actions';
import { MatDialogRef } from '@angular/material/dialog';
import { noWhitespaceValidator } from '@shared/validators/whitespace-validator';
import { FormUtilsService } from '@shared/utils/form-utils.service';
import { MimeType } from '@shared/upload-artifacts';
import {
    SupplementalQuestionnaireResponse,
    SupplementalQuestionnaireWithQuestions,
} from '@entities/supplemental-questionnaire';
import { getUserAccount } from '../../../../session/redux/session.selectors';
import { VisoUser } from '@entities/viso-user';
import { map } from 'rxjs/operators';
import { getUserAuthority } from '../../../../session/redux/session.selectors';
import { VisoUserRole } from '@entities/viso-user';
import { takeUntil } from 'rxjs/operators';
import { CdkStepper } from '@angular/cdk/stepper';
import { FilterFunction } from '@shared/file-upload/interfaces';
import {
    CreateSupplementalQuestionnaireRequest,
    UpdateSupplementalQuestionnaireRequest,
} from '../../model/questionnaire-management-models';

export interface QuestionFormGroup {
    id?: FormControl<number>;
    question: FormControl<string>;

    //readonly
    editing: FormControl<boolean>;
}

export interface SupplementalQuestionnaireFormGroup {
    id: FormControl<string>;
    questionnaireName: FormControl<string>;
    enabled: FormControl<boolean>;
    questions: FormArray<FormGroup<QuestionFormGroup>>;
}

export enum SupplementalQuestionnaireDialogStep {
    IMPORT = 'IMPORT',
    CREATE = 'CREATE',
    EDIT = 'EDIT',
}

export enum SupplementalQuestionnaireDialogStepIndex {
    IMPORT = 0,
    MODIFY = 1,
}

@Component({
    selector: 'app-supplemental-questionnaire-dialog',
    templateUrl: './supplemental-questionnaire-dialog.component.html',
    styleUrls: ['./supplemental-questionnaire-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplementalQuestionnaireDialogComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input()
    questionnaireStep: SupplementalQuestionnaireDialogStep;

    @Input()
    questionnaireToEdit: SupplementalQuestionnaireWithQuestions;

    @Input()
    existingFormData: FormGroup<SupplementalQuestionnaireFormGroup>;

    @Input({ required: true })
    currentStepperIndex: SupplementalQuestionnaireDialogStepIndex;

    @ViewChild(CdkStepper)
    private _stepper: CdkStepper;

    SupplementalQuestionnaireDialogStepIndex = SupplementalQuestionnaireDialogStepIndex;

    importErrors$: Observable<{ key: string; value: string }[]>;
    importJobId$: Observable<number>;
    supportId$: Observable<string>;
    importPercentUploaded$: Observable<number>;
    importFileName$: Observable<string>;
    importedQuestions$: Observable<string[]>;
    supplementalQuestionnaires$: Observable<SupplementalQuestionnaireResponse[]>;
    currentUser$: Observable<VisoUser>;
    maxSupplementalQuestionnaireQuestions$: Observable<number>;

    isCurrentUserOrgAdmin: boolean;

    supplementalQuestionnaireFormGroup: FormGroup<SupplementalQuestionnaireFormGroup>;

    private _unsub$ = new Subject<void>();

    constructor(
        private _dialogRef: MatDialogRef<SupplementalQuestionnaireDialogComponent>,
        private _store$: Store,
        private _fb: FormBuilder,
        private _formUtils: FormUtilsService,
    ) {}

    get isEditing(): boolean {
        return this.questionnaireStep === SupplementalQuestionnaireDialogStep.EDIT;
    }

    ngOnInit(): void {
        this.supplementalQuestionnaireFormGroup = !!this.existingFormData
            ? this.existingFormData
            : this._fb.group({
                  id: this._fb.control(''),
                  questionnaireName: this._fb.control('', {
                      validators: [Validators.required, noWhitespaceValidator],
                  }),
                  questions: this._fb.array<FormGroup<QuestionFormGroup>>([]),
                  enabled: this._fb.control(true, { validators: [Validators.required] }),
              });

        this._store$
            .pipe(select(getUserAuthority(VisoUserRole.OrgAdmin)), takeUntil(this._unsub$))
            .subscribe((isCurrentUserOrgAdmin) => {
                if (!isCurrentUserOrgAdmin) {
                    this.supplementalQuestionnaireFormGroup.disable();
                }

                this.isCurrentUserOrgAdmin = isCurrentUserOrgAdmin;
            });

        this.importFileName$ = this._store$.select(getSupplementalQuestionnaireImportFileName);
        this.importErrors$ = this._store$.select(getSupplementalQuestionnaireImportErrors);
        this.importJobId$ = this._store$.select(getSupplementalQuestionnaireImportJobId);
        this.supportId$ = this._store$.select(getSupplementalQuestionnaireSupportId);
        this.importPercentUploaded$ = this._store$.select(getSupplementalQuestionnaireImportPercentUploaded);
        this.importedQuestions$ = this._store$.select(getImportedSupplementalQuestionnaireReport);
        this.supplementalQuestionnaires$ = this._store$.select(getSupplementalQuestionnaires);
        this.currentUser$ = this._store$.select(getUserAccount);
        this.maxSupplementalQuestionnaireQuestions$ = this.currentUser$.pipe(
            map((user) => user?.clientLicense.maxSupplementalQuestionnaireQuestions),
        );
    }

    ngAfterViewInit(): void {
        this._stepper.selectedIndex = this.currentStepperIndex;
    }

    cancelCreateQuestionnaireDialog(importJobId?: number) {
        if (this.supplementalQuestionnaireFormGroup.touched) {
            this._store$.dispatch(
                cancelCreateSupplementalQuestionnaireDialog({
                    supplementalQuestionnaireFormData: this.supplementalQuestionnaireFormGroup,
                    importJobId,
                    questionnaireStep: this.questionnaireStep,
                }),
            );
        } else {
            this._store$.dispatch(importSupplementalQuestionnaireRequestCancelled({ importJobId }));
            this._dialogRef.close();
        }
    }

    createQuestionnaire(importJobId?: number): void {
        const { questionnaireName, questions, enabled } = this._formUtils.getCleanTypedFormGroupValue(
            this.supplementalQuestionnaireFormGroup,
        );
        const requestPayload: CreateSupplementalQuestionnaireRequest = {
            questionnaireName,
            questions: questions.map((q) => q.question),
            importJobId,
            enabled,
        };
        this._dialogRef.close({ requestPayload, questionnaireStep: this.questionnaireStep });
    }

    updateQuestionnaire(): void {
        const { id, questionnaireName, questions, enabled } = this._formUtils.getCleanTypedFormGroupValue(
            this.supplementalQuestionnaireFormGroup,
        );
        const requestPayload: UpdateSupplementalQuestionnaireRequest = {
            id,
            questionnaireName,
            questions: questions.map((q) => {
                return { id: q.id, question: q.question };
            }),
            enabled,
        };
        this._dialogRef.close({ requestPayload, questionnaireStep: this.questionnaireStep });
    }

    importSupplementalQuestionnaire(file: File) {
        this._store$.dispatch(
            importSupplementalQuestionnaireRequest({
                file,
            }),
        );
    }

    onWhenAddingFileFailed(filter: FilterFunction) {
        let errorMessage: string;
        if (filter.name === 'fileSize') {
            errorMessage = 'File size must not be empty and less than 100MB';
        } else if (filter.name === 'mimeType') {
            errorMessage = `File type must be ${MimeType.CSV.extension}`;
        } else {
            errorMessage = `Filter failed: ${filter.name}`;
        }

        this._store$.dispatch(
            onWhenAddingImportFileFailed({
                message: errorMessage,
            }),
        );
    }

    deleteQuestionnaire() {
        const { id } = this._formUtils.getCleanTypedFormGroupValue(this.supplementalQuestionnaireFormGroup);
        this._store$.dispatch(deleteSupplementalQuestionnaireRequest({ questionnaireId: id }));
    }

    continueToNextStep() {
        this.currentStepperIndex++;
        this._stepper.selectedIndex = this.currentStepperIndex;
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
