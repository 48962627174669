import { RequestEffects } from '../request.effects';
import { ArtifactEffects } from './artifacts.effects';
import { AssessmentEffects } from './assessments.effects';
import { BusinessOwnerEffects } from './business-owner.effects';
import { CommentEffects } from './comments.effects';
import { RelationshipEffects } from './relationship.effects';
import { MarkEffects } from './mark.effects';
import { MentionableUsersEffects } from './mentionable-users.effects';
import { OnboardEffects } from './onboard.effects';
import { SecurityControlDomainEffects } from './security-control-domains.effects';
import { SubscriberEffects } from './subscriber.effects';
import { OrganizationEffects } from './organization.effects';
import { AttachmentEffects } from './attachment.effects';
import { QuestionAnsweringEffects } from './question-answering.effects';
import { LifecycleManagementEffects } from './lifecycle-management.effects';
import { VendorDetailsTabEffects } from './vendor-details-tab.effects';
import { RtpEffects } from './rtp.effects';
import { AssessmentSummaryConfigEffects } from './assessment-summary-config.effects';
import { RiskAdvisoryEffects } from './risk-advisory.effects';
import { FrameworkMappingsEffects } from './framework-mappings.effects';
import { SnackbarEffects } from './snackbar.effects';
import { SupplementalQuestionnaireEffects } from './supplemental-questionnaire.effects';

export const effects = [
    ArtifactEffects,
    AssessmentEffects,
    BusinessOwnerEffects,
    CommentEffects,
    RelationshipEffects,
    MentionableUsersEffects,
    OnboardEffects,
    MarkEffects,
    RequestEffects,
    AssessmentSummaryConfigEffects,
    SecurityControlDomainEffects,
    SubscriberEffects,
    OrganizationEffects,
    AttachmentEffects,
    QuestionAnsweringEffects,
    LifecycleManagementEffects,
    VendorDetailsTabEffects,
    RtpEffects,
    RiskAdvisoryEffects,
    FrameworkMappingsEffects,
    SnackbarEffects,
    SupplementalQuestionnaireEffects,
];
