import { ChangeDetectionStrategy, Component, computed, input, Signal } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Risk } from '@entities/risk-assessment';
import { SALES_EMAIL } from '@shared/constants/email.constants';
import { FollowupType, FollowupTypeLabels } from '@entities/assessment';

@Component({
    selector: 'app-followup-type-selection',
    templateUrl: './followup-type-selection.component.html',
    styleUrl: './followup-type-selection.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FollowupTypeSelectionComponent {
    followupTypeControl = input.required<FormControl<FollowupType>>();
    followupRiskThreshold = input.required<FormControl<Risk>>();
    conciergeEnabled = input.required<boolean>();
    remainingConciergeAssessments = input<number>();

    followupTypes: Signal<FollowupType[]>;

    FollowupType = FollowupType;
    FollowupTypeLabels = FollowupTypeLabels;
    Risk = Risk;
    SALES_EMAIL = SALES_EMAIL;

    constructor() {
        this.followupTypes = computed(() =>
            Object.values(FollowupType).filter((val) => this.conciergeEnabled() || val !== FollowupType.CONCIERGE),
        );
    }
}
