import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Artifact, ArtifactType, QuestionnaireArtifact } from '@entities/artifact';
import { QuestionnaireAnswer } from '@entities/assessment';
import { downloadArtifact } from '../request/redux/actions/artifacts.actions';
import { BREADCRUMB_CONTAINER_TOKEN } from '@shared/dynamic-content/dynamic-content-injector';
import moment from 'moment';
import { QuestionnaireRouteType } from './questionnaire.route';
import { FeatureFlagService } from '@shared/services/featureflag.service';
import { FeatureFlags } from '@shared/enums/feature-flags';

@Component({
    selector: 'app-questionnaire',
    templateUrl: './questionnaire.component.html',
    styleUrls: ['./questionnaire.component.scss'],
})
export class QuestionnaireComponent implements OnInit, OnDestroy {
    questionnaireArtifactId: number;
    questionnaireArtifact: QuestionnaireArtifact = null;
    requestId: number;
    requestArtifacts: Artifact[] = [];
    questionnaireArtifacts: Artifact[] = [];
    questionnaireAnswers: QuestionnaireAnswer[] = [];
    relationshipName: string;
    questionnaireRouteType: QuestionnaireRouteType;
    supplementalQuestionnairesEnabled$: Observable<boolean>;

    readonly QuestionnaireRouteType = QuestionnaireRouteType;
    readonly breadcrumbsContainerToken = BREADCRUMB_CONTAINER_TOKEN;

    private _unsub$: Subject<void> = new Subject<void>();

    get hasAdditionalCompletedQuestionnaires() {
        return this.questionnaireArtifacts.length > 1;
    }

    get currentQuestionnaire() {
        return this.questionnaireArtifact?.id;
    }

    get relativeCompletedDate() {
        const completedDate = this.questionnaireArtifact?.updatedDate;
        if (!completedDate) {
            return null;
        }
        return moment(completedDate).fromNow();
    }

    constructor(
        private _route: ActivatedRoute,
        private _store$: Store,
        private _router: Router,
        private _featureFlagService: FeatureFlagService,
    ) {}

    ngOnInit(): void {
        this._route.params.pipe(takeUntil(this._unsub$)).subscribe((params) => {
            this.requestId = params['relationshipId'];
        });

        this._route.data.pipe(takeUntil(this._unsub$)).subscribe((data) => {
            this.questionnaireArtifact = data.questionnaire;
            this.questionnaireAnswers = this.questionnaireArtifact?.answers;

            this.requestArtifacts = data.artifacts;
            this.questionnaireArtifacts = this.requestArtifacts?.filter(
                (artifact) => artifact.type === ArtifactType.QUESTIONNAIRE_ARTIFACT,
            );

            this.relationshipName = data.relationshipName;

            this.questionnaireRouteType = data.questionnaireRouteType;
            if (this.questionnaireRouteType === QuestionnaireRouteType.RELATIONSHIP) {
                this.filterArtifacts(this.questionnaireArtifact?.supplemental);
            }
        });

        this.supplementalQuestionnairesEnabled$ = this._featureFlagService.hasFeatureFlagEnabled(
            FeatureFlags.SUPPLEMENTAL_QUESTIONNAIRES,
        );
    }

    isQuestionnaireCurrent(artifact: Artifact): boolean {
        return artifact.id === this.currentQuestionnaire;
    }

    trackByQuestionnaireId(index: number, artifact: Artifact): number {
        return artifact.id;
    }

    filterArtifacts(isSupplemental: boolean) {
        this.questionnaireArtifacts = this.questionnaireArtifacts
            .filter((artifact) => (artifact as QuestionnaireArtifact).supplemental === isSupplemental)
            .sort((a, b) => {
                return new Date(b.updatedDate).getTime() - new Date(a.updatedDate).getTime();
            });
    }

    downloadQuestionnaire(): void {
        this._store$.dispatch(downloadArtifact({ artifactId: this.questionnaireArtifact.id }));
    }

    viewQuestionnaire(questionnaireId: number) {
        this.questionnaireArtifactId = questionnaireId;
        this._router.navigate(['/requests', this.requestId, 'questionnaire', this.questionnaireArtifactId]);
    }

    getQuestionnaireTitle(): string {
        if (
            this.questionnaireArtifact?.supplemental ||
            this.questionnaireRouteType === QuestionnaireRouteType.SUPPLEMENTAL_QUESTIONNAIRES
        ) {
            return 'Supplemental';
        } else if (this.questionnaireRouteType === QuestionnaireRouteType.TRUST_QUESTIONNAIRE) {
            return 'Trust';
        } else {
            return '';
        }
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
