import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SlimRiskAssessment } from '@entities/risk-assessment';
import { MatDialogRef } from '@angular/material/dialog';

export enum ReviewFollowupDialogAction {
    COMPLETE_ASSESSMENT,
    SEND_FOLLOWUP,
}

@Component({
    selector: 'app-review-followup-dialog',
    templateUrl: './review-followup-dialog.component.html',
    styleUrl: './review-followup-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewFollowupDialogComponent {
    @Input({ required: true })
    supplementalQuestionnairesEnabled: boolean;

    @Input({ required: true })
    latestRiskAssessment: SlimRiskAssessment;

    @Input({ required: true })
    followupControlDomainNames: string[];

    @Input({ required: true })
    unansweredSupplementalQuestions: string[];

    showAllQuestions = false;

    constructor(private _dialogRef: MatDialogRef<ReviewFollowupDialogComponent, ReviewFollowupDialogAction>) {}

    completeAssessment(): void {
        this._dialogRef.close(ReviewFollowupDialogAction.COMPLETE_ASSESSMENT);
    }

    sendFollowup(): void {
        this._dialogRef.close(ReviewFollowupDialogAction.SEND_FOLLOWUP);
    }
}
