<mat-dialog-content>
    <app-custom-stepper>
        <cdk-step>
            <app-supplemental-questionnaire-import
                [importErrors]="importErrors$ | async"
                [importFileName]="importFileName$ | async"
                [importPercentUploaded]="importPercentUploaded$ | async"
                [supportId]="supportId$ | async"
                (importSupplementalQuestionnaire)="importSupplementalQuestionnaire($event)"
                (onWhenAddingFileFailed)="onWhenAddingFileFailed($event)"
                [maxSupplementalQuestionnaireQuestions]="maxSupplementalQuestionnaireQuestions$ | async"
                [supplementalQuestionnaires]="supplementalQuestionnaires$ | async"
            />
        </cdk-step>
        <cdk-step>
            <app-supplemental-questionnaire-edit
                [questionnaireToEdit]="questionnaireToEdit"
                [isEditing]="isEditing"
                [isCurrentUserOrgAdmin]="isCurrentUserOrgAdmin"
                [createSupplementalQuestionnaireFormGroup]="supplementalQuestionnaireFormGroup"
                [importedQuestionnaireQuestions]="importedQuestions$ | async"
                [maxSupplementalQuestionnaireQuestions]="maxSupplementalQuestionnaireQuestions$ | async"
                [supplementalQuestionnaires]="supplementalQuestionnaires$ | async"
            />
        </cdk-step>
    </app-custom-stepper>
</mat-dialog-content>
<mat-dialog-actions [ngClass]="isEditing ? 'justify-content-between' : 'justify-content-end'">
    <ng-container
        *ngIf="{
            importErrors: importErrors$ | async,
            importPercentUploaded: importPercentUploaded$ | async,
            importJobId: importJobId$ | async,
        } as ctx"
    >
        @if (isEditing && isCurrentUserOrgAdmin) {
            <button type="button" mat-button color="warn" (click)="deleteQuestionnaire()" matDialogClose>
                Delete questionnaire
            </button>
        }

        <div>
            <button
                type="button"
                mat-button
                mat-dialog-close
                color="secondary"
                (click)="cancelCreateQuestionnaireDialog(ctx.importJobId)"
            >
                {{ isCurrentUserOrgAdmin ? 'Cancel' : 'Close' }}
            </button>

            @if (isCurrentUserOrgAdmin) {
                @if (currentStepperIndex === SupplementalQuestionnaireDialogStepIndex.IMPORT) {
                    <button
                        type="button"
                        mat-flat-button
                        color="primary"
                        (click)="continueToNextStep()"
                        [disabled]="ctx.importPercentUploaded !== 100 || !!ctx.importErrors.length"
                    >
                        Continue
                    </button>
                } @else if (currentStepperIndex === SupplementalQuestionnaireDialogStepIndex.MODIFY && !isEditing) {
                    <button
                        type="button"
                        mat-flat-button
                        color="primary"
                        (click)="createQuestionnaire(ctx.importJobId)"
                        [disabled]="supplementalQuestionnaireFormGroup.invalid"
                    >
                        Save
                    </button>
                } @else {
                    <button
                        type="button"
                        mat-flat-button
                        color="primary"
                        (click)="updateQuestionnaire()"
                        [disabled]="supplementalQuestionnaireFormGroup.invalid"
                    >
                        Update
                    </button>
                }
            }
        </div>
    </ng-container>
</mat-dialog-actions>
