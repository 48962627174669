import { Component, Input, OnInit } from '@angular/core';
import { FollowupType } from '@entities/assessment';
import { Risk } from '@entities/risk-assessment';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AssessmentActions } from '../../redux/actions/assessments.actions';

export interface EditFollowupDialogResult {
    newFollowupType: FollowupType;
    newFollowupRiskThreshold: Risk;
}

@Component({
    selector: 'app-edit-followup-dialog',
    templateUrl: './edit-followup-method-dialog.component.html',
    styleUrl: './edit-followup-method-dialog.component.scss',
})
export class EditFollowupMethodDialog implements OnInit {
    @Input({ required: true })
    currentFollowupType: FollowupType;

    @Input({ required: true })
    currentFollowupRiskThreshold: Risk;

    @Input({ required: true })
    conciergeAssessmentsEnabled: boolean;

    @Input({ required: true })
    currentUserRemainingConciergeAssessments: number;

    followupTypeControl: FormControl<FollowupType>;
    followupRiskThresholdControl: FormControl<Risk>;

    constructor(
        private _dialogRef: MatDialogRef<EditFollowupDialogResult>,
        private _fb: FormBuilder,
    ) {}

    ngOnInit(): void {
        this.followupTypeControl = this._fb.control(this.currentFollowupType, Validators.required);
        this.followupRiskThresholdControl = this._fb.control(
            this.currentFollowupRiskThreshold ?? Risk.LOW,
            Validators.required,
        );
    }

    submit(): void {
        const followupType = this.followupTypeControl.value;
        const followupRiskThreshold = this.followupRiskThresholdControl.value;
        this._dialogRef.close({
            newFollowupType: followupType,
            newFollowupRiskThreshold: followupType === FollowupType.AUTOMATIC ? followupRiskThreshold : null,
        } as EditFollowupDialogResult);
    }

    protected readonly AssessmentActions = AssessmentActions;
}
