import { Risk } from '../risk-assessment';
import { SecurityControlDomain } from '../relationship/models/security-control-domain';
import { ArtifactRtpValidationStatus, FileArtifact } from '../artifact';

export enum RTPEventType {
    RTP_ARTIFACT_AV_SCANNED = 'RTP_ARTIFACT_AV_SCANNED',
    RTP_ARTIFACT_CLASSIFIED = 'RTP_ARTIFACT_CLASSIFIED',
    RTP_PUBLISH_DATE_SET = 'RTP_PUBLISH_DATE_SET',
    RTP_PAGE_DETECTIONS_CLASSIFIED = 'RTP_PAGE_DETECTIONS_CLASSIFIED',
    RTP_ARTIFACT_PAGES_COMPLETED = 'RTP_ARTIFACT_PAGES_COMPLETED',
    RTP_ARTIFACT_ERRORED = 'RTP_ARTIFACT_ERRORED',
    RTP_SUPPQ_COMPLETED = 'RTP_SUPPQ_COMPLETED',
    RTP_SUPPQ_ARTIFACT_PROCESSED = 'RTP_SUPPQ_ARTIFACT_PROCESSED',
}

export enum MLErrorType {
    FAILED_TO_DOWNLOAD = 'FAILED_TO_DOWNLOAD',
    NON_ENGLISH_DOC = 'NON_ENGLISH_DOC',
    NON_TEXTRACT_LANG = 'NON_TEXTRACT_LANG',
    UNABLE_TO_PARSE_PDF = 'UNABLE_TO_PARSE_PDF',
    NO_PAGES_FOUND = 'NO_PAGES_FOUND',
    PAGE_SIZE_TOO_LARGE = 'PAGE_SIZE_TOO_LARGE',
    INTERNAL_RTP_ERROR = 'INTERNAL_RTP_ERROR',
    INCORRECT_PASSWORD = 'INCORRECT_PASSWORD',
    UNPARSEABLE_FILE_TYPE = 'UNPARSEABLE_FILE_TYPE',
}

export interface RTPEvent {
    orgId?: number;
    text?: string;
    eventType?: RTPEventType;
    artifactId?: number;
    assessmentId?: number;
    relationshipId?: number;
}

export interface RTPArtifactEvent extends RTPEvent {
    artifact?: FileArtifact;
}

export interface RTPPageEvent extends RTPEvent {
    totalPages?: number;
    validatedControlDomainIds?: number[];
    securityControlDomain: SecurityControlDomain;
    rtpStatus: ArtifactRtpValidationStatus;
}

export interface RTPArtifactAvScanned extends RTPArtifactEvent {
    eventType: RTPEventType.RTP_ARTIFACT_AV_SCANNED;
}

export interface RTPArtifactClassified extends RTPArtifactEvent {
    eventType: RTPEventType.RTP_ARTIFACT_CLASSIFIED;
}

export interface RTPArtifactPublishDateSet extends RTPArtifactEvent {
    eventType: RTPEventType.RTP_PUBLISH_DATE_SET;
}

export interface RTPSupplementalQuestionnaireArtifactProcessed extends RTPArtifactEvent {
    eventType: RTPEventType.RTP_SUPPQ_ARTIFACT_PROCESSED;
    validatedControlDomainIds?: number[];
    securityControlDomain: SecurityControlDomain;
}

export interface RTPSupplementalQuestionnaireCompleted extends RTPArtifactEvent {
    eventType: RTPEventType.RTP_SUPPQ_COMPLETED;
}

export interface RTPPageDetectionsClassified extends RTPEvent {
    eventType: RTPEventType.RTP_PAGE_DETECTIONS_CLASSIFIED;
    pageNumber?: number;
    totalPages?: number;
    completedPages?: number;
    validatedControlDomainIds?: number[];
    residualRisk: Risk;
    securityControlDomain: SecurityControlDomain;
    rtpStatus: ArtifactRtpValidationStatus;
}

export interface RTPArtifactPagesCompleted extends RTPEvent {
    eventType: RTPEventType.RTP_ARTIFACT_PAGES_COMPLETED;
    totalPages?: number;
    validatedControlDomainIds?: number[];
    residualRisk: Risk;
    securityControlDomain: SecurityControlDomain;
    rtpStatus: ArtifactRtpValidationStatus;
}

export interface RTPArtifactErrored extends RTPEvent {
    eventType: RTPEventType.RTP_ARTIFACT_ERRORED;
    rtpStatus: ArtifactRtpValidationStatus;
    errorType: MLErrorType;
}
