import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { createRequestOption, CSRFService } from '../../shared';
import { UploadFileArtifactRequest } from '../artifact';
import {
    CreateSupplementalQuestionnaireRequest,
    UpdateSupplementalQuestionnaireRequest,
} from '../../routes/questionnaire/questionnaire-management/model/questionnaire-management-models';
import {
    SupplementalQuestionnaireResponse,
    SupplementalQuestionnaireWithQuestions,
} from './supplemental-questionnaire.model';

@Injectable({
    providedIn: 'root',
})
export class SupplementalQuestionnaireService {
    private resourceUrl = 'api/supplemental-questionnaires';

    constructor(
        private _http: HttpClient,
        private _csrfService: CSRFService,
    ) {}

    uploadSupplementalQuestionnaires(fileRequests: UploadFileArtifactRequest[]) {
        const formData = new FormData();
        const filesMetaData = [];

        fileRequests.forEach((request) => {
            formData.append('files', request.file, request.file.name);
            filesMetaData.push(request.fileArtifactMetaData);
        });

        formData.append(
            'fileArtifactMetaData',
            new Blob([JSON.stringify(filesMetaData)], { type: 'application/json' }),
        );

        const options: any = {
            headers: {
                'X-XSRF-TOKEN': this._csrfService.getCSRF(),
            },
            reportProgress: true,
        };

        return this._http.post(this.resourceUrl, formData, options);
    }

    findAllSupplementalQuestionnairesForCurrentUser(
        pageable?: any,
    ): Observable<HttpResponse<SupplementalQuestionnaireResponse[]>> {
        const options = createRequestOption(pageable);
        return this._http.get<SupplementalQuestionnaireResponse[]>(this.resourceUrl, {
            params: options,
            observe: 'response',
        });
    }

    findById(supplementalQuestionnaireId: string): Observable<SupplementalQuestionnaireWithQuestions> {
        return this._http.get<SupplementalQuestionnaireWithQuestions>(
            `${this.resourceUrl}/${supplementalQuestionnaireId}`,
        );
    }

    deleteSupplementalQuestionnaire(questionnaireId: string): Observable<void> {
        return this._http.delete<any>(`${this.resourceUrl}/${questionnaireId}`);
    }

    createSupplementalQuestionnaire(createRequest: CreateSupplementalQuestionnaireRequest): Observable<void> {
        return this._http.post<void>(this.resourceUrl, createRequest);
    }

    updateSupplementalQuestionnaire(updateRequest: UpdateSupplementalQuestionnaireRequest): Observable<void> {
        return this._http.put<void>(this.resourceUrl, updateRequest);
    }
}
