import { Injectable } from '@angular/core';
import { PublicAssessmentService } from '@entities/assessment';
import {
    isRTPArtifactClassified,
    isRTPArtifactPublishDateSet,
    RTPArtifactClassified,
    RTPEventType,
} from '@entities/rtp';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, filter, map, switchMap, takeUntil, withLatestFrom } from 'rxjs';
import { PublicAssessmentRTPFileArtifact } from '../../models';
import {
    onRTPArtifactClassifiedEvent,
    onRTPArtifactPublishDateSetEvent,
    onRTPEvent,
    onRtpFileArtifactCreation,
    startRTP,
    stopRTP,
    uploadFiles,
    uploadFilesRequestSuccess,
} from '../actions';
import {
    getAssessmentToken,
    getFileArtifactsUploadedAfterAssessmentCreation,
    getRtpEventsMap,
    getSecret,
    getSortedRtpFileArtifacts,
} from '../selectors';
import { skip, startWith } from 'rxjs/operators';
import { FileArtifact } from '@entities/artifact';

// Any RTP events we care about.
// All others sent from the API will be ignored.
const RTP_EVENTS_TO_FILTER_BY = [
    RTPEventType.RTP_ARTIFACT_CLASSIFIED,
    RTPEventType.RTP_PUBLISH_DATE_SET,
    RTPEventType.RTP_ARTIFACT_ERRORED,
    RTPEventType.RTP_SUPPQ_COMPLETED,
    RTPEventType.RTP_SUPPQ_ARTIFACT_PROCESSED,
];

@Injectable()
export class RTPEffects {
    startRTP$ = createEffect(() =>
        this._actions$.pipe(
            ofType(startRTP),
            withLatestFrom(this._store$.select(getAssessmentToken), this._store$.select(getSecret)),
            switchMap(([, assessmentToken, secret]) =>
                this._publicAssessmentService.listenToPublicAssessmentRTP(assessmentToken, secret).pipe(
                    filter((rtpEvent) => RTP_EVENTS_TO_FILTER_BY.includes(rtpEvent.eventType)),
                    map((rtpEvent) => onRTPEvent({ rtpEvent })),
                    takeUntil(this._actions$.pipe(ofType(stopRTP))),
                ),
            ),
        ),
    );

    onRTPArtifactClassifiedEvent$ = createEffect(() =>
        this._actions$.pipe(
            ofType(onRTPEvent),
            filter(({ rtpEvent }) => isRTPArtifactClassified(rtpEvent)),
            map(({ rtpEvent }) => onRTPArtifactClassifiedEvent({ rtpEvent: rtpEvent as RTPArtifactClassified })),
        ),
    );

    onRTPArtifactPublishDateSetEvent$ = createEffect(() =>
        this._actions$.pipe(
            ofType(onRTPEvent),
            filter(({ rtpEvent }) => isRTPArtifactPublishDateSet(rtpEvent)),
            map(() => onRTPArtifactPublishDateSetEvent()),
        ),
    );

    triggerRtpFileArtifactPlaceholdersUntilApiResponse = createEffect(() =>
        this._actions$.pipe(
            ofType(uploadFiles),
            withLatestFrom(this._store$.select(getSortedRtpFileArtifacts)),
            map(([{ files }, rtpFileArtifacts]) =>
                rtpFileArtifacts.concat(
                    files.map(
                        (file) =>
                            new PublicAssessmentRTPFileArtifact(
                                { fileName: file.name } as FileArtifact,
                                new Map(),
                                false,
                            ),
                    ),
                ),
            ),
            map((rtpFileArtifacts) => onRtpFileArtifactCreation({ rtpFileArtifacts })),
        ),
    );

    triggerRtpFileArtifactCreation$ = createEffect(() =>
        combineLatest([
            this._store$.select(getFileArtifactsUploadedAfterAssessmentCreation),
            this._store$.select(getRtpEventsMap),
        ]).pipe(
            skip(1), // Selectors above will emit on initial state load.
            withLatestFrom(
                this._actions$.pipe(
                    ofType(uploadFilesRequestSuccess),
                    startWith({ successfullyUploadedArtifacts: [] }),
                ),
            ),
            map(([[artifacts, rtpEventsMap], { successfullyUploadedArtifacts }]) =>
                artifacts.map(
                    (artifact) =>
                        new PublicAssessmentRTPFileArtifact(
                            artifact,
                            rtpEventsMap.get(artifact.id) || new Map(),
                            successfullyUploadedArtifacts?.some((a) => a.id === artifact.id) ?? false,
                        ),
                ),
            ),
            map((rtpFileArtifacts) => onRtpFileArtifactCreation({ rtpFileArtifacts })),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _store$: Store,
        private _publicAssessmentService: PublicAssessmentService,
    ) {}
}
