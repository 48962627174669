import { Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil, withLatestFrom } from 'rxjs/operators';
import md5 from 'blueimp-md5';
import { initialize, LDClient, LDFlagChangeset, LDFlagSet, LDFlagValue } from 'launchdarkly-js-client-sdk';
import { environment } from '@environments/environment';
import { getUserAccount } from '../../routes/session/redux/session.selectors';
import { getServerSessionCompleted } from '../../routes/session/redux/session.actions';
import { AppState } from '../redux/state';
import { FeatureFlags } from '../enums/feature-flags';

export interface MappedFlagSet extends LDFlagSet, Record<FeatureFlags, LDFlagValue> {}

@Injectable({
    providedIn: 'root',
})
export class FeatureFlagService implements OnDestroy {
    flagsLoaded = new BehaviorSubject<MappedFlagSet & { loaded: boolean }>({
        [FeatureFlags.TRUST]: false,
        [FeatureFlags.PRIVACY_MODULE]: false,
        [FeatureFlags.SUPPLEMENTAL_QUESTIONNAIRES]: false,
        [FeatureFlags.RDP_AI_QA]: false,
        [FeatureFlags.ORG_USER_CAN_ONBOARD]: false,
        [FeatureFlags.BIG_PICTURE_PUBLIC_SEARCH_API]: false,
        [FeatureFlags.RISK_TOLERANCE]: false,
        [FeatureFlags.DOMAINS_BRANDING]: false,
        [FeatureFlags.CONCIERGE_ASSESSMENTS]: false,
        loaded: false,
    });

    private flags: MappedFlagSet;
    private ldClient: LDClient;
    private _unsub: Subject<void> = new Subject<void>();

    constructor(
        private _store$: Store<AppState>,
        private _actions$: Actions,
    ) {
        const { loaded, ...flags } = this.flagsLoaded.getValue();
        this.flags = flags;
        if (environment.enableLaunchDarkly) {
            this._actions$
                .pipe(
                    ofType(getServerSessionCompleted),
                    withLatestFrom(this._store$.pipe(select(getUserAccount))),
                    map(([, currentAccount]) => currentAccount),
                    takeUntil(this._unsub),
                )
                .subscribe((account) => {
                    // Use the email from the account the user is logged in as, hashed with MD5 as the key.
                    // If the email does not exist, we set it null and mark the user as anonymous.
                    const hashedEmail: string = !!account?.email ? md5(account.email) : null;
                    const orgId: string = !!account?.orgId ? account.orgId.toString() : null;

                    this.ldClient = initialize(environment.launchDarklyClientId, {
                        key: hashedEmail,
                        anonymous: hashedEmail === null,
                        custom: {
                            orgId: orgId,
                        },
                    });

                    // Receive update from the API when flags are changed on the Launch Darkly Dashboard
                    this.ldClient.on('change', (updatedFlags: LDFlagChangeset) => {
                        const updatedFlagsKeys = Object.keys(updatedFlags).reduce((acc, key) => {
                            acc[key] = updatedFlags[key]?.current;
                            return acc;
                        }, {} as MappedFlagSet);
                        this.flags = { ...this.flags, ...updatedFlagsKeys };
                        this.flagsLoaded.next({ ...this.flags, loaded: true });
                    });

                    this.ldClient.on('ready', () => {
                        this.setFlags();
                        this.flagsLoaded.next({ ...this.flags, loaded: true });
                    });
                });
        } else {
            // Treat every feature as enabled when LaunchDarkly is disabled
            Object.keys(this.flags).forEach((v) => (this.flags[v] = true));
            this.flagsLoaded.next({ ...this.flags, loaded: true });
        }
    }

    hasFeatureFlagEnabled(featureFlag: FeatureFlags): Observable<boolean> {
        return this.flagsLoaded.pipe(map((flagset) => flagset[featureFlag]));
    }

    async ngOnDestroy(): Promise<void> {
        await this.ldClient.close();
        this._unsub.next();
    }

    private setFlags(): void {
        this.flags = this.ldClient.allFlags() as MappedFlagSet;
    }
}
