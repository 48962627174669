import { AssessmentStatus } from '@entities/assessment';
import { AssessmentStatusEvent } from './models/assessment-events';

const notAssessed: AssessmentStatusEvent = {
    title: 'Not started',
    subtitle: 'Waiting for client',
    assessmentStatus: AssessmentStatus.NOT_ASSESSED,
    current: true,
    lowerText: {
        label: null,
        value: null,
    },
    done: false,
};

const started: AssessmentStatusEvent = {
    title: 'Started',
    subtitle: 'Waiting for third party',
    assessmentStatus: AssessmentStatus.STARTED,
    current: false,
    lowerText: {
        label: 'Sent To',
        value: '',
    },
    done: false,
};

const collectingInformation: AssessmentStatusEvent = {
    title: 'Collecting information',
    subtitle: 'Waiting for third party',
    assessmentStatus: AssessmentStatus.COLLECTING_INFORMATION,
    current: false,
    lowerText: {
        label: 'Collecting From',
        value: '',
    },
    done: false,
};

const reviewStarted: AssessmentStatusEvent = {
    title: 'Review started',
    subtitle: 'Waiting for VISO TRUST',
    assessmentStatus: AssessmentStatus.REVIEW_STARTED,
    current: false,
    lowerText: {
        label: 'Submitted By',
        value: '',
    },
    done: false,
};

const auditCompleted: AssessmentStatusEvent = {
    title: 'Review started - Follow-up recommended',
    subtitle: 'Waiting for Client',
    assessmentStatus: AssessmentStatus.AUDIT_COMPLETED,
    current: false,
    lowerText: {
        label: 'Submitted By',
        value: '',
    },
    done: false,
};

const completed: AssessmentStatusEvent = {
    title: 'Completed',
    subtitle: null,
    assessmentStatus: AssessmentStatus.COMPLETED,
    current: false,
    lowerText: {
        label: 'Duration',
        value: '',
    },
    done: false,
};

export const assessmentTimeline: Record<AssessmentStatus, AssessmentStatusEvent[]> = {
    NOT_ASSESSED: [{ ...notAssessed, current: true }, collectingInformation, reviewStarted, completed],
    STARTED: [{ ...started, current: true }, collectingInformation, reviewStarted, completed],
    COLLECTING_INFORMATION: [
        { ...started, done: true },
        { ...collectingInformation, current: true },
        reviewStarted,
        completed,
    ],
    REVIEW_STARTED: [
        { ...started, done: true },
        { ...collectingInformation, done: true },
        { ...reviewStarted, current: true },
        completed,
    ],
    AUDIT_COMPLETED: [
        { ...started, done: true },
        { ...collectingInformation, done: true },
        { ...auditCompleted, current: true },
        completed,
    ],
    COMPLETED: [
        { ...started, done: true },
        { ...collectingInformation, done: true },
        { ...reviewStarted, done: true },
        { ...completed, current: true, done: true },
    ],
    CANCELLED: [started, collectingInformation, reviewStarted, completed],

    // Assessments in these statuses are not displayed.
    AWAITING_REMEDIATION: [],
    DELETED: [],
};
