import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
    generateExampleAssessmentSummaryRequest,
    generateExampleAssessmentSummaryRequestFailed,
    generateExampleAssessmentSummaryRequestSuccess,
    updateAssessmentSummarySectionConfigRequest,
    updateAssessmentSummarySectionConfigRequestFailed,
    updateAssessmentSummarySectionConfigRequestSuccess,
} from './assessment-summary-management.actions';
import { ClientProfileService } from '../../../admin';
import { AssessmentService } from '@entities/assessment';

@Injectable()
export class AssessmentSummaryManagementEffects {
    updateAssessmentSummarySectionConfig$ = createEffect(() =>
        this._actions$.pipe(
            ofType(updateAssessmentSummarySectionConfigRequest),
            switchMap(({ assessmentSummarySection }) =>
                this._clientProfileService.updateAssessmentSummarySectionConfigForClient(assessmentSummarySection).pipe(
                    map(() => updateAssessmentSummarySectionConfigRequestSuccess()),
                    catchError((error) => of(updateAssessmentSummarySectionConfigRequestFailed(error))),
                ),
            ),
        ),
    );

    generateExampleAssessmentSummaryRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(generateExampleAssessmentSummaryRequest),
            switchMap(() =>
                this._assessmentService.generateExampleAssessmentSummary().pipe(
                    map(({ html: summary }) => generateExampleAssessmentSummaryRequestSuccess({ summary })),
                    catchError((error) => of(generateExampleAssessmentSummaryRequestFailed({ error }))),
                ),
            ),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _clientProfileService: ClientProfileService,
        private _assessmentService: AssessmentService,
    ) {}
}
