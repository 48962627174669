import { ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import {
    SupplementalQuestionnaireService,
    SupplementalQuestionnaireWithQuestions,
} from '@entities/supplemental-questionnaire';

export const editSupplementalQuestionnaireResolver: ResolveFn<SupplementalQuestionnaireWithQuestions> = (
    route,
    state,
) => {
    const supplementalQuestionnaireId = route.paramMap.get('supplementalQuestionnaireId');
    const supplementalQuestionnaireService = inject(SupplementalQuestionnaireService);
    return supplementalQuestionnaireService.findById(supplementalQuestionnaireId);
};
