import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { catchError, exhaustMap, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { RiskAssessmentService, SlimRiskAssessment } from '@entities/risk-assessment';
import { PrimaryVendorContactService } from '../../primary-vendor-contact';
import {
    acceptRiskRequest,
    acceptRiskRequestFailed,
    acceptRiskRequestSuccess,
    addPrimaryVendorContactRequest,
    addPrimaryVendorContactRequestFailed,
    addPrimaryVendorContactRequestSuccess,
    bulkLinkTagsRequest,
    bulkLinkTagsRequestFailed,
    bulkLinkTagsRequestSuccess,
    cancelRemediationRequest,
    cancelRemediationRequestFailed,
    cancelRemediationRequestSuccess,
    combineSupplementalQuestionnaireConfiguration,
    completeLatestAssessmentRequest,
    completeLatestAssessmentRequestFailed,
    completeLatestAssessmentRequestSuccess,
    getRelationshipSubscribersRequest,
    getRelationshipSubscribersRequestFailed,
    getRelationshipSubscribersRequestSuccess,
    getRequestPrimaryVendorContactRequest,
    getRequestPrimaryVendorContactRequestFailed,
    getRequestPrimaryVendorContactRequestSuccess,
    getRequestRiskAssessmentsRequest,
    getRequestRiskAssessmentsRequestFailed,
    getRequestRiskAssessmentsRequestSuccess,
    linkTagRequest,
    linkTagRequestFailed,
    linkTagRequestSuccess,
    openCancelRemediationDialog,
    openEditFollowupMethodDialog,
    openRemediationDialog,
    openReviewFollowupDialog,
    openReviewRiskDialog,
    removePrimaryVendorContactRequest,
    removePrimaryVendorContactRequestFailed,
    removePrimaryVendorContactRequestSuccess,
    revokeRiskAcceptanceRequest,
    revokeRiskAcceptanceRequestFailed,
    revokeRiskAcceptanceRequestSuccess,
    sendLatestAssessmentFollowupRequest,
    sendLatestAssessmentFollowupRequestFailed,
    sendLatestAssessmentFollowupRequestSuccess,
    startRemediationRequest,
    startRemediationRequestFailed,
    startRemediationRequestSuccess,
    subscribeContactRequest,
    subscribeContactRequestFailed,
    subscribeContactRequestSuccess,
    unlinkTagRequest,
    unlinkTagRequestFailed,
    unlinkTagRequestSuccess,
    unsubscribeContactRequest,
    unsubscribeContactRequestFailed,
    unsubscribeContactRequestSuccess,
    updateFollowupMethodRequest,
    updateFollowupTypeRequestFailed,
    updateFollowupTypeRequestSuccess,
} from './request.actions';
import { RelationshipService } from '@entities/relationship';
import { SnackbarService } from '@shared/components/snackbar/snackbar.service';
import { ContactService } from '../../contact';
import {
    getRequestLatestAssessmentRequestFailed,
    getRequestLatestAssessmentRequestSuccess,
} from './actions/assessments.actions';
import { AssessmentView } from '@entities/assessment';
import { getRelationshipSuccess } from './actions/relationship.actions';
import { TagService } from '@entities/tag';
import {
    getRelationshipArtifactSupersessionRequest,
    getRelationshipArtifactSupersessionRequestFailed,
    getRelationshipArtifactSupersessionRequestSuccess,
} from './actions/artifacts.actions';
import { Store } from '@ngrx/store';
import {
    getLatestCompletedAssessmentNotCombinedRecommendations,
    getRelationshipLatestAssessment,
    getRelationshipLatestCompletedAssessment,
    getRelationshipLatestCompletedAssessmentRemediationRequest,
    getRelationshipPopulatedLatestAssessment,
} from './selectors/assessment.selectors';
import { AssessmentUtilsService } from '@shared/utils/assessment-utils.service';
import {
    getControlDomainsRequiringFollowup,
    getIsRiskAccepted,
    getRelationshipBusinessOwner,
    getRelationshipId,
    getRelationshipLatestAssessmentInProgress,
    getRelationshipLatestRiskAssessment,
    getRelationshipPrimaryVendorContact,
    getRelationshipRiskOverrideLastAppliedByFullName,
    getRelationshipRiskOverrideLastAppliedDate,
    getRelationshipSubscribers,
    getRelationshipVendorName,
    getRemainingConciergeAssessments,
    getSupplementalQuestionnaireConfig,
} from './request.selectors';
import { getUserAccount } from '../../session/redux/session.selectors';
import {
    RemediationDialogComponent,
    RemediationDialogResult,
    RemediationDialogResultAction,
} from '../assessments/remediation-dialog/remediation-dialog.component';
import { MatDialogWrapperService } from '@shared/modal/mat-dialog-wrapper.service';
import { NOOP } from '@shared/redux/actions';
import { RemediationUtilsService } from '@shared/utils/remediation-utils.service';
import { MatConfirmDialogService } from '@shared/components/mat-confirm-dialog/mat-confirm-dialog.service';
import {
    ReviewRiskDialogComponent,
    ReviewRiskDialogResult,
    ReviewRiskDialogResultAction,
} from '../assessments/review-risk-dialog/review-risk-dialog.component';
import {
    ReviewFollowupDialogAction,
    ReviewFollowupDialogComponent,
} from '../assessments/review-followup-dialog/review-followup-dialog.component';
import {
    EditFollowupDialogResult,
    EditFollowupMethodDialog,
} from '../assessments/edit-followup-dialog/edit-followup-method-dialog.component';
import { FeatureFlagService } from '@shared/services/featureflag.service';
import { FeatureFlags } from '@shared/enums/feature-flags';
import { ControlDomainType } from '@entities/control-domain';
import {
    getOrgSupplementalQuestionnaireConfigRequestSuccess,
    getRelationshipSupplementalQuestionnaireConfigRequestSuccess,
} from './actions/supplemental-questionnaire.actions';

@Injectable()
export class RequestEffects {
    getRequestRiskAssessmentsRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getRequestRiskAssessmentsRequest),
            switchMap(({ requestId }) =>
                this._riskAssessmentService.findAllByRelationshipId(requestId).pipe(
                    map((response) => response.body),
                    map((riskAssessments) => getRequestRiskAssessmentsRequestSuccess({ riskAssessments })),
                    catchError(() => of(getRequestRiskAssessmentsRequestFailed())),
                ),
            ),
        ),
    );

    subscribeSubscriberRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(subscribeContactRequest),
            switchMap(({ requestId, contactId }) =>
                this._contactService.subscribe(contactId, requestId).pipe(
                    map(() => subscribeContactRequestSuccess()),
                    catchError(() => of(subscribeContactRequestFailed())),
                ),
            ),
        ),
    );

    unsubscribeSubscriberRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(unsubscribeContactRequest),
            switchMap(({ requestId, contactId }) =>
                this._confirmDialogService
                    .confirm({
                        title: 'Unsubscribe',
                        message: 'Are you sure you want to unsubscribe the subscriber from this relationship?',
                        confirmLabel: 'Yes, do it!',
                    })
                    .pipe(
                        filter((result) => result),
                        switchMap(() =>
                            this._contactService.unsubscribe(contactId, requestId).pipe(
                                map(() => unsubscribeContactRequestSuccess()),
                                catchError(() => of(unsubscribeContactRequestFailed())),
                            ),
                        ),
                    ),
            ),
        ),
    );

    subscribeSubscriberRequestSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(subscribeContactRequestSuccess),
                tap(() => {
                    this._snackbarService.success('We have subscribed the contact');
                }),
            ),
        { dispatch: false },
    );

    unsubscribeSubscriberRequestSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(unsubscribeContactRequestSuccess),
                tap(() => {
                    this._snackbarService.success('We have unsubscribed the contact');
                }),
            ),
        { dispatch: false },
    );

    addPrimaryVendorContactRequestSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(addPrimaryVendorContactRequestSuccess),
                tap(() => {
                    this._snackbarService.success('Successfully saved primary contact');
                }),
            ),
        { dispatch: false },
    );

    addPrimaryVendorContactRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(addPrimaryVendorContactRequest),
            map(({ primaryVendorContact }) => primaryVendorContact),
            withLatestFrom(
                this._actions$.pipe(
                    ofType(getRelationshipSuccess),
                    map(({ relationship }) => relationship.primaryContact),
                ),
                this._store$.select(getRelationshipPopulatedLatestAssessment),
                this._store$.select(getRelationshipLatestCompletedAssessment),
            ),
            switchMap(([newContact, existingContact, latestAssessment, latestCompletedAssessment]) => {
                let message = null;
                if (this._remediationUtils.isRemediationActive(latestCompletedAssessment?.remediationRequest?.status)) {
                    message =
                        'Are you sure you want to make changes to the third party contact?' +
                        '\n\nThere is currently an open request for remediation. If you update the third party contact, we will forward this request to them.' +
                        '\n\nIf you would like us to automatically reach out to this contact for recertification and artifact update, ensure that lifecycle management is enabled and third party contact is selected.';
                } else {
                    const assessmentInProgress = this._assessmentUtils.isAssessmentInProgress(latestAssessment?.status);
                    const primaryContactChanged = newContact?.email !== existingContact?.email;
                    const newContactDifferentFromAssessmentRecipient =
                        newContact?.email !== latestAssessment?.sentToEmail;
                    if (assessmentInProgress && primaryContactChanged && newContactDifferentFromAssessmentRecipient) {
                        message =
                            'Are you sure you want to make changes to the third party contact?' +
                            '\n\nYou have an assessment in progress. Updating the third party contact will cancel the current assessment and send a new one to the updated contact.' +
                            '\n\nIf you would like us to automatically reach out to this contact for recertification and artifact update, ensure that lifecycle management is enabled and third party contact is selected.';
                    }
                }
                return (
                    !!message
                        ? this._confirmDialogService
                              .confirm({
                                  title: 'Edit Third Party Contact',
                                  message,
                                  cancelLabel: 'No, keep contact',
                                  confirmLabel: 'Yes, update contact',
                              })
                              .pipe(filter((result) => result))
                        : of(true)
                ).pipe(
                    switchMap(() =>
                        this._primaryVendorContactService.save(newContact).pipe(
                            map(() => addPrimaryVendorContactRequestSuccess()),
                            catchError(() => of(addPrimaryVendorContactRequestFailed())),
                        ),
                    ),
                );
            }),
        ),
    );

    removePrimaryVendorContactRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(removePrimaryVendorContactRequest),
            withLatestFrom(
                this._actions$.pipe(
                    ofType(getRequestLatestAssessmentRequestSuccess, getRequestLatestAssessmentRequestFailed),
                    map((payload) =>
                        this._assessmentUtils.isAssessmentInProgress(
                            (payload as { latestAssessment: AssessmentView })?.latestAssessment?.status,
                        ),
                    ),
                ),
                this._store$.select(getRelationshipLatestCompletedAssessment),
            ),
            map(([{ relationshipId, primaryContactId }, assessmentInProgress, latestCompletedAssessment]) => {
                let message;
                if (this._remediationUtils.isRemediationActive(latestCompletedAssessment?.remediationRequest?.status)) {
                    message =
                        'There is currently an open request for remediation. Removing the third party contact will cancel the request.';
                } else if (assessmentInProgress) {
                    message =
                        'You have an assessment in progress. Removing the third party contact will cancel the current assessment.';
                }

                return {
                    message:
                        `Are you sure you want to remove the third party contact?${!!message ? '\n\n' + message : ''}` +
                        '\n\nWe won’t be able to automatically follow up with the third party or reach out to the organization to collect the latest information.' +
                        '\n\nIf you have lifecycle management enabled, the contact will be set to the business owner.',
                    relationshipId,
                    primaryContactId,
                };
            }),
            switchMap(({ message, relationshipId, primaryContactId }) =>
                this._confirmDialogService
                    .confirm({
                        title: 'Remove Third Party Contact',
                        message,
                        cancelLabel: 'No, keep contact',
                        confirmLabel: 'Yes, remove contact',
                    })
                    .pipe(
                        filter((result) => result),
                        switchMap(() =>
                            this._primaryVendorContactService.remove(relationshipId, primaryContactId).pipe(
                                map((response) => removePrimaryVendorContactRequestSuccess()),
                                catchError(() => of(removePrimaryVendorContactRequestFailed())),
                            ),
                        ),
                    ),
            ),
        ),
    );

    removePrimaryVendorContactRequestSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(removePrimaryVendorContactRequestSuccess),
                tap(() => {
                    this._snackbarService.success('The primary contact has been removed');
                }),
            ),
        { dispatch: false },
    );

    drSubscribersRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getRelationshipSubscribersRequest),
            switchMap(({ requestId }) =>
                this._requestService.getSubscribers(requestId).pipe(
                    map((response) => response.body),
                    map((subscribers) => getRelationshipSubscribersRequestSuccess({ subscribers })),
                    catchError(() => of(getRelationshipSubscribersRequestFailed())),
                ),
            ),
        ),
    );

    primaryVendorContactRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getRequestPrimaryVendorContactRequest),
            switchMap(({ requestId }) =>
                this._requestService.getPrimaryVendorContact(requestId).pipe(
                    map((response) => response.body),
                    map((primaryVendorContact) =>
                        getRequestPrimaryVendorContactRequestSuccess({ primaryVendorContact }),
                    ),
                    catchError(() => of(getRequestPrimaryVendorContactRequestFailed())),
                ),
            ),
        ),
    );

    linkTagRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(linkTagRequest),
            mergeMap(({ tagId, requestId }) =>
                this._tagService.linkToRelationship(tagId, requestId).pipe(
                    map(() => linkTagRequestSuccess()),
                    catchError(() => of(linkTagRequestFailed())),
                ),
            ),
        ),
    );

    unlinkTagRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(unlinkTagRequest),
            mergeMap(({ tagId, requestId }) =>
                this._tagService.unlinkToRelationship(tagId, requestId).pipe(
                    map(() => unlinkTagRequestSuccess()),
                    catchError(() => of(unlinkTagRequestFailed())),
                ),
            ),
        ),
    );

    bulkLinkTagsRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(bulkLinkTagsRequest),
            exhaustMap(({ tagIds, relationshipIds }) => {
                const payload = tagIds.reduce(
                    (acc, tagId) => ({
                        ...acc,
                        [tagId]: relationshipIds,
                    }),
                    {},
                );

                return this._tagService.bulkLinkToRelationships(payload).pipe(
                    tap((response) => {
                        let snackbarMessages: string[] = [`Tags added to ${response.linkSuccesses} relationships`];

                        if (response.failuresFromMaxTags) {
                            snackbarMessages.push(
                                `${response.failuresFromMaxTags} unsuccessful due to tag limit being reached`,
                            );
                        }

                        if (response.failuresFromOrgUserNotOwner) {
                            snackbarMessages.push(
                                `${response.failuresFromOrgUserNotOwner} unsuccessful because you are not the business owner for these relationships`,
                            );
                        }

                        const snackbarMessage = snackbarMessages.join(', ') + '.';
                        this._snackbarService.success(snackbarMessage);
                    }),
                    map(() => bulkLinkTagsRequestSuccess()),
                    catchError(() => of(bulkLinkTagsRequestFailed())),
                );
            }),
        ),
    );

    getRelationshipArtifactSupersessionRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getRelationshipArtifactSupersessionRequest),
            switchMap(({ relationshipId }) =>
                this._requestService.findSupersessionForRelationship(relationshipId).pipe(
                    map((artifactSupersession) =>
                        getRelationshipArtifactSupersessionRequestSuccess({ artifactSupersession }),
                    ),
                    catchError(() => of(getRelationshipArtifactSupersessionRequestFailed())),
                ),
            ),
        ),
    );

    openReviewRiskModal$ = createEffect(() =>
        this._actions$.pipe(
            ofType(openReviewRiskDialog),
            withLatestFrom(
                this._store$.select(getRelationshipPrimaryVendorContact),
                this._store$.select(getRelationshipVendorName),
                this._store$.select(getLatestCompletedAssessmentNotCombinedRecommendations),
                this._store$.select(getRelationshipLatestCompletedAssessmentRemediationRequest),
                this._store$.select(getIsRiskAccepted),
                this._store$.select(getRelationshipLatestRiskAssessment),
                this._store$.select(getRelationshipRiskOverrideLastAppliedByFullName),
                this._store$.select(getRelationshipRiskOverrideLastAppliedDate),
                this._store$.select(getRelationshipLatestAssessmentInProgress),
            ),
            switchMap(
                ([
                    { riskReviewNote },
                    primaryContact,
                    vendorName,
                    recommendations,
                    remediationRequest,
                    isRiskAccepted,
                    latestRiskAssessment,
                    riskOverrideLastAppliedByFullName,
                    riskOverrideLastAppliedDate,
                    latestAssessmentInProgress,
                ]) =>
                    this._dialogService
                        .open<ReviewRiskDialogComponent>(ReviewRiskDialogComponent, {
                            config: {
                                maxWidth: 900,
                            },
                            inputs: {
                                riskReviewNote,
                                primaryContact,
                                vendorName,
                                recommendations,
                                remediationRequest,
                                isRiskAccepted,
                                latestRiskAssessment,
                                riskOverrideLastAppliedByFullName,
                                riskOverrideLastAppliedDate,
                                latestAssessmentInProgress,
                            },
                            metadata: { modalName: 'Review Risk' },
                        })
                        .afterClosed()
                        .pipe(
                            map((result: ReviewRiskDialogResult) => {
                                switch (result.action) {
                                    case ReviewRiskDialogResultAction.ACCEPT_RISK:
                                        return acceptRiskRequest({
                                            ...result.data,
                                        });
                                    case ReviewRiskDialogResultAction.REVOKE_RISK_ACCEPTANCE:
                                        return revokeRiskAcceptanceRequest({
                                            riskReviewNote: result.data?.riskReviewNote,
                                        });
                                    case ReviewRiskDialogResultAction.REQUEST_REMEDIATION:
                                        return openRemediationDialog({
                                            riskReviewNote: result.data?.riskReviewNote,
                                        });
                                    case ReviewRiskDialogResultAction.CANCEL_REMEDIATION:
                                        return openCancelRemediationDialog({
                                            riskReviewNote: result.data?.riskReviewNote,
                                        });
                                    default:
                                        return NOOP();
                                }
                            }),
                        ),
            ),
        ),
    );

    openRemediationDialog$ = createEffect(() =>
        this._actions$.pipe(
            ofType(openRemediationDialog),
            withLatestFrom(
                this._store$.select(getRelationshipId),
                this._store$.select(getRelationshipVendorName),
                this._store$.select(getLatestCompletedAssessmentNotCombinedRecommendations),
                this._store$.select(getRelationshipPrimaryVendorContact),
                this._store$.select(getUserAccount),
                this._store$.select(getRelationshipBusinessOwner),
                this._store$.select(getRelationshipSubscribers),
            ),
            switchMap(
                ([
                    { riskReviewNote },
                    relationshipId,
                    vendorName,
                    recommendations,
                    primaryContact,
                    currentUser,
                    businessOwner,
                    subscribers,
                ]) =>
                    this._dialogService
                        .open<RemediationDialogComponent>(RemediationDialogComponent, {
                            inputs: {
                                riskReviewNote,
                                vendorName,
                                recommendations,
                                primaryContact,
                                currentUser,
                                businessOwner,
                                subscribers,
                            },
                            config: {
                                maxWidth: 900,
                            },
                            metadata: { modalName: 'Remediation Request' },
                        })
                        .afterClosed()
                        .pipe(
                            filter(Boolean),
                            map((result: RemediationDialogResult) => {
                                switch (result.action) {
                                    case RemediationDialogResultAction.REQUEST_REMEDIATION:
                                        return startRemediationRequest({
                                            relationshipId,
                                            ...result.data,
                                        });
                                    case RemediationDialogResultAction.BACK:
                                        return openReviewRiskDialog({ riskReviewNote: result.data?.riskReviewNote });
                                    default:
                                        return NOOP();
                                }
                            }),
                        ),
            ),
        ),
    );

    startRemediationRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(startRemediationRequest),
            switchMap(
                ({ relationshipId, targetDate, sendRemediationToSubscribers, remediationComment, riskReviewNote }) =>
                    this._requestService
                        .startRemediation(
                            relationshipId,
                            targetDate,
                            sendRemediationToSubscribers,
                            riskReviewNote,
                            remediationComment,
                        )
                        .pipe(
                            map(() => startRemediationRequestSuccess()),
                            catchError(() => of(startRemediationRequestFailed())),
                        ),
            ),
        ),
    );

    startRemediationRequestSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(startRemediationRequestSuccess),
                withLatestFrom(this._store$.select(getRelationshipVendorName)),
                tap(([, vendorName]) =>
                    this._snackbarService.success(`Remediation of ${vendorName} requested successfully.`),
                ),
            ),
        { dispatch: false },
    );

    openCancelRemediationDialog$ = createEffect(() =>
        this._actions$.pipe(
            ofType(openCancelRemediationDialog),
            withLatestFrom(this._store$.select(getRelationshipId)),
            switchMap(([{ riskReviewNote }, relationshipId]) =>
                this._confirmDialogService
                    .confirm({
                        title: 'Cancel remediation',
                        message: 'Are you sure you want to cancel this request for remediation?',
                        cancelLabel: 'Never mind',
                        confirmLabel: 'Cancel remediation',
                        confirmButtonStyle: 'secondary',
                        confirmButtonColor: 'warn',
                    })
                    .pipe(
                        map((result) =>
                            !!result ? cancelRemediationRequest({ relationshipId, riskReviewNote }) : NOOP(),
                        ),
                    ),
            ),
        ),
    );

    cancelRemediationRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(cancelRemediationRequest),
            switchMap(({ relationshipId, riskReviewNote }) =>
                this._requestService.cancelRemediation(relationshipId, riskReviewNote).pipe(
                    map(() => cancelRemediationRequestSuccess()),
                    catchError(() => of(cancelRemediationRequestFailed())),
                ),
            ),
        ),
    );

    acceptRiskRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(acceptRiskRequest),
            withLatestFrom(this._store$.select(getRelationshipId)),
            switchMap(([{ riskReviewNote, riskOverride }, relationshipId]) =>
                this._requestService.acceptRisk(relationshipId, riskReviewNote, riskOverride).pipe(
                    map(() => acceptRiskRequestSuccess()),
                    catchError(() => of(acceptRiskRequestFailed)),
                ),
            ),
        ),
    );

    acceptRiskRequestSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(acceptRiskRequestSuccess),
                tap(() => this._snackbarService.success('Risk accepted')),
            ),
        { dispatch: false },
    );

    revokeRiskAcceptanceRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(revokeRiskAcceptanceRequest),
            withLatestFrom(this._store$.select(getRelationshipId)),
            switchMap(([{ riskReviewNote }, relationshipId]) =>
                this._requestService.revokeRiskAcceptance(relationshipId, riskReviewNote).pipe(
                    map(() => revokeRiskAcceptanceRequestSuccess()),
                    catchError(() => of(revokeRiskAcceptanceRequestFailed)),
                ),
            ),
        ),
    );

    revokeRiskAcceptanceRequestSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(revokeRiskAcceptanceRequestSuccess),
                tap(() => this._snackbarService.success('Risk acceptance revoked')),
            ),
        { dispatch: false },
    );

    openReviewFollowupDialog$ = createEffect(() =>
        this._actions$.pipe(
            ofType(openReviewFollowupDialog),
            withLatestFrom(
                this._featureFlagService$.hasFeatureFlagEnabled(FeatureFlags.SUPPLEMENTAL_QUESTIONNAIRES),
                this._store$.select(getRelationshipId),
                this._store$.select(getRelationshipLatestRiskAssessment),
                this._store$.select(getControlDomainsRequiringFollowup),
                this._store$.select(getSupplementalQuestionnaireConfig),
            ),
            switchMap(
                ([
                    ,
                    supplementalQuestionnairesEnabled,
                    relationshipId,
                    latestRiskAssessment,
                    followupControlDomains,
                    { questionnaires },
                ]) =>
                    this._dialogService
                        .open<ReviewFollowupDialogComponent>(ReviewFollowupDialogComponent, {
                            inputs: {
                                supplementalQuestionnairesEnabled,
                                latestRiskAssessment: latestRiskAssessment as SlimRiskAssessment,
                                followupControlDomainNames: followupControlDomains
                                    .filter(
                                        (cd) => cd.controlDomainType !== ControlDomainType.SUPPLEMENTAL_QUESTIONNAIRE,
                                    )
                                    .map((cd) => cd.name),
                                unansweredSupplementalQuestions: questionnaires
                                    .filter((questionnaire) => questionnaire.enabled)
                                    .flatMap((questionnaire) => questionnaire.questions)
                                    .filter((q) => followupControlDomains.map((cd) => cd.id).includes(q.id))
                                    .map((q) => q.question),
                            },
                            config: {
                                maxWidth: 800,
                            },
                            metadata: { modalName: 'Follow-up recommended' },
                        })
                        .afterClosed()
                        .pipe(
                            map((action: ReviewFollowupDialogAction) => {
                                switch (action) {
                                    case ReviewFollowupDialogAction.COMPLETE_ASSESSMENT:
                                        return completeLatestAssessmentRequest({ relationshipId });
                                    case ReviewFollowupDialogAction.SEND_FOLLOWUP:
                                        return sendLatestAssessmentFollowupRequest({ relationshipId });
                                    default:
                                        return NOOP();
                                }
                            }),
                        ),
            ),
        ),
    );

    openEditFollowupDialog$ = createEffect(() =>
        this._actions$.pipe(
            ofType(openEditFollowupMethodDialog),
            withLatestFrom(
                this._store$.select(getRelationshipLatestAssessment),
                this._store$.select(getRemainingConciergeAssessments),
                this._featureFlagService$.flagsLoaded,
            ),
            switchMap(([, latestAssessment, remainingConciergeAssessments, featureFlags]) =>
                this._dialogService
                    .open<EditFollowupMethodDialog>(EditFollowupMethodDialog, {
                        inputs: {
                            currentFollowupType: latestAssessment.followupType,
                            currentFollowupRiskThreshold: latestAssessment.followupRiskThreshold,
                            conciergeAssessmentsEnabled: featureFlags[FeatureFlags.CONCIERGE_ASSESSMENTS] ?? false,
                            currentUserRemainingConciergeAssessments: remainingConciergeAssessments,
                        },
                        config: {
                            maxWidth: 500,
                        },
                        metadata: { modalName: 'Edit follow-up method' },
                    })
                    .afterClosed()
                    .pipe(
                        filter(Boolean),
                        map((result: EditFollowupDialogResult) =>
                            updateFollowupMethodRequest({
                                relationshipId: latestAssessment.relationshipId,
                                followupType: result.newFollowupType,
                                followupRiskThreshold: result.newFollowupRiskThreshold,
                            }),
                        ),
                    ),
            ),
        ),
    );

    completeLatestAssessment$ = createEffect(() =>
        this._actions$.pipe(
            ofType(completeLatestAssessmentRequest),
            switchMap(({ relationshipId }) =>
                this._confirmDialogService
                    .confirm({
                        title: 'Are you sure you want to complete this assessment?',
                        message:
                            'You will not be able to send follow-up questions after the assessment is marked complete.',
                        confirmLabel: 'Yes, complete',
                        cancelLabel: 'Go back',
                    })
                    .pipe(
                        switchMap((result) =>
                            result
                                ? this._requestService.completeLatestAssessment(relationshipId).pipe(
                                      map(() => completeLatestAssessmentRequestSuccess()),
                                      catchError(() => of(completeLatestAssessmentRequestFailed())),
                                  )
                                : of(openReviewFollowupDialog()),
                        ),
                    ),
            ),
        ),
    );

    sendLatestAssessmentFollowupRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(sendLatestAssessmentFollowupRequest),
            switchMap(({ relationshipId }) =>
                this._requestService.sendLatestAssessmentFollowup(relationshipId).pipe(
                    map(() => sendLatestAssessmentFollowupRequestSuccess()),
                    catchError(() => of(sendLatestAssessmentFollowupRequestFailed())),
                ),
            ),
        ),
    );

    updateLatestAssessmentFollowupMethodRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(updateFollowupMethodRequest),
            switchMap(({ relationshipId, followupType, followupRiskThreshold }) =>
                this._requestService
                    .updateLatestAssessmentFollowupMethod(relationshipId, followupType, followupRiskThreshold)
                    .pipe(
                        tap(() => this._snackbarService.success('Follow-up method updated successfully.')),
                        map(() => updateFollowupTypeRequestSuccess()),
                        catchError(() => of(updateFollowupTypeRequestFailed())),
                    ),
            ),
        ),
    );

    combineSupplementalQuestionnaireConfigurations$ = createEffect(() =>
        combineLatest([
            this._actions$.pipe(ofType(getOrgSupplementalQuestionnaireConfigRequestSuccess)),
            this._actions$.pipe(ofType(getRelationshipSupplementalQuestionnaireConfigRequestSuccess)),
        ]).pipe(
            map(
                ([
                    { orgSupplementalQuestionnaireConfig },
                    { relationshipSupplementalQuestionnaireConfigResponse },
                ]) => ({
                    clientConfig: orgSupplementalQuestionnaireConfig,
                    relationshipConfig:
                        relationshipSupplementalQuestionnaireConfigResponse?.supplementalQuestionnaireConfig,
                }),
            ),
            map(({ clientConfig, relationshipConfig }) =>
                !Object.keys(relationshipConfig ?? {}).length
                    ? combineSupplementalQuestionnaireConfiguration({
                          usingOrgDefaults: true,
                          questionnaires: clientConfig,
                      })
                    : combineSupplementalQuestionnaireConfiguration({
                          usingOrgDefaults: false,
                          questionnaires: clientConfig
                              ?.map((suppQ) => ({
                                  ...suppQ,
                                  enabled: relationshipConfig[suppQ.id],
                              }))
                              .sort((a, b) => (a.questionnaireName < b.questionnaireName ? 1 : -1)),
                      }),
            ),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _snackbarService: SnackbarService,
        private _requestService: RelationshipService,
        private _riskAssessmentService: RiskAssessmentService,
        private _contactService: ContactService,
        private _primaryVendorContactService: PrimaryVendorContactService,
        private _confirmDialogService: MatConfirmDialogService,
        private _dialogService: MatDialogWrapperService,
        private _tagService: TagService,
        private _assessmentUtils: AssessmentUtilsService,
        private _remediationUtils: RemediationUtilsService,
        private _featureFlagService$: FeatureFlagService,
        private _store$: Store,
    ) {}
}
