<ng-container [ngSwitch]="subStep">
    <app-artifact-upload-recommendations
        data-pw="artifactUploadSubStep"
        *ngSwitchCase="AdditionalInformationSubSteps.UPLOAD_RECOMMENDATIONS"
        [vendorName]="vendorName"
        [recommendations]="artifactUploadRecommendations"
        (continued)="uploadRecommendationsContinued.emit()"
        (wentBack)="uploadRecommendationsWentBack.emit()"
        (uploadArtifact)="uploadArtifact.emit($event)"
        (removeArtifact)="removeArtifact.emit($event)"
        (setPassword)="setPassword.emit($event)"
        (onWhenAddingFileFailed)="onWhenAddingFileFailed.emit($event)"
        (updateRecommendation)="updateRecommendation.emit($event)"
    />

    <app-answer-questionnaire
        data-pw="questionnaireSubStep"
        *ngSwitchCase="AdditionalInformationSubSteps.ANSWER_QUESTIONNAIRE"
        [clientName]="clientName"
        [questionnaire]="questionnaire"
        [forFollowup]="followupRequested"
        [followupControlDomainIds]="followupControlDomainIds"
        [relevantControlDomainIds]="relevantControlDomainIds"
        [activeSupplementalQuestionnaires]="activeSupplementalQuestionnaires"
        (wentBack)="questionnaireWentBack.emit()"
        (wentToCollectArtifacts)="questionnaireWentBackToCollectArtifacts.emit()"
        (continued)="questionnaireContinued.emit()"
        (questionnaireUpdated)="questionnaireUpdated.emit($event)"
    />
</ng-container>
