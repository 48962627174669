import * as fromRoot from '../../../shared/redux/state';
import { ControlDomain } from '@entities/control-domain';

export const controlsStateKey: string = 'controls';

export interface ControlsState {
    controls: ControlDomain[];
    orgControls: ControlDomain[];
}

export const initialState: ControlsState = {
    controls: [],
    orgControls: [],
};

export interface AppState extends fromRoot.AppState {
    controls: ControlsState;
}
