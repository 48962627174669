<ng-container
    *ngIf="{
        latestAssessment: latestAssessment$ | async,
        assessments: assessments$ | async,
        relationship: relationship$ | async,
    } as ctx"
>
    <div class="row my-3" *ngIf="!!ctx.relationship.riskOverrideAppliedDate && !isOnlyLegacyAssessmentPresent">
        <div class="col risk-override-banner">
            <mat-card>
                <mat-card-content>
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="d-flex align-items-center">
                            <div class="me-3">Custom risk override</div>
                            <div>
                                <app-risk-chip
                                    [risk]="ctx.relationship.latestRiskAssessment.risk"
                                    [condensed]="true"
                                ></app-risk-chip>
                            </div>
                        </div>
                        <div class="d-flex align-items-center">
                            <div>
                                Applied by
                                {{ ctx.relationship.latestRiskAssessment.lastUserToSetLegacyEmail }} on
                                {{ ctx.relationship.riskOverrideAppliedDate | date }}
                            </div>
                            <ng-container *jhiHasAnyAuthority="[Roles.OrgAdmin]">
                                <button class="ms-4" mat-button visoButton="secondary" (click)="editRiskReview.emit()">
                                    Edit
                                </button>
                            </ng-container>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>

    <div class="row my-3">
        <div class="col d-flex align-items-center">
            <h3 class="lead mb-0">Assessments</h3>
        </div>
        <div class="col-auto d-flex align-items-center">
            <ng-container *jhiHasAnyAuthority="[Roles.OrgAdmin, Roles.OrgUser]">
                <ng-container *hideIfOrgUserIsNotBusinessOwner="businessOwner">
                    <ng-container *ngIf="showRecertificationButton$ | async">
                        <button
                            *disableStartAssessmentOnRules="ctx.relationship"
                            mat-button
                            visoButton="secondary"
                            data-pw="assessmentsHandler-completed-startRecertification"
                            (click)="confirmRecertifyAssessment.emit()"
                        >
                            Start recertification
                        </button>
                    </ng-container>

                    <ng-container *ngIf="showStartAnotherAssessmentButton$ | async">
                        <button
                            *disableStartAssessmentOnRules="ctx.relationship"
                            type="button"
                            mat-button
                            visoButton="link"
                            data-pw="assessmentsHandler-completed-startAnotherAssessment"
                            (click)="startAssessment.emit()"
                        >
                            Start another assessment
                        </button>
                    </ng-container>
                </ng-container>

                <button
                    type="button"
                    class="ms-3"
                    mat-button
                    visoButton="secondary"
                    [routerLink]="assessmentSummaryConfigLink"
                >
                    <mat-icon>settings</mat-icon>
                    Configure assessment summary
                </button>
            </ng-container>
        </div>
    </div>

    <ng-container *ngIf="!isOnlyLegacyAssessmentPresent; else onlyLegacyAssessment">
        <div class="row mb-2">
            <div class="col">
                <app-assessment-list-item
                    [assessment]="ctx.latestAssessment"
                    [assessmentSummarySectionConfig]="assessmentSummarySectionConfig"
                    [relationship]="ctx.relationship"
                    [defaultExpanded]="true"
                    [businessOwner]="businessOwner"
                    [currentUser]="currentAccount$ | async"
                    (firstOpen)="loadAssessmentData($event)"
                    (startAssessment)="startAssessment.emit()"
                    (cancelAssessment)="cancelAssessment.emit()"
                    (proceedWithAvailableData)="proceedWithAvailableData(ctx.latestAssessment.id)"
                    (sendReminderEmail)="sendReminderEmailToUser(ctx.latestAssessment.id)"
                    (reviewRisk)="editRiskReview.emit()"
                    (reviewFollowup)="reviewFollowup.emit()"
                    (editFollowup)="editFollowup.emit()"
                />
            </div>
        </div>

        <div class="row mb-2" *ngFor="let pastAssessment of ctx.assessments; trackBy: trackByAssessmentId">
            <div class="col">
                <app-assessment-list-item
                    [assessment]="pastAssessment"
                    [assessmentSummarySectionConfig]="assessmentSummarySectionConfig"
                    [relationship]="ctx.relationship"
                    [businessOwner]="businessOwner"
                    [currentUser]="currentAccount$ | async"
                    (firstOpen)="loadAssessmentData($event)"
                />
            </div>
        </div>
    </ng-container>

    <ng-template #onlyLegacyAssessment>
        <div class="only-legacy-assessment-container">
            <div class="block-center mb-5 mt-5 wd-xl">
                <mat-card>
                    <mat-card-content>
                        <p class="text-center">
                            The assessment of this relationship was completed outside of VISO TRUST platform
                        </p>
                        <ng-container *jhiHasAnyAuthority="[Roles.OrgAdmin, Roles.OrgUser]">
                            <ng-container *hideIfOrgUserIsNotBusinessOwner="businessOwner">
                                <ng-container *ngIf="showStartAnotherAssessmentButton$ | async">
                                    <button
                                        *disableStartAssessmentOnRules="relationship$ | async"
                                        mat-button
                                        visoButton="secondary"
                                        color="default"
                                        class="full-width"
                                        data-pw="assessmentHandlerStartAssessmentButton"
                                        (click)="startAssessment.emit()"
                                    >
                                        Start assessment
                                    </button>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </ng-template>
</ng-container>
