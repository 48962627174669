<div class="row mb-3">
    <div class="col">
        <h3 class="lead mb-0">Details</h3>
    </div>
    <div class="col-auto" *hideIfOrgUserIsNotBusinessOwner="businessOwner">
        <a
            *jhiHasNotAuthority="[Roles.Auditor, Roles.Support, Roles.ReadOnly]"
            exportRemovableAction
            class="text-primary"
            [routerLink]="editLink"
            [replaceUrl]="true"
        >
            Edit
        </a>
    </div>
</div>
<div class="row">
    <div class="col-12" *jhiHasAnyAuthority="Roles.Auditor">
        <strong>Created by</strong>
        <p>{{ creator.email }}</p>
    </div>
    <div class="col">
        <strong>Created on</strong>
        <p>{{ createdDate | date }}</p>
    </div>
    <div class="col-12" *ngIf="homepage && isVendorManaged">
        <strong>Homepage</strong>
        <a class="d-block mb-2" [externalLink]="homepage">{{ homepage }}</a>
    </div>
    <div class="col-12" *ngIf="businessPurpose">
        <strong>Business purpose</strong>
        <p class="mb-0" data-pw="rdpBusinessPurpose">
            {{ showMoreDescription ? businessPurpose : (businessPurpose | slice: 0 : descriptionMaxLength) }}
        </p>
        <a
            *ngIf="showtoggleDescriptionLength"
            exportRemovableAction
            class="text-primary cursor-pointer"
            (click)="toggleDescriptionLength()"
        >
            {{ showMoreDescription ? 'Read Less' : 'Read More' }}
        </a>
    </div>
</div>
