<div mat-dialog-title>
    Edit follow-up method
    <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <app-followup-type-selection
        [followupTypeControl]="followupTypeControl"
        [followupRiskThreshold]="followupRiskThresholdControl"
        [conciergeEnabled]="false"
    />
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" class="me-2" matDialogClose>Cancel</button>
    <button mat-flat-button color="primary" (click)="submit()">Save changes</button>
</mat-dialog-actions>
