import { Action, createReducer, on } from '@ngrx/store';
import { QuestionnaireManagementState, initialState } from './questionnaire-management.state';
import {
    cancelCreateSupplementalQuestionnaireDialog,
    createSupplementalQuestionnaireRequestFailed,
    createSupplementalQuestionnaireRequestSuccess,
    getSupplementalQuestionnairesRequestSuccess,
    importSupplementalQuestionnaireRequest,
    importSupplementalQuestionnaireRequestCancelled,
    importSupplementalQuestionnaireRequestFailed,
    importSupplementalQuestionnaireRequestProgress,
    importSupplementalQuestionnaireRequestSuccess,
} from './questionnaire-management.actions';
import { parseImportErrors } from '../../../bulk-import/bulk-import.service';

const reducer = createReducer(
    initialState,
    on(getSupplementalQuestionnairesRequestSuccess, (state, { questionnaires }) => ({
        ...state,
        supplementalQuestionnaires: questionnaires,
    })),
    on(getSupplementalQuestionnairesRequestSuccess, (state, { questionnaires }) => ({
        ...state,
        supplementalQuestionnaires: questionnaires,
    })),
    on(importSupplementalQuestionnaireRequestSuccess, (state, { importReport }) => {
        const parsedErrors: Map<string, string> = parseImportErrors(importReport?.errors);

        let importErrors: { key: string; value: string }[] = [];
        if (importReport?.errors?.errorCount > 0) {
            for (let errorType in parsedErrors) {
                importErrors.push(parsedErrors[errorType]);
            }
        }
        return {
            ...state,
            importedQuestions: Object.keys(importReport?.questionsByLine)
                .map(Number)
                .sort((a, b) => a - b)
                .map((key) => importReport?.questionsByLine[key]),
            importJobId: importReport?.importJob?.id,
            supportId: importReport?.importJob?.supportId,
            importErrors,
        };
    }),
    on(importSupplementalQuestionnaireRequestProgress, (state, { percent }) => ({
        ...state,
        percentUploaded: percent,
    })),
    on(importSupplementalQuestionnaireRequest, (state, { file }) => ({
        ...state,
        percentUploaded: null,
        importJobId: null,
        supportId: null,
        importErrors: null,
        importedQuestions: null,
        importFileName: file.name,
    })),
    on(
        cancelCreateSupplementalQuestionnaireDialog,
        importSupplementalQuestionnaireRequestCancelled,
        importSupplementalQuestionnaireRequestFailed,
        createSupplementalQuestionnaireRequestSuccess,
        createSupplementalQuestionnaireRequestFailed,
        (state) => ({
            ...state,
            percentUploaded: null,
            importJobId: null,
            supportId: null,
            importErrors: null,
            importFileName: null,
            importedQuestions: null,
        }),
    ),
);

export const questionnaireManagementReducer = (
    state: QuestionnaireManagementState = initialState,
    action: Action,
): QuestionnaireManagementState => reducer(state, action);
