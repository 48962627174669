import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, withLatestFrom } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AssessmentSubmissionContext, AdditionalInformationSubSteps, StepIds } from '../../models';
import {
    continueFromInitialUpload,
    continueFromWelcomePage,
    expiredDocumentsScreenWentBack,
    goBackFromInitialUpload,
    goBackFromSubmission,
    navigateToCollectArtifacts,
    navigateToQuestionnaireForFollowup,
    setCurrentCollectArtifactsSubStep,
    setCurrentStep,
    setSubmissionContext,
} from '../actions';
import { getAreThereUploadRecommendations, getIsFollowupRequested } from '../selectors';

@Injectable()
export class StepsNavigationEffects {
    continueFromWelcomePage$ = createEffect(() =>
        this._actions$.pipe(
            ofType(continueFromWelcomePage),
            withLatestFrom(this._store$.select(getIsFollowupRequested)),
            map(([, isFollowupRequested]) =>
                isFollowupRequested ? navigateToQuestionnaireForFollowup() : navigateToCollectArtifacts(),
            ),
        ),
    );

    goBackToWelcomeFromExpiredDocuments$ = createEffect(() =>
        this._actions$.pipe(
            ofType(expiredDocumentsScreenWentBack),
            map(() => setCurrentStep({ stepId: StepIds.INITIAL_ARTIFACT_UPLOAD })),
        ),
    );

    navigateToQuestionnaireForFollowup$ = createEffect(() =>
        this._actions$.pipe(
            ofType(navigateToQuestionnaireForFollowup),
            mergeMap(() => [
                setCurrentStep({ stepId: StepIds.ADDITIONAL_INFORMATION }),
                setSubmissionContext({ context: AssessmentSubmissionContext.FOLLOWUP_QUESTIONNAIRE }),
                setCurrentCollectArtifactsSubStep({
                    collectArtifactsSubStep: AdditionalInformationSubSteps.ANSWER_QUESTIONNAIRE,
                }),
            ]),
        ),
    );

    navigateToCollectArtifacts$ = createEffect(() =>
        this._actions$.pipe(
            ofType(navigateToCollectArtifacts),
            mergeMap(() => [
                setSubmissionContext({ context: AssessmentSubmissionContext.FILE_ARTIFACTS }),
                setCurrentStep({ stepId: StepIds.INITIAL_ARTIFACT_UPLOAD }),
            ]),
        ),
    );

    continueFromInitialUpload$ = createEffect(() =>
        this._actions$.pipe(
            ofType(continueFromInitialUpload),
            withLatestFrom(this._store$.select(getAreThereUploadRecommendations)),
            mergeMap(([, areThereRecommendations]) => [
                setCurrentStep({ stepId: areThereRecommendations ? StepIds.ADDITIONAL_INFORMATION : StepIds.SUBMIT }),
                setCurrentCollectArtifactsSubStep({
                    collectArtifactsSubStep: AdditionalInformationSubSteps.UPLOAD_RECOMMENDATIONS,
                }),
            ]),
        ),
    );

    goBackFromInitialUpload$ = createEffect(() =>
        this._actions$.pipe(
            ofType(goBackFromInitialUpload),
            map(() => setCurrentStep({ stepId: StepIds.WELCOME })),
        ),
    );

    goBackFromSubmission$ = createEffect(() =>
        this._actions$.pipe(
            ofType(goBackFromSubmission),
            withLatestFrom(this._store$.select(getAreThereUploadRecommendations)),
            map(([, areThereRecommendations]) =>
                setCurrentStep({
                    stepId: areThereRecommendations ? StepIds.ADDITIONAL_INFORMATION : StepIds.INITIAL_ARTIFACT_UPLOAD,
                }),
            ),
        ),
    );

    constructor(
        private _store$: Store,
        private _actions$: Actions,
    ) {}
}
