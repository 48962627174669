import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { VisoUser, VisoUserRole } from '@entities/viso-user';
import { ActiveStatus, SupplementalQuestionnaireConfig } from '@entities/org';

@Component({
    selector: 'app-supplemental-questionnaires',
    templateUrl: './supplemental-questionnaires.component.html',
    styleUrls: ['./supplemental-questionnaires.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplementalQuestionnairesComponent {
    @Input({ required: true })
    relationshipId: number;

    @Input({ required: true })
    businessOwner: VisoUser;

    @Input({ required: true })
    combinedSupplementalQuestionnaireConfig: SupplementalQuestionnaireConfig[];

    Roles = VisoUserRole;

    constructor() {}

    get enabledSupplementalQuestionnaires() {
        return this.combinedSupplementalQuestionnaireConfig?.filter(
            (suppQ) => suppQ.enabled && suppQ.activeStatus === ActiveStatus.ACTIVE,
        );
    }

    get supplementalQuestionnaireDialogLink() {
        if (!this.relationshipId) {
            return [];
        }

        return [
            '/',
            {
                outlets: {
                    popup: `relationships/${this.relationshipId}/supplemental-questionnaires`,
                },
            },
        ];
    }
}
