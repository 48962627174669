import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Risk } from '@entities/risk-assessment';
import { ClientProfile, ClientProfileDTOResponse, DataTypeConfig, OrgRiskConfig } from './client-profile.model';
import { DataSensitivityLevel } from './data-sensitivity-levels-config/data-sensitivity-level.model';
import {
    AssessmentSummarySection,
    UpdateAssessmentSummarySectionConfigRequest,
} from '@shared/assessment-components/components/assessment-summary-config/model/assessment-summary-config-models';
import { BYPASS_SNACKBAR_ON_ERROR } from '../../blocks/interceptor/errorhandler.interceptor';

@Injectable()
export class ClientProfileService {
    private _resourceUrl = 'api/client-profile/';

    constructor(private _http: HttpClient) {}

    getForOrg(orgId: number): Observable<ClientProfile> {
        return this._http
            .get(`${this._resourceUrl}${orgId}`, {
                observe: 'response',
                context: new HttpContext().set(BYPASS_SNACKBAR_ON_ERROR, { statuses: [404] }),
            })
            .pipe(map((res) => this.formatClientProfile(res.body as ClientProfileDTOResponse)));
    }

    getForCurrentSession(): Observable<ClientProfile> {
        return this._http
            .get(`${this._resourceUrl}`, {
                observe: 'response',
            })
            .pipe(map((res) => this.formatClientProfile(res.body as ClientProfileDTOResponse)));
    }

    updateAssessmentSummarySectionConfigForClient(
        updateAssessmentSummarySectionConfigRequest: UpdateAssessmentSummarySectionConfigRequest,
    ) {
        return this._http.put(
            `${this._resourceUrl}assessment-summary-config`,
            updateAssessmentSummarySectionConfigRequest,
        );
    }

    updateRiskConfig(profileId: string, riskConfig: OrgRiskConfig) {
        return this._http.put(
            `${this._resourceUrl}${profileId}/risk-config/${riskConfig.riskLevel}`,
            riskConfig.displayName,
            {
                observe: 'response',
            },
        );
    }

    updateDataSensitivityConfig(profileId: string, dataSensitivityLevel: DataSensitivityLevel, displayName: string) {
        return this._http.put(
            `${this._resourceUrl}${profileId}/data-sensitivity-config/${dataSensitivityLevel}`,
            displayName,
            {
                observe: 'response',
            },
        );
    }

    updateCustomDataTypeForOrg(profileId: string, dataType: DataTypeConfig) {
        return this._http.put(`${this._resourceUrl}${profileId}/data-type-config`, dataType, {
            observe: 'response',
        });
    }

    createCustomDatatypeForOrg(profileId: string, dataType: DataTypeConfig) {
        return this._http.post(`${this._resourceUrl}${profileId}/data-type-config`, dataType, {
            observe: 'response',
        });
    }

    enableDataTypeForOrg(profileId: string, dataTypeId: number) {
        return this._http.put(`${this._resourceUrl}${profileId}/data-type-config/${dataTypeId}`, {
            observe: 'response',
        });
    }

    disableDataTypeForOrg(profileId: string, dataTypeId: number) {
        return this._http.delete(`${this._resourceUrl}${profileId}/data-type-config/${dataTypeId}`, {
            observe: 'response',
        });
    }

    enableControlForOrg(profileId: string, controlId: number) {
        return this._http.put(`${this._resourceUrl}${profileId}/control-config/${controlId}`, {
            observe: 'response',
        });
    }

    disableControlForOrg(profileId: string, controlId: number) {
        return this._http.delete(`${this._resourceUrl}${profileId}/control-config/${controlId}`, {
            observe: 'response',
        });
    }

    private formatClientProfile(profile: ClientProfileDTOResponse): ClientProfile {
        const clientProfile: ClientProfile = {
            id: profile.id,
            orgId: profile.orgId,
            orgControlDomainConfigs: profile.orgControlDomainConfigs,
            dataTypes: profile.dataTypes,
            riskLevels: [],
            riskTolerance: profile.riskTolerance,
            dataSensitivityLevels: [],
            assessmentSummarySections: [],
        };

        const riskLevels = profile.riskLevels;
        clientProfile.riskLevels = Object.keys(riskLevels).map((r) => ({
            riskLevel: r as Risk,
            displayName: riskLevels[r],
        }));

        const dataSensitivityLevels = profile.dataSensitivityLevels;
        clientProfile.dataSensitivityLevels = Object.keys(dataSensitivityLevels).map((dsl) => ({
            dataSensitivityLevel: dsl as DataSensitivityLevel,
            displayName: dataSensitivityLevels[dsl],
        }));

        const summarySections = profile.assessmentSummarySections;
        if (!!summarySections) {
            clientProfile.assessmentSummarySections = Object.keys(summarySections).map((section) => ({
                section: section as AssessmentSummarySection,
                visible: summarySections[section],
            }));
        }
        return clientProfile;
    }
}
