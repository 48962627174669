import { createAction, props } from '@ngrx/store';
import { ImportJobView, RelationshipImportReport } from '../import-job.model';

export enum BulkImportActions {
    GetImportHistoryRequest = '[Bulk Import] Get Import History Request',
    GetImportHistoryRequestSuccess = '[Bulk Import] Get Import History Request Success',
    GetImportHistoryRequestFailed = '[Bulk Import] Get Import History Request Failed',
    UploadImportCsvRequest = '[Bulk Import] Upload Import Csv Request',
    UploadImportCsvRequestSuccess = '[Bulk Import] Upload Import Csv Request Success',
    UploadImportCsvRequestFailed = '[Bulk Import] Upload Import Csv Request Failed',
    UploadImportCsvRequestCancelled = '[Bulk Import] Upload Import Csv Request Cancelled',
    UploadImportCsvRequestProgress = '[Bulk Import] Upload Import Csv Request Progress',
    ConfirmUploadedImportRequest = '[Bulk Import] Confirm Uploaded Import Request',
    ConfirmUploadedImportRequestSuccess = '[Bulk Import] Confirm Uploaded Import Request Success',
    ConfirmUploadedImportRequestFailed = '[Bulk Import] Confirm Uploaded Import Request Failed',
    DownloadImportedFileRequest = '[Bulk Import] Download Imported File Request',
    DownloadImportedFileRequestSuccess = '[Bulk Import] Download Imported File Request Success',
    DownloadImportedFileRequestFailed = '[Bulk Import] Download Imported File Request Failed',
}

export const getImportHistoryRequest = createAction(BulkImportActions.GetImportHistoryRequest);

export const getImportHistoryRequestSuccess = createAction(
    BulkImportActions.GetImportHistoryRequestSuccess,
    props<{ importHistory: ImportJobView[] }>(),
);

export const getImportHistoryRequestFailed = createAction(BulkImportActions.GetImportHistoryRequestFailed);

export const uploadImportCsvRequest = createAction(BulkImportActions.UploadImportCsvRequest, props<{ file: File }>());

export const uploadImportCsvRequestCancelled = createAction(BulkImportActions.UploadImportCsvRequestCancelled);

export const uploadImportCsvRequestSuccess = createAction(
    BulkImportActions.UploadImportCsvRequestSuccess,
    props<{ importReport: RelationshipImportReport }>(),
);

export const uploadImportCsvRequestFailed = createAction(BulkImportActions.UploadImportCsvRequestFailed);

export const uploadImportCsvRequestProgress = createAction(
    BulkImportActions.UploadImportCsvRequestProgress,
    props<{ percent: number }>(),
);

export const confirmUploadedImportRequest = createAction(
    BulkImportActions.ConfirmUploadedImportRequest,
    props<{ importJobId: number }>(),
);

export const confirmUploadedImportRequestSuccess = createAction(
    BulkImportActions.ConfirmUploadedImportRequestSuccess,
    props<{ importReport: RelationshipImportReport }>(),
);

export const confirmUploadedImportRequestFailed = createAction(BulkImportActions.ConfirmUploadedImportRequestFailed);

export const downloadImportedFileRequest = createAction(
    BulkImportActions.DownloadImportedFileRequest,
    props<{ importJobId: number }>(),
);

export const downloadImportedFileRequestSuccess = createAction(
    BulkImportActions.DownloadImportedFileRequestSuccess,
    props<{ importReport: RelationshipImportReport }>(),
);

export const downloadImportedFileRequestFailed = createAction(BulkImportActions.DownloadImportedFileRequestFailed);
