<div class="row flex-nowrap">
    <div class="col-auto">
        <div class="mb-0 mt-4 pb-1">
            <mat-label class="section-label">Sections Included</mat-label>
        </div>
        <mat-list class="pt-2">
            <mat-list-item class="form-group flex-row ps-0" *ngFor="let summarySection of getSectionLabels">
                <div matListItemTitle class="d-inline">{{ AssessmentSummarySectionLabels[summarySection] }}</div>
                <mat-slide-toggle
                    matListItemMeta
                    color="primary"
                    labelPosition="before"
                    [formControl]="getVisibleFormControlBySummarySection(AssessmentSummarySection[summarySection])"
                    (change)="toggleAssessmentSummarySection()"
                ></mat-slide-toggle>
            </mat-list-item>
        </mat-list>
    </div>
    <div class="col-auto">
        <mat-divider class="vertical-divider" [vertical]="true" />
    </div>
    <div class="col">
        <div #assessmentSummary class="pt-4 scrollable-summary" [innerHTML]="exampleSummary | safeHtml"></div>
    </div>
</div>
