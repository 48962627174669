<div class="row mb-3">
    <div class="col">
        <h3 class="lead">Supplemental questionnaires</h3>
    </div>
    <ng-container *hideIfOrgUserIsNotBusinessOwner="businessOwner">
        <div class="col-auto">
            <a
                *jhiHasNotAuthority="[Roles.Auditor, Roles.Support, Roles.ReadOnly]"
                exportRemovableAction
                class="text-primary clickable"
                [routerLink]="supplementalQuestionnaireDialogLink"
            >
                Edit
            </a>
        </div>
    </ng-container>
</div>
<p class="text-muted">Add non-risk impacting security controls to the relationship scope.</p>
<div class="row">
    <div class="col">
        <mat-list>
            @for (supplementalQuestionnaire of enabledSupplementalQuestionnaires; track supplementalQuestionnaire) {
                <mat-list-item class="flex-row ps-0">
                    <div matListItemTitle class="title-text">
                        {{ supplementalQuestionnaire.questionnaireName }}
                    </div>
                </mat-list-item>
            }
        </mat-list>
    </div>
</div>
