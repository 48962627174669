import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { AppState } from '@shared/redux/state';
import { QuestionnaireManagementState, questionnaireManagementStateKey } from './questionnaire-management.state';
import { SupplementalQuestionnaireResponse } from '@entities/supplemental-questionnaire';

export const getQuestionnaireManagementState: MemoizedSelector<AppState, QuestionnaireManagementState> =
    createFeatureSelector<QuestionnaireManagementState>(questionnaireManagementStateKey);

export const getSupplementalQuestionnaires: MemoizedSelector<AppState, SupplementalQuestionnaireResponse[]> =
    createSelector(getQuestionnaireManagementState, (state) => state.supplementalQuestionnaires);

export const getSupplementalQuestionnaireImportJobId: MemoizedSelector<AppState, number> = createSelector(
    getQuestionnaireManagementState,
    (state) => state.importJobId,
);

export const getSupplementalQuestionnaireSupportId: MemoizedSelector<AppState, string> = createSelector(
    getQuestionnaireManagementState,
    (state) => state.supportId,
);

export const getSupplementalQuestionnaireImportErrors: MemoizedSelector<AppState, { key: string; value: string }[]> =
    createSelector(getQuestionnaireManagementState, (state) => state.importErrors);

export const getSupplementalQuestionnaireImportFileName: MemoizedSelector<AppState, string> = createSelector(
    getQuestionnaireManagementState,
    (state) => state.importFileName,
);

export const getSupplementalQuestionnaireImportPercentUploaded: MemoizedSelector<AppState, number> = createSelector(
    getQuestionnaireManagementState,
    (state) => state.percentUploaded,
);

export const getImportedSupplementalQuestionnaireReport: MemoizedSelector<AppState, string[]> = createSelector(
    getQuestionnaireManagementState,
    (state) => state.importedQuestions,
);
