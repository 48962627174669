import { Routes } from '@angular/router';

import { QuestionnaireComponent } from './questionnaire.component';
import { AuthGuard } from '../../shared';
import { VisoUserRole } from '@entities/viso-user';
import { FeatureFlags } from '@shared/enums/feature-flags';
import { FeatureFlagGuard } from '@shared/guards/feature-flag.guard';
import { questionnaireResolver } from './resolvers/questionnaire.resolver';
import { relationshipNameResolver } from './resolvers/relationship-name.resolver';
import { questionnaireReferencedArtifactsResolver } from './resolvers/questionnaire-referenced-artifacts.resolver';
import { QuestionnaireManagementComponent } from './questionnaire-management/questionnaire-management.component';
import { editSupplementalQuestionnaireResolver } from './resolvers/edit-supplemental-questionnaire.resolver';

export enum QuestionnaireRouteType {
    RELATIONSHIP = 'RELATIONSHIP',
    TRUST_QUESTIONNAIRE = 'TRUST_QUESTIONNAIRE',
    SUPPLEMENTAL_QUESTIONNAIRES = 'SUPPLEMENTAL_QUESTIONNAIRES',
    GENERIC_QUESTIONNAIRE = 'GENERIC_QUESTIONNAIRE',
}

export const questionnaireRoutes: Routes = [
    {
        path: 'requests/:relationshipId/questionnaire/:questionnaireId',
        component: QuestionnaireComponent,
        data: {
            questionnaireRouteType: QuestionnaireRouteType.RELATIONSHIP,
        },
        resolve: {
            questionnaire: questionnaireResolver,
            artifacts: questionnaireReferencedArtifactsResolver,
            relationshipName: relationshipNameResolver,
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'intelligent-questionnaire-response/trust-questionnaire/:questionnaireId',
        component: QuestionnaireComponent,
        data: {
            authorities: [VisoUserRole.OrgAdmin],
            featureFlag: FeatureFlags.TRUST,
            questionnaireRouteType: QuestionnaireRouteType.TRUST_QUESTIONNAIRE,
        },
        resolve: {
            questionnaire: questionnaireResolver,
            artifacts: questionnaireReferencedArtifactsResolver,
        },
        canActivate: [AuthGuard, FeatureFlagGuard],
    },
    {
        path: 'questionnaire/:supplementalQuestionnaireId/edit',
        data: {
            questionnaireRouteType: QuestionnaireRouteType.SUPPLEMENTAL_QUESTIONNAIRES,
        },
        children: [],
        canActivate: [AuthGuard],
        outlet: 'popup',
        resolve: {
            supplementalQuestionnaire: editSupplementalQuestionnaireResolver,
        },
    },
    {
        path: 'questionnaire/:questionnaireId',
        component: QuestionnaireComponent,
        data: {
            questionnaireRouteType: QuestionnaireRouteType.GENERIC_QUESTIONNAIRE,
        },
        resolve: {
            questionnaire: questionnaireResolver,
            artifacts: questionnaireReferencedArtifactsResolver,
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'questionnaires',
        component: QuestionnaireManagementComponent,
        data: {
            featureFlag: FeatureFlags.SUPPLEMENTAL_QUESTIONNAIRES,
            authorities: [VisoUserRole.OrgAdmin, VisoUserRole.OrgUser, VisoUserRole.ReadOnly],
            pageTitle: 'Questionnaire management',
        },
        canActivate: [AuthGuard, FeatureFlagGuard],
    },
];
