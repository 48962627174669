import { Routes } from '@angular/router';

import { AuthGuard } from '../../shared';
import { YourOrganizationComponent } from './your-organization.component';
import { YourOrganizationPopupComponent } from './your-organization-dialog.component';
import { VisoUserRole } from '@entities/viso-user';

export const yourOrganizationRoute: Routes = [
    {
        path: 'your-organization',
        component: YourOrganizationComponent,
        data: {
            authorities: [VisoUserRole.OrgAdmin, VisoUserRole.OrgUser, VisoUserRole.ReadOnly],
            pageTitle: 'Your Organization Profile',
        },
        canActivate: [AuthGuard],
    },
];

export const yourOrganizationPopupRoute: Routes = [
    {
        path: 'your-organization/edit',
        component: YourOrganizationPopupComponent,
        data: {
            authorities: [VisoUserRole.OrgAdmin],
            pageTitle: 'Edit Public Profile',
        },
        canActivate: [AuthGuard],
        outlet: 'popup',
    },
];
