import { select, Store } from '@ngrx/store';
import { map, takeUntil } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { FeatureFlags } from '@shared/enums/feature-flags';
import { FeatureFlagService } from '@shared/services/featureflag.service';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { getUserAuthority } from '../../../../session/redux/session.selectors';
import { VisoUserRole } from '@entities/viso-user';
import { SupplementalQuestionnaireConfig } from '@entities/org';
import { SupplementalQuestionnaireConfigForm } from './supplemental-questionnaires-config-form/supplemental-questionnaires-config-form.component';
import {
    deleteRelationshipSupplementalQuestionnaireConfigRequest,
    updateRelationshipSupplementalQuestionnaireConfigRequest,
} from '../../../redux/actions/supplemental-questionnaire.actions';
import { UpdateRelationshipSupplementalQuestionnaireConfigRequest } from '@entities/relationship';
import { getSupplementalQuestionnaireConfig } from '../../../redux/request.selectors';

@Component({
    selector: 'app-supplemental-questionnaires-dialog',
    templateUrl: './supplemental-questionnaires-dialog.component.html',
    styleUrls: ['./supplemental-questionnaires-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplementalQuestionnairesDialogComponent implements OnInit, OnDestroy {
    @Input({ required: true })
    relationshipId: number;

    loadingConfiguration: boolean;

    isCurrentUserOrgAdmin$: Observable<boolean>;
    hasSupplementalQuestionnairesEnabled$: Observable<boolean>;
    supplementalQuestionnaireConfig$: Observable<SupplementalQuestionnaireConfig[]>;
    usingRelationshipSupplementalQuestionnaireConfig$: Observable<boolean>;

    supplementalQuestionnaireConfig: SupplementalQuestionnaireConfigForm[];

    private _unsub$ = new Subject<void>();

    constructor(
        private _store$: Store,
        private _featureFlagsService: FeatureFlagService,
        public _dialogRef: MatDialogRef<SupplementalQuestionnairesDialogComponent>,
    ) {}

    ngOnInit(): void {
        this.supplementalQuestionnaireConfig$ = this._store$
            .select(getSupplementalQuestionnaireConfig)
            .pipe(map((config) => config.questionnaires));

        this.usingRelationshipSupplementalQuestionnaireConfig$ = this._store$
            .select(getSupplementalQuestionnaireConfig)
            .pipe(map((config) => !config.usingOrgDefaults));

        this.hasSupplementalQuestionnairesEnabled$ = this._featureFlagsService.hasFeatureFlagEnabled(
            FeatureFlags.SUPPLEMENTAL_QUESTIONNAIRES,
        );

        this.loadUserRole();
    }

    private loadUserRole() {
        this.isCurrentUserOrgAdmin$ = this._store$.pipe(
            select(getUserAuthority(VisoUserRole.OrgAdmin)),
            takeUntil(this._unsub$),
        );
    }

    onQuestionnaireConfigChanges(updatedConfig: SupplementalQuestionnaireConfigForm[]) {
        this.supplementalQuestionnaireConfig = updatedConfig;
    }

    saveSupplementalQuestionnaireConfig() {
        let updateRequest: UpdateRelationshipSupplementalQuestionnaireConfigRequest = {
            supplementalQuestionnaireConfig: this.supplementalQuestionnaireConfig.reduce(
                (acc, suppQ) => {
                    acc[suppQ.id] = suppQ.enabled;
                    return acc;
                },
                {} as Record<string, boolean>,
            ),
        };

        this._store$.dispatch(
            updateRelationshipSupplementalQuestionnaireConfigRequest({
                relationshipId: this.relationshipId,
                updateRequest,
            }),
        );
    }

    deleteRelationshipSupplementalQuestionnaireConfig() {
        this._store$.dispatch(
            deleteRelationshipSupplementalQuestionnaireConfigRequest({
                relationshipId: this.relationshipId,
            }),
        );
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
