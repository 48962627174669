import { AfterViewInit, Component, Input, OnDestroy, OnInit, Provider, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { INgxSelectOption, NgxSelectComponent } from 'ngx-select-ex';
import { RefSelectOption } from '../../model/select-options';
import { TagColorEnum } from '../../../entities/tag';
import {
    searchOrganizationsRequest,
    searchOrganizationsRequestSuccess,
} from '../../../routes/request/redux/actions/organization.actions';
import { generateRandomUUID } from '../../helpers/uuid-helpers';
import { VendorSearchResult } from '../models/vendor-search-result';
import { IMAGE_FALLBACK_BASE64 } from '../../image-fallback/image-fallback.constants';

const VENDOR_SEARCH_VALUE_ACCESSOR: Provider = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => VendorSearchComponent),
    multi: true,
};

@Component({
    selector: 'app-vendor-search',
    templateUrl: 'vendor-search.component.html',
    styleUrls: ['vendor-search.component.scss'],
    providers: [VENDOR_SEARCH_VALUE_ACCESSOR],
})
export class VendorSearchComponent implements ControlValueAccessor, OnInit, OnDestroy, AfterViewInit {
    @Input()
    placeholderText: string = 'Enter a company name, website address, or product name';

    @Input()
    customOption: boolean = true;

    @Input()
    customOptionPrefixText: string = 'Create new vendor';

    readonly vendorImageFallback: string = IMAGE_FALLBACK_BASE64;

    vendorOrgs$ = new BehaviorSubject<RefSelectOption<VendorSearchResult>[]>([]);
    TagColor = TagColorEnum;
    vendorSearchFormControl = new FormControl<VendorSearchResult>(null);

    disabled$ = new Subject<boolean>();

    private _onTouched: Function = () => {};
    private _onChanged: Function = (value: unknown) => {};

    private _unsub$ = new Subject<void>();
    private _componentId: string;

    @ViewChild(NgxSelectComponent)
    private _select: NgxSelectComponent;

    constructor(
        private _store$: Store,
        private _actions$: Actions,
    ) {}

    writeValue(obj?: VendorSearchResult): void {
        const options = this.vendorOrgs$.value;
        const { id, name } = obj || {};
        let value;
        if (!id && !name) {
            this.vendorSearchFormControl.setValue(null);
            return;
        }
        const matchingOption = options.find((option) => !!option.ref.id && option.ref.id === id);
        if (matchingOption) {
            value = matchingOption.ref;
        } else {
            value = obj;
            this.vendorOrgs$.next([
                ...options,
                {
                    ref: obj,
                    name: obj.name,
                },
            ]);
        }
        this.vendorSearchFormControl.setValue(value);
    }

    registerOnChange(fn: any): void {
        this._onChanged = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled$.next(isDisabled);
    }

    ngOnInit(): void {
        this._componentId = generateRandomUUID();

        this._actions$
            .pipe(
                ofType(searchOrganizationsRequestSuccess),
                filter(({ componentId }) => !!componentId && componentId === this._componentId),
                map(({ organizations, searchText }) => {
                    const vendors = organizations.map<RefSelectOption<VendorSearchResult>>((profile) => ({
                        name: profile.name,
                        ref: profile,
                    }));
                    if (this.customOption && searchText) {
                        vendors.push({
                            ref: {
                                id: null,
                                name: searchText,
                                homepage: null,
                                faviconUrl: null,
                                isNewVendor: true,
                            },
                            name: `${this.customOptionPrefixText} \"${searchText}\"`,
                        });
                    }
                    return vendors;
                }),
                takeUntil(this._unsub$),
            )
            .subscribe((organizations) => this.vendorOrgs$.next(organizations));

        this.disabled$
            .pipe(takeUntil(this._unsub$))
            .subscribe((disabled) =>
                disabled ? this.vendorSearchFormControl.disable() : this.vendorSearchFormControl.enable(),
            );

        this.vendorSearchFormControl.valueChanges.pipe(takeUntil(this._unsub$)).subscribe((value) => {
            this._onTouched();
            this._onChanged(value);
        });
    }

    ngAfterViewInit(): void {
        this._select.typed
            .pipe(
                debounceTime(500),
                distinctUntilChanged(),
                filter((value) => !!value),
                takeUntil(this._unsub$),
            )
            .subscribe((searchText) => this.searchForVendor(searchText));
    }

    searchForVendor(searchText: string) {
        this._store$.dispatch(searchOrganizationsRequest({ searchText, componentId: this._componentId }));
    }

    searchCallbackIgnoreFilter(search: string, item: INgxSelectOption): boolean {
        return true;
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
