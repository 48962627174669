import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map, startWith, tap } from 'rxjs/operators';
import { LinkNotification, NotificationService, NotificationType } from '../../../entities/notification';
import { SnackbarService } from '../../../shared/components/snackbar/snackbar.service';
import { urlValidator } from '@shared/validators/url-validator';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
    notificationsCreateFormGroup: UntypedFormGroup;
    previewLinkNotification$: Observable<LinkNotification>;
    savingNotification: boolean = false;

    get showUrlPatternError(): boolean {
        return !!this.notificationsCreateFormGroup.controls.url.errors?.pattern;
    }

    constructor(
        private _fb: UntypedFormBuilder,
        private _snackbarService: SnackbarService,
        private _notificationsService: NotificationService,
    ) {}

    ngOnInit(): void {
        this.notificationsCreateFormGroup = this._fb.group({
            orgId: [null],
            vendorRef: [null],
            anchorText: ['', Validators.required],
            url: ['', [Validators.required, urlValidator]],
            description: ['', Validators.required],
        });

        this.previewLinkNotification$ = this.notificationsCreateFormGroup.valueChanges.pipe(
            startWith({}),
            map(
                ({ anchorText, description, url }) =>
                    ({
                        anchorText,
                        description,
                        url,
                    }) as LinkNotification,
            ),
            map((previewNotification) =>
                Object.values(previewNotification).filter((x) => !!x).length ? previewNotification : null,
            ),
            map((previewLinkNotification) =>
                !!previewLinkNotification
                    ? ({
                          ...previewLinkNotification,
                          humanizedCreatedDate: 'About 0 minutes ago',
                      } as LinkNotification)
                    : null,
            ),
        );
    }

    createLinkNotification(): void {
        this.savingNotification = true;
        const formValues = this.notificationsCreateFormGroup.value;

        const payload = {
            ...formValues,
            vendorRef: undefined,
            orgId: formValues.vendorRef?.id,
        };

        payload.notificationType = NotificationType.LINK_NOTIFICATION;

        this._notificationsService
            .createLinkNotification(payload)
            .pipe(
                tap(() => {
                    this.onSuccessfulSave();
                    this.cleanForm();
                }),
                catchError(() => of(null)),
                finalize(() => (this.savingNotification = false)),
            )
            .subscribe();
    }

    private cleanForm(): void {
        this.notificationsCreateFormGroup.reset();
    }

    private onSuccessfulSave(): void {
        this._snackbarService.success('Link Notification created.');
    }
}
