import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SharedModule } from '../../shared';
import { RouterModule } from '@angular/router';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { EffectsModule } from '@ngrx/effects';
import { AssessmentSummaryManagementEffects } from './redux/assessment-summary-management.effects';
import { AssessmentSummaryManagementComponent } from './assessment-summary-management.component';
import { assessmentSummaryMgmtRoutes } from './assessment-summary-management.route';
import { AssessmentComponentsModule } from '@shared/assessment-components/assessment-components.module';

const ENTITY_STATES = [...assessmentSummaryMgmtRoutes];

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild(ENTITY_STATES),
        NgxDatatableModule,
        EffectsModule.forFeature([AssessmentSummaryManagementEffects]),
        AssessmentComponentsModule,
    ],
    declarations: [AssessmentSummaryManagementComponent],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AssessmentSummaryManagementModule {
    constructor() {}
}
