<div *dynamicContent="breadcrumbsContainerToken">
    <ol class="breadcrumb breadcrumb px-0 pb-0">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
        <li class="breadcrumb-item active">Questionnaires</li>
    </ol>
</div>
<div class="content-heading">
    <div>Questionnaires<span class="beta-badge h6">BETA</span></div>
</div>

<p class="mb-0">
    We'll attempt to answer these automatically using your vendor's artifacts and follow up for manual response to any
    remaining questions.
</p>
<p>
    Download our
    <a class="text-secondary text-decoration-underline" [href]="IMPORT_QUESTIONNAIRE_TEMPLATE_URL"
        >questionnaire template</a
    >
    or create one from scratch.
</p>

<div class="questionnaire-limit-container d-flex align-items-center" *ngIf="!doesOrgHaveAvailableQuestions">
    <mat-icon class="me-2" fontSet="material-symbols-outlined">lock</mat-icon>
    <div>
        You have met the limit of {{ maxSupplementalQuestionnaireQuestions }} questions.
        <p class="small mb-0">
            Please contact <a href="mailto:sales@visotrust.com">sales&#64;visotrust.com</a> for more information.
        </p>
    </div>
</div>

<div class="questionnaire-header d-flex justify-content-between align-items-center">
    <strong>Your license includes a limit of {{ maxSupplementalQuestionnaireQuestions }} total questions.</strong>
    <div>
        <button
            mat-flat-button
            [matMenuTriggerFor]="menu"
            color="primary"
            *jhiHasAnyAuthority="Roles.OrgAdmin"
            [disabled]="!doesOrgHaveAvailableQuestions"
        >
            <mat-icon>arrow_drop_down</mat-icon>
            Add questionnaire
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="importQuestionnaire()">Import</button>
            <button mat-menu-item (click)="createQuestionnaire()">Create</button>
        </mat-menu>
    </div>
</div>

<table
    mat-table
    [dataSource]="dataSource"
    matSort
    [matSortActive]="sortByColumnName"
    [matSortDirection]="sortDirection"
    matSortDisableClear
    matSortStart="desc"
>
    <ng-container matColumnDef="questionnaireName" [sticky]="true">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let row">
            <a class="viso-link" [routerLink]="getQuestionnaireEditUrl(row)">
                {{ row.questionnaireName }}
            </a>
        </td>
    </ng-container>
    <ng-container matColumnDef="createdDate" [sticky]="true">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Uploaded on</th>
        <td mat-cell *matCellDef="let row">{{ row.createdDate | date: 'mediumDate' }}</td>
    </ng-container>
    <ng-container matColumnDef="questions" [sticky]="true">
        <th mat-header-cell *matHeaderCellDef>Question count ({{ questionCount }})</th>
        <td mat-cell *matCellDef="let row">
            <span>{{ row.questionCount }}</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="enabled" [sticky]="true">
        <th mat-header-cell *matHeaderCellDef>Enabled for every relationship</th>
        <td mat-cell *matCellDef="let row">
            <span>{{ row.enabled ? 'Yes' : 'No' }}</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="actions" stickyEnd [sticky]="true">
        <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
        <td mat-cell *matCellDef="let row" class="d-flex justify-content-center">
            <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item [routerLink]="getQuestionnaireEditUrl(row)">Edit questionnaire</button>
                <button mat-menu-item class="text-danger" (click)="deleteQuestionnaire(row)">
                    Delete questionnaire
                </button>
            </mat-menu>
        </td>
    </ng-container>

    <ng-container *ngIf="columnDefinitions$ | async as columnDefinition">
        <tr mat-header-row *matHeaderRowDef="columnDefinition"></tr>
        <tr mat-row *matRowDef="let row; columns: columnDefinition"></tr>
        <tr *matNoDataRow>
            <td colspan="5">
                <div class="d-flex justify-content-center p-3">
                    <ng-container *ngIf="!questionnairesLoading; else loading">
                        <span>No questionnaires uploaded yet.</span>
                    </ng-container>
                    <ng-template #loading>
                        <div class="sk-spinner sk-spinner-pulse"></div>
                    </ng-template>
                </div>
            </td>
        </tr>
    </ng-container>
</table>
<mat-paginator
    [pageSizeOptions]="[10, 20, 30]"
    [showFirstLastButtons]="true"
    [length]="totalCurrentItems"
    [pageSize]="itemsPerPage"
    [pageIndex]="page"
    (page)="setCurrentPage($event)"
    aria-label="Select page of questionnaires"
>
</mat-paginator>
