<div *dynamicContent="breadcrumbsContainerToken">
    <ol class="breadcrumb breadcrumb px-0 pb-0">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
        <ng-container *ngIf="questionnaireRouteType === QuestionnaireRouteType.RELATIONSHIP">
            <li class="breadcrumb-item"><a [routerLink]="['/relationships']">Relationships</a></li>
            <li class="breadcrumb-item">
                <a [routerLink]="['/requests/', requestId]">{{ relationshipName }}</a>
            </li>
        </ng-container>
        <ng-container *ngIf="questionnaireRouteType === QuestionnaireRouteType.SUPPLEMENTAL_QUESTIONNAIRES">
            <li class="breadcrumb-item"><a [routerLink]="['/questionnaires']">Questionnaires</a></li>
        </ng-container>
        <ng-container *ngIf="questionnaireRouteType === QuestionnaireRouteType.TRUST_QUESTIONNAIRE">
            <li class="breadcrumb-item">
                <a [routerLink]="['/intelligent-questionnaire-response']">Intelligent Questionnaire Response</a>
            </li>
        </ng-container>
        <li class="breadcrumb-item active">
            {{ getQuestionnaireTitle() }}
            Questionnaire
        </li>
    </ol>
</div>

<div class="viso-heading">
    <div class="col">
        <ng-container
            *ngIf="
                questionnaireRouteType === QuestionnaireRouteType.RELATIONSHIP &&
                    !this.questionnaireArtifact?.supplemental;
                else showQuestionnaireName
            "
        >
            Questionnaire Response
        </ng-container>
        <ng-template #showQuestionnaireName
            >{{ questionnaireArtifact?.fileName || 'Questionnaire Response' }}
        </ng-template>
        <div class="viso-subheading">Completed {{ relativeCompletedDate }}</div>
    </div>
    <div class="col-auto">
        <button mat-fab class="fab-outlined" extended (click)="downloadQuestionnaire()">
            <mat-icon>file_download</mat-icon>
            Download
        </button>
        <button
            *ngIf="hasAdditionalCompletedQuestionnaires"
            mat-fab
            (click)="sideNav.toggle()"
            class="fab-outlined ms-2"
        >
            <mat-icon>history</mat-icon>
        </button>
    </div>
</div>

<mat-drawer-container>
    <mat-drawer #sideNav mode="side" opened="false" position="end">
        <mat-selection-list #question [multiple]="false">
            <p class="ms-2 mb-0 pb-1 border-bottom"><strong>Completed Date</strong></p>
            <mat-list-option
                *ngFor="let questionnaire of questionnaireArtifacts; trackBy: trackByQuestionnaireId"
                [value]="questionnaire.id"
                (click)="viewQuestionnaire(questionnaire.id)"
                [selected]="isQuestionnaireCurrent(questionnaire)"
                [color]="'primary'"
            >
                {{ questionnaire.createdDate | date: 'shortDate' }}
            </mat-list-option>
        </mat-selection-list>
    </mat-drawer>
    <mat-drawer-content>
        <app-questionnaire-response
            *ngIf="!!questionnaireAnswers"
            [questionnaireAnswers]="questionnaireAnswers"
            [requestArtifacts]="requestArtifacts"
            [supplementalQuestionnairesEnabled]="supplementalQuestionnairesEnabled$ | async"
        >
        </app-questionnaire-response>
    </mat-drawer-content>
</mat-drawer-container>
