<div class="d-flex align-items-center">
    <div>
        Inherent Risk
        <app-risk-chip class="ms-2" [risk]="inherentRisk" [condensed]="true" />
    </div>
    <mat-icon>arrow_right</mat-icon>
    <div>
        Residual Risk
        <app-risk-chip class="ms-2" [risk]="residualRisk" [condensed]="true" />
    </div>
</div>
