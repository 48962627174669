<div class="row mb-3">
    <div class="col">
        <h3 class="lead mb-0">Business case</h3>
    </div>
</div>
<ng-container *ngIf="!!contextTypes?.length; else noBusinessCase">
    <div class="row context-type" *ngFor="let contextType of contextTypes">
        <div class="col">
            <p class="mb-0 p-2 d-flex border-top align-items-center" (mouseleave)="pop.hide()">
                {{ contextType.name }}
                <em
                    class="fa fa-info-circle text-primary d-none ms-auto"
                    #pop="bs-popover"
                    [popover]="popover"
                    [placement]="'right'"
                    (mouseenter)="pop.show()"
                ></em>
                <ng-template #popover>
                    <div class="popover-detail">
                        {{ contextType.description }}
                    </div>
                </ng-template>
            </p>
        </div>
    </div>
</ng-container>
<ng-template #noBusinessCase>
    <p class="text-muted">No business case selected</p>
</ng-template>
