import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileLikeObject, FileUploader } from '@shared/file-upload';
import { MimeType } from '@shared/upload-artifacts';
import { IMPORT_QUESTIONNAIRE_TEMPLATE_URL } from '@shared/constants/url.constants';
import { FilterFunction } from '@shared/file-upload/interfaces';
import { SupplementalQuestionnaireResponse } from '@entities/supplemental-questionnaire';

@Component({
    selector: 'app-supplemental-questionnaire-import',
    templateUrl: './supplemental-questionnaire-import.component.html',
    styleUrl: './supplemental-questionnaire-import.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplementalQuestionnaireImportComponent implements OnInit {
    @Input({ required: true })
    importErrors: { key: string; value: string }[];

    @Input({ required: true })
    supportId: string;

    @Input({ required: true })
    importPercentUploaded: number;

    @Input({ required: true })
    importFileName: string;

    @Input({ required: true })
    maxSupplementalQuestionnaireQuestions: number;

    @Input({ required: true })
    supplementalQuestionnaires: SupplementalQuestionnaireResponse[];

    @Output()
    importSupplementalQuestionnaire = new EventEmitter<File>();

    @Output()
    onWhenAddingFileFailed = new EventEmitter<FilterFunction>();

    questionCount: number = 0;
    availableQuestions: number = 0;
    uploader: FileUploader;
    allowedMimeType: MimeType = MimeType.CSV;

    readonly IMPORT_QUESTIONNAIRE_TEMPLATE_URL = IMPORT_QUESTIONNAIRE_TEMPLATE_URL;

    ngOnInit(): void {
        this.setupFileUploader();
        this.questionCount = 0;
        this.supplementalQuestionnaires.forEach((questionnaire) => {
            this.questionCount += questionnaire.questionCount;
        });
        this.availableQuestions = this.maxSupplementalQuestionnaireQuestions - this.questionCount;
    }

    importQuestionnaire(files: File[]) {
        this.importSupplementalQuestionnaire.emit(files[0]);
    }

    private setupFileUploader(): void {
        this.uploader = new FileUploader({
            url: '',
            maxFileSize: 100000000,
            allowedMimeType: this.allowedMimeType.mimeType,
        });

        this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
            this.onWhenAddingFileFailed.emit(filter);
        };
    }
}
