import { createSelector, MemoizedSelector } from '@ngrx/store';
import { AppState } from '../request.state';
import { AssessmentStatus, AssessmentView } from '@entities/assessment';
import { getRelationshipState } from '../request.selectors';
import { HasEmails, PopulatedAssessment } from '../../models/populated-assessment';
import { AssessmentRecommendation, RecommendationType } from '@entities/recommendation';
import { RemediationRequest } from '@entities/remediation-request/remediation-request.model';

const getRelationshipAssessments: MemoizedSelector<AppState, AssessmentView[]> = createSelector(
    getRelationshipState,
    (state) => state.assessments.allAssessments,
);

export const isRelationshipLatestAssessmentLoaded: MemoizedSelector<AppState, boolean> = createSelector(
    getRelationshipState,
    (state) => state.assessments.latestAssessmentLoaded,
);

const getRelationshipAssessmentPropertiesMap: MemoizedSelector<
    AppState,
    Map<number | string, HasEmails>
> = createSelector(
    getRelationshipState,
    (state) => new Map(Object.entries(state.assessments.propertiesMap).map(([k, v]) => [+k, v])),
);

const getRelationshipPopulatedAssessments: MemoizedSelector<AppState, PopulatedAssessment[]> = createSelector(
    getRelationshipAssessments,
    getRelationshipAssessmentPropertiesMap,
    (assessments, propertiesMap) =>
        assessments.map<PopulatedAssessment>((assessment) => {
            const { emails } = propertiesMap.get(assessment.id) || {};
            return {
                ...assessment,
                emails,
            };
        }),
);

export const getRelationshipLatestAssessment: MemoizedSelector<AppState, AssessmentView> = createSelector(
    getRelationshipState,
    (state) => state.assessments.latestAssessment,
);

export const getRelationshipPopulatedLatestAssessment: MemoizedSelector<AppState, PopulatedAssessment> = createSelector(
    getRelationshipLatestAssessment,
    getRelationshipAssessmentPropertiesMap,
    (latestAssessment, propertiesMap) => {
        if (!latestAssessment) {
            return null;
        }
        const { emails } = propertiesMap.get(latestAssessment.id) || {};
        return {
            ...latestAssessment,
            emails,
        };
    },
);

export const getRelationshipPopulatedPastAssessments: MemoizedSelector<AppState, PopulatedAssessment[]> =
    createSelector(
        getRelationshipPopulatedAssessments,
        getRelationshipPopulatedLatestAssessment,
        (assessments, latestAssessment) =>
            assessments
                ?.filter(
                    (assessment) =>
                        assessment.id !== latestAssessment?.id &&
                        assessment.status !== AssessmentStatus.AWAITING_REMEDIATION,
                )
                .sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()),
    );

export const getRelationshipLatestCompletedAssessment: MemoizedSelector<AppState, PopulatedAssessment> = createSelector(
    getRelationshipPopulatedAssessments,
    (sortedAssessments) => sortedAssessments.find((a) => a.status === AssessmentStatus.COMPLETED),
);

export const getLatestCompletedAssessmentRecommendations: MemoizedSelector<AppState, AssessmentRecommendation[]> =
    createSelector(getRelationshipLatestCompletedAssessment, (latestCompleted) => latestCompleted?.recommendations);

export const getLatestCompletedAssessmentNotCombinedRecommendations: MemoizedSelector<
    AppState,
    AssessmentRecommendation[]
> = createSelector(getLatestCompletedAssessmentRecommendations, (recommendations) =>
    recommendations?.filter((r) => r.type !== RecommendationType.COMBINED),
);

export const getRelationshipLatestCompletedAssessmentDate: MemoizedSelector<AppState, Date | null> = createSelector(
    getRelationshipLatestCompletedAssessment,
    (latestCompleted) => latestCompleted?.auditCompletedDate ?? null,
);

export const getRelationshipLatestCompletedAssessmentRemediationRequest: MemoizedSelector<
    AppState,
    RemediationRequest
> = createSelector(getRelationshipLatestCompletedAssessment, (latestCompleted) => latestCompleted?.remediationRequest);
