import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, mergeMap, of, switchMap } from 'rxjs';
import { PublicAssessmentService } from '@entities/assessment';
import { AuditReportService, AuditReportTypeCode } from '@entities/audit-report';
import { AuthenticationFailedReasons } from '../../models';
import {
    cancelAssessmentRequest,
    cancelAssessmentRequestFailed,
    cancelAssessmentRequestSuccess,
    createQuestionnaireRequest,
    createQuestionnaireRequestFailed,
    createQuestionnaireRequestSuccess,
    declineRemediationRequest,
    declineRemediationRequestFailed,
    declineRemediationRequestSuccess,
    forwardRequestRequest,
    forwardRequestRequestFailed,
    forwardRequestRequestSuccess,
    getAuditReportsRequest,
    getAuditReportsRequestFailed,
    getAuditReportsRequestSuccess,
    getPublicAssessmentClientBrandingRequest,
    getPublicAssessmentClientBrandingRequestFailed,
    getPublicAssessmentClientBrandingRequestSuccess,
    getPublicAssessmentRequest,
    getPublicAssessmentRequestFailed,
    getPublicAssessmentRequestSuccess,
    getRecommendationsRequest,
    getRecommendationsRequestFailed,
    getRecommendationsRequestSuccess,
    getSecurityControlDomainRequest,
    getSecurityControlDomainRequestFailed,
    getSecurityControlDomainRequestSuccess,
    extendAssessmentExpirationRequest,
    extendAssessmentExpirationRequestFailed,
    extendAssessmentExpirationRequestSuccess,
    removeArtifactRequest,
    removeArtifactRequestFailed,
    removeArtifactRequestSuccess,
    saveQuestionnaireRequest,
    saveQuestionnaireRequestFailed,
    saveQuestionnaireRequestSuccess,
    setPasswordForArtifactRequest,
    setPasswordForArtifactRequestFailed,
    setPasswordForArtifactRequestSuccess,
    submitAssessmentRequest,
    submitAssessmentRequestFailed,
    submitAssessmentRequestSuccess,
    uploadFilesRequest,
    uploadFilesRequestFailed,
    uploadFilesRequestSuccess,
} from '../actions';
import { withLatestFrom } from 'rxjs/operators';
import { getAssessmentToken } from '../selectors';
import { Store } from '@ngrx/store';
import { UpdateQuestionnaireRequest } from '@entities/artifact';

const IGNORED_AUDIT_REPORTS = [
    AuditReportTypeCode.SUPERSEDED,
    AuditReportTypeCode.APPASSESSISSUES,
    AuditReportTypeCode.PENTESTISSUES,
];

@Injectable()
export class RequestEffects {
    getPublicAssessmentRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getPublicAssessmentRequest),
            switchMap(({ assessmentToken, secret }) =>
                this._publicAssessmentService.find(assessmentToken, secret).pipe(
                    map((publicAssessment) => getPublicAssessmentRequestSuccess({ publicAssessment })),
                    catchError((error: HttpErrorResponse) => {
                        const isExpired = error.error.errorKey === AuthenticationFailedReasons.EXPIRED;
                        const isInvalidLogin = error.status === 403;
                        const authenticationFailedReason = isExpired
                            ? AuthenticationFailedReasons.EXPIRED
                            : isInvalidLogin
                              ? AuthenticationFailedReasons.INVALID_SECRET
                              : AuthenticationFailedReasons.UNKNOWN;
                        return of(getPublicAssessmentRequestFailed({ error, authenticationFailedReason }));
                    }),
                ),
            ),
        ),
    );

    getClientOrgBranding$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getPublicAssessmentClientBrandingRequest),
            withLatestFrom(this._store$.select(getAssessmentToken)),
            switchMap(([, token]) =>
                this._publicAssessmentService.getClientOrgBranding(token).pipe(
                    map((branding) => getPublicAssessmentClientBrandingRequestSuccess({ branding })),
                    catchError(() => of(getPublicAssessmentClientBrandingRequestFailed())),
                ),
            ),
        ),
    );

    cancelAssessmentRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(cancelAssessmentRequest),
            switchMap(({ assessmentToken, secret, vendorDetails }) =>
                this._publicAssessmentService
                    .cancelAssessmentDueToClientVendorNoLongerDoingBusiness(assessmentToken, secret, vendorDetails)
                    .pipe(
                        map(() => cancelAssessmentRequestSuccess()),
                        catchError((error) => of(cancelAssessmentRequestFailed({ error }))),
                    ),
            ),
        ),
    );

    extendAssessmentExpirationRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(extendAssessmentExpirationRequest),
            switchMap(({ assessmentToken, secret }) =>
                this._publicAssessmentService.extendAssessmentExpiration(assessmentToken, secret).pipe(
                    map(() => extendAssessmentExpirationRequestSuccess()),
                    catchError((error) => of(extendAssessmentExpirationRequestFailed({ error }))),
                ),
            ),
        ),
    );

    forwardRequestRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(forwardRequestRequest),
            switchMap(({ assessmentToken, secret, vendorDetails }) =>
                this._publicAssessmentService
                    .forwardAssessmentToNewContact(assessmentToken, secret, vendorDetails)
                    .pipe(
                        map(() => forwardRequestRequestSuccess()),
                        catchError((error) => of(forwardRequestRequestFailed({ error }))),
                    ),
            ),
        ),
    );

    declineRemediationRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(declineRemediationRequest),
            switchMap(({ assessmentToken, secret, vendorDetails }) =>
                this._publicAssessmentService.declineRemediation(assessmentToken, secret, vendorDetails).pipe(
                    map(() => declineRemediationRequestSuccess()),
                    catchError((error) => of(declineRemediationRequestFailed({ error }))),
                ),
            ),
        ),
    );

    getRecommendationsRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getRecommendationsRequest),
            switchMap(({ assessmentToken, secret }) =>
                this._publicAssessmentService.getRecommendations(assessmentToken, secret).pipe(
                    map((recommendations) => getRecommendationsRequestSuccess({ recommendations })),
                    catchError((error) => of(getRecommendationsRequestFailed({ error }))),
                ),
            ),
        ),
    );

    getAuditReportsRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getAuditReportsRequest),
            switchMap(() =>
                this._auditReportService.queryPublicAuditReports().pipe(
                    map((response) => response.body),
                    map((auditReports) =>
                        auditReports
                            .filter((auditReport) => !IGNORED_AUDIT_REPORTS.includes(auditReport.auditReport))
                            .sort((a, b) => a.auditReportLongName.localeCompare(b.auditReportLongName)),
                    ),
                    map((auditReports) => getAuditReportsRequestSuccess({ auditReports })),
                    catchError((error) => of(getAuditReportsRequestFailed({ error }))),
                ),
            ),
        ),
    );

    uploadFilesRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(uploadFilesRequest),
            mergeMap(({ uploadFileArtifactRequests, assessmentToken, secret, replacedArtifactId }) =>
                this._publicAssessmentService.uploadFiles(uploadFileArtifactRequests, secret, assessmentToken).pipe(
                    map((response) =>
                        uploadFilesRequestSuccess({
                            uploadFileArtifactRequests,
                            successfullyUploadedArtifacts: response.successfullyUploadedArtifacts,
                            duplicateArtifactFileNames: response.duplicateArtifactFileNames,
                            replacedArtifactId,
                        }),
                    ),
                    catchError((error) => of(uploadFilesRequestFailed({ error, uploadFileArtifactRequests }))),
                ),
            ),
        ),
    );

    removeArtifactRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(removeArtifactRequest),
            mergeMap(({ artifactId, assessmentToken, secret, artifactType }) =>
                this._publicAssessmentService.deleteFile(assessmentToken, secret, artifactId).pipe(
                    map(() => removeArtifactRequestSuccess({ artifactType, artifactId })),
                    catchError((error) => of(removeArtifactRequestFailed({ error }))),
                ),
            ),
        ),
    );

    setPasswordForArtifactRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(setPasswordForArtifactRequest),
            switchMap(({ artifactId, password, secret, assessmentToken }) =>
                this._publicAssessmentService
                    .savePasswordForArtifact(artifactId, password, secret, assessmentToken)
                    .pipe(
                        map(() => setPasswordForArtifactRequestSuccess()),
                        catchError((error) => of(setPasswordForArtifactRequestFailed({ error }))),
                    ),
            ),
        ),
    );

    getSecurityControlDomainRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getSecurityControlDomainRequest),
            switchMap(({ assessmentToken, secret }) =>
                this._publicAssessmentService.getSecurityControlDomain(assessmentToken, secret).pipe(
                    map((securityControlDomain) => getSecurityControlDomainRequestSuccess({ securityControlDomain })),
                    catchError((error) => of(getSecurityControlDomainRequestFailed({ error }))),
                ),
            ),
        ),
    );

    createQuestionnaireRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(createQuestionnaireRequest),
            switchMap(({ assessmentToken, secret }) =>
                this._publicAssessmentService.createQuestionnaireArtifact(assessmentToken, secret).pipe(
                    map((questionnaire) => createQuestionnaireRequestSuccess({ questionnaire })),
                    catchError((error) => of(createQuestionnaireRequestFailed({ error }))),
                ),
            ),
        ),
    );

    saveQuestionnaireRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(saveQuestionnaireRequest),
            switchMap(({ assessmentToken, secret, questionnaire }) =>
                this._publicAssessmentService
                    .updateQuestionnaire(assessmentToken, secret, new UpdateQuestionnaireRequest(questionnaire))
                    .pipe(
                        map(() => saveQuestionnaireRequestSuccess()),
                        catchError((error) => of(saveQuestionnaireRequestFailed({ error }))),
                    ),
            ),
        ),
    );

    submitAssessmentRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(submitAssessmentRequest),
            exhaustMap(({ payload }) =>
                this._publicAssessmentService.submit(payload).pipe(
                    map(() => submitAssessmentRequestSuccess()),
                    catchError((error) => of(submitAssessmentRequestFailed({ error }))),
                ),
            ),
        ),
    );

    constructor(
        private _store$: Store,
        private _actions$: Actions,
        private _publicAssessmentService: PublicAssessmentService,
        private _auditReportService: AuditReportService,
    ) {}
}
