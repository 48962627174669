<ul class="assessment-status-timeline mb-0" [ngClass]="{ 'more-information-needed': isMoreInformationNeeded() }">
    <li
        *ngFor="let event of assessmentEvents(); index as i; last as last"
        [ngClass]="{ current: event.current, done: event.done }"
    >
        <div class="status">
            <app-badge
                *ngIf="event.current"
                class="m-0"
                type="timeline"
                [noBorderRadius]="true"
                [striped]="isMoreInformationNeeded() && i === 2"
            >
                {{ event.title }}
            </app-badge>
            <strong *ngIf="!event.current">{{ event.title }}</strong>
            <div class="state-help-text mt-1">
                <div class="text-muted" *ngIf="event.current || event.done">
                    {{ event.subtitle }}
                </div>
            </div>
            <em *ngIf="isMoreInformationNeeded() && i === 1" class="fas fa-caret-left"></em>
        </div>
        <div
            *ngIf="!!event.lowerText?.value && (event.done || event.current)"
            class="mt-2 side-text"
            data-pw="stepCompletionText"
        >
            <strong class="d-block">{{ event.lowerText.label }}</strong>
            <span class="text-muted">{{ event.lowerText.value }}</span>
        </div>
    </li>
</ul>
