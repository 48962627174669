import { Routes } from '@angular/router';
import { AuthGuard } from '../../shared';
import { AssessmentSummaryManagementComponent } from './assessment-summary-management.component';

export const assessmentSummaryMgmtRoutes: Routes = [
    {
        path: 'assessment-summary',
        component: AssessmentSummaryManagementComponent,
        canActivate: [AuthGuard],
    },
];
