import { ChangeDetectionStrategy, Component, computed, EventEmitter, input, Output, Signal } from '@angular/core';
import { RecertificationType, Relationship, RequestStatus } from '@entities/relationship';
import { Risk, RiskAssessment } from '@entities/risk-assessment';
import { AssessmentUtilsService } from '@shared/utils/assessment-utils.service';
import { AssessmentStatus, AssessmentType, AssessmentView } from '@entities/assessment';
import { VisoUserRole } from '@entities/viso-user';
import { RemediationStatus } from '@entities/remediation-request/remediation-request.model';

enum ResidualRiskHelpText {
    NOT_ASSESSED = 'Not assessed',
    ASSESSMENT_STARTED = 'Assessment Started',
    ASSESSMENT_COLLECTING_INFORMATION = 'Collecting Information',
    ASSESSMENT_REVIEW_STARTED = 'Review Started',
    RECERTIFICATION_IN_PROGRESS = 'Recertification in progress',
    ARTIFACT_UPDATE_IN_PROGRESS = 'Artifact Update in progress',
    ASSESSMENT_COMPLETED = 'Assessment is complete',
    AWAITING_FOLLOWUP = 'Follow-up recommended',
    AWAITING_REVIEW = 'Awaiting review',
    RISK_ACCEPTED = 'Risk accepted',
    REMEDIATION_REQUESTED = 'Remediation requested',
    REMEDIATION_CANCELLED = 'Remediation cancelled',
    REMEDIATION_DECLINED = 'Remediation declined',
}

enum LifecycleStatus {
    UP_TO_DATE = 'Up To Date',
    UPDATING_ARTIFACTS = 'Updating Artifacts',
    RECERTIFYING_RELATIONSHIP = 'Recertifying Relationship',
    RECERTIFICATION_OVERDUE = 'Recertification Overdue',
    NOT_APPLY = 'N/A',
    NOT_ONBOARDED = 'Not Onboarded',
}

@Component({
    selector: 'app-stat-boxes',
    templateUrl: './stat-boxes.component.html',
    styleUrls: ['./stat-boxes.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatBoxesComponent {
    relationship = input.required<Relationship>();
    latestAssessment = input.required<AssessmentView>();
    latestRiskAssessment = input.required<RiskAssessment>();
    latestNonTransitionalRiskAssessment = input.required<RiskAssessment>();
    isRecertificationOverdue = input.required<boolean>();
    isAssessmentInProgress = input.required<boolean>();
    recertificationDueDaysLeft = input.required<number>();
    isUpForRecertification = input.required<boolean>();

    @Output()
    editRiskReview = new EventEmitter<void>();

    lifecycleStatus: Signal<LifecycleStatus>;
    isNotOnboarded: Signal<boolean>;
    isUpdatingArtifactsOrRecertifying: Signal<boolean>;
    inherentRiskLabel: Signal<Risk>;
    residualRiskLabel: Signal<Risk>;
    residualRiskHelpText: Signal<ResidualRiskHelpText>;
    isResidualRiskTransitionalAndNotOverridden: Signal<boolean>;
    inherentRiskBoxCssClasses: Signal<string>;
    residualRiskBoxCssClasses: Signal<string[]>;
    isRecertificationDisabled: Signal<boolean>;
    recertificationDate: Signal<Date>;
    riskIsOverridden: Signal<boolean>;
    isOnboardedAndRecertificationIsDisabled: Signal<boolean>;

    Roles = VisoUserRole;

    constructor(private _assessmentUtils: AssessmentUtilsService) {
        this.inherentRiskLabel = computed(() => this.latestRiskAssessment()?.inherentRisk);
        this.residualRiskLabel = computed(() => {
            if (this.latestRiskAssessment()?.legacy) {
                return this.latestRiskAssessment()?.risk;
            }
            return this.latestNonTransitionalRiskAssessment()?.risk || this.latestRiskAssessment()?.risk;
        });
        this.isResidualRiskTransitionalAndNotOverridden = computed(
            () => this.latestRiskAssessment()?.transitional && !this.latestRiskAssessment()?.legacy,
        );
        this.inherentRiskBoxCssClasses = computed(() => this.getRisk(this.inherentRiskLabel()));
        this.residualRiskBoxCssClasses = computed(() => [
            this.getRisk(this.residualRiskLabel()),
            this.isResidualRiskTransitionalAndNotOverridden() ? 'transitional' : '',
        ]);
        this.residualRiskHelpText = computed(() => this.getResidualRiskHelpText());
        this.isRecertificationDisabled = computed(
            () => this.relationship()?.recertificationType === RecertificationType.NONE,
        );
        this.recertificationDate = computed(() => this.relationship()?.recertificationDate);
        this.lifecycleStatus = computed(() => this.getLifecycleStatus());
        this.isNotOnboarded = computed(() => this.lifecycleStatus() === LifecycleStatus.NOT_ONBOARDED);
        this.isUpdatingArtifactsOrRecertifying = computed(() =>
            [LifecycleStatus.UPDATING_ARTIFACTS, LifecycleStatus.RECERTIFYING_RELATIONSHIP].includes(
                this.lifecycleStatus(),
            ),
        );
        this.riskIsOverridden = computed(() => this.relationship()?.latestRiskAssessment?.legacy ?? false);
        this.isOnboardedAndRecertificationIsDisabled = computed(
            () => !this.isNotOnboarded() && this.isRecertificationDisabled(),
        );
    }

    private getRisk(risk: Risk): string {
        switch (risk) {
            case Risk.EXTREME:
            case Risk.HIGH:
            case Risk.MEDIUM:
            case Risk.LOW:
                return risk.toString().toLowerCase();
        }
        return '';
    }

    private tryGetRemediationRequestStatusText(latestAssessment: AssessmentView): ResidualRiskHelpText {
        switch (latestAssessment.remediationRequest?.status) {
            case RemediationStatus.REQUESTED:
            case RemediationStatus.ARTIFACTS_PROVIDED:
                return ResidualRiskHelpText.REMEDIATION_REQUESTED;
            case RemediationStatus.CANCELLED:
                return ResidualRiskHelpText.REMEDIATION_CANCELLED;
            case RemediationStatus.DECLINED:
                return ResidualRiskHelpText.REMEDIATION_DECLINED;
            default:
                return null;
        }
    }

    private tryGetRiskAcceptanceStatusText(relationship: Relationship): ResidualRiskHelpText {
        return relationship.riskAccepted ? ResidualRiskHelpText.RISK_ACCEPTED : null;
    }

    private getResidualRiskHelpText(): ResidualRiskHelpText {
        if (!this.latestAssessment()) {
            return ResidualRiskHelpText.NOT_ASSESSED;
        }
        const { status, type } = this.latestAssessment();
        if (this._assessmentUtils.isAssessmentComplete(status)) {
            return (
                this.tryGetRiskAcceptanceStatusText(this.relationship()) ??
                this.tryGetRemediationRequestStatusText(this.latestAssessment()) ??
                ResidualRiskHelpText.AWAITING_REVIEW
            );
        }

        if (this._assessmentUtils.isAssessmentInProgress(status)) {
            switch (type) {
                case AssessmentType.ARTIFACT_UPDATE:
                    return ResidualRiskHelpText.ARTIFACT_UPDATE_IN_PROGRESS;
                case AssessmentType.RECERTIFICATION:
                    return ResidualRiskHelpText.RECERTIFICATION_IN_PROGRESS;
                case AssessmentType.CERTIFICATION:
                case AssessmentType.REMEDIATION:
                    switch (status) {
                        case AssessmentStatus.STARTED:
                            return ResidualRiskHelpText.ASSESSMENT_STARTED;
                        case AssessmentStatus.COLLECTING_INFORMATION:
                            return ResidualRiskHelpText.ASSESSMENT_COLLECTING_INFORMATION;
                        case AssessmentStatus.REVIEW_STARTED:
                            return ResidualRiskHelpText.ASSESSMENT_REVIEW_STARTED;
                        case AssessmentStatus.AUDIT_COMPLETED:
                            return ResidualRiskHelpText.AWAITING_FOLLOWUP;
                    }
            }
        }
    }

    private getLifecycleStatus(): LifecycleStatus {
        if (this.relationship().status !== RequestStatus.ONBOARDED) {
            return LifecycleStatus.NOT_ONBOARDED;
        }

        if (this.relationship().status === RequestStatus.ONBOARDED) {
            if (
                this.isRecertificationDisabled() ||
                !this.latestAssessment() ||
                (this.latestAssessment().type === AssessmentType.CERTIFICATION &&
                    this._assessmentUtils.isAssessmentInProgress(this.latestAssessment().status))
            ) {
                return LifecycleStatus.NOT_APPLY;
            }

            if (
                this.latestAssessment()?.type === AssessmentType.ARTIFACT_UPDATE &&
                this._assessmentUtils.isAssessmentInProgress(this.latestAssessment().status)
            ) {
                return LifecycleStatus.UPDATING_ARTIFACTS;
            }

            if (this.isAssessmentInProgress()) {
                return LifecycleStatus.RECERTIFYING_RELATIONSHIP;
            }

            if (this.isRecertificationOverdue()) {
                return LifecycleStatus.RECERTIFICATION_OVERDUE;
            }

            if (this.latestAssessment()?.status === AssessmentStatus.COMPLETED) {
                return LifecycleStatus.UP_TO_DATE;
            }
        }
    }
}
