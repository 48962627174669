import { Routes } from '@angular/router';

import { AuthGuard } from '../../shared';

import { RelationshipsComponent } from './relationships.component';
import { VisoUserRole } from '@entities/viso-user';

export const relationshipsRoute: Routes = [
    {
        path: 'relationships',
        component: RelationshipsComponent,
        data: {
            authorities: [
                VisoUserRole.OrgAdmin,
                VisoUserRole.Auditor,
                VisoUserRole.Support,
                VisoUserRole.OrgUser,
                VisoUserRole.ReadOnly,
            ],
            pageTitle: 'Relationships',
        },
        canActivate: [AuthGuard],
    },
];

export const relationshipsPopupRoute: Routes = [
    {
        path: 'relationship/:id/edit/details',
        children: [],
        data: {
            authorities: [VisoUserRole.OrgAdmin, VisoUserRole.OrgUser],
        },
        canActivate: [AuthGuard],
        outlet: 'popup',
    },
    {
        path: 'new-user',
        children: [],
        data: {
            authorities: [VisoUserRole.OrgAdmin, VisoUserRole.OrgUser, VisoUserRole.ReadOnly],
        },
        canActivate: [AuthGuard],
        outlet: 'popup',
    },
    {
        path: 'first-assessment-created',
        children: [],
        data: {
            authorities: [VisoUserRole.OrgAdmin, VisoUserRole.OrgUser],
        },
        canActivate: [AuthGuard],
        outlet: 'popup',
    },
];
