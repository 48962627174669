import * as fromRoot from '../../../../shared/redux/state';
import { SupplementalQuestionnaireResponse } from '@entities/supplemental-questionnaire';

export const questionnaireManagementStateKey = 'questionnaire-management';

export interface QuestionnaireManagementState {
    supplementalQuestionnaires: SupplementalQuestionnaireResponse[];
    importJobId: number;
    supportId: string;
    importErrors: { key: string; value: string }[];
    importFileName: string;
    percentUploaded: number;
    importedQuestions: string[];
}

export const initialState: QuestionnaireManagementState = {
    supplementalQuestionnaires: null,
    importJobId: null,
    supportId: null,
    importErrors: [],
    importFileName: null,
    percentUploaded: null,
    importedQuestions: [],
};

export interface AppState extends fromRoot.AppState {
    [questionnaireManagementStateKey]: QuestionnaireManagementState;
}
