import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MiddleTruncateDirective } from './middle-truncate.directive';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    declarations: [MiddleTruncateDirective],
    imports: [CommonModule, MatTooltipModule],
    exports: [MiddleTruncateDirective],
})
export class TruncateModule {}
