import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../shared';
import { questionnaireRoutes } from '.';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSelectModule } from 'ngx-select-ex';
import { EffectsModule } from '@ngrx/effects';
import { MatButtonModule } from '@angular/material/button';
import { QuestionnaireComponent } from './questionnaire.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { ArtifactIntelligenceModule } from '@shared/artifact-intelligence';
import { QuestionnaireResponseComponent } from './questionnaire-response';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { QuestionnaireManagementComponent } from './questionnaire-management/questionnaire-management.component';
import { FileUploadModule } from '@shared/file-upload';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { QuestionnaireManagementEffects } from './questionnaire-management/redux/questionnaire-management.effects';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SkeletonLoaderModule } from '@shared/skeleton-loader/skeleton-loader.module';
import { SupplementalQuestionnaireDialogComponent } from './questionnaire-management/components/supplemental-questionnaire-dialog/supplemental-questionnaire-dialog.component';
import { VisoEditorModule } from '@shared/components/viso-editor/viso-editor.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatButtonToggle, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { StoreModule } from '@ngrx/store';
import { questionnaireManagementReducer } from './questionnaire-management/redux/questionnaire-management.reducer';
import { questionnaireManagementStateKey } from './questionnaire-management/redux/questionnaire-management.state';
import { MatStep, MatStepLabel, MatStepper, MatStepperNext } from '@angular/material/stepper';
import { SupplementalQuestionnaireImportComponent } from './questionnaire-management/components/supplemental-questionnaire-dialog/supplemental-questionnaire-import/supplemental-questionnaire-import.component';
import { SupplementalQuestionnaireEditComponent } from './questionnaire-management/components/supplemental-questionnaire-dialog/supplemental-questionnaire-edit/supplemental-questionnaire-edit.component';
import { CdkStep } from '@angular/cdk/stepper';
import { CustomStepperModule } from '@shared/components/custom-stepper/custom-stepper.module';

const ROUTES = [...questionnaireRoutes];

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild(ROUTES),
        FormsModule,
        ReactiveFormsModule,
        NgxSelectModule,
        StoreModule.forFeature(questionnaireManagementStateKey, questionnaireManagementReducer),
        EffectsModule.forFeature([QuestionnaireManagementEffects]),
        MatButtonModule,
        TextFieldModule,
        MatExpansionModule,
        MatTabsModule,
        ArtifactIntelligenceModule,
        MatSidenavModule,
        MatListModule,
        MatInputModule,
        MatFormFieldModule,
        MatCardModule,
        MatSnackBarModule,
        FileUploadModule,
        MatSlideToggleModule,
        NgxDatatableModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
        MatTooltipModule,
        SkeletonLoaderModule,
        VisoEditorModule,
        MatDialogModule,
        MatMenuTrigger,
        MatMenu,
        MatMenuItem,
        MatButtonToggle,
        MatButtonToggleGroup,
        MatStepper,
        MatStep,
        MatStepperNext,
        MatStepLabel,
        CdkStep,
        CustomStepperModule,
    ],
    declarations: [
        QuestionnaireComponent,
        QuestionnaireResponseComponent,
        QuestionnaireManagementComponent,
        SupplementalQuestionnaireDialogComponent,
        SupplementalQuestionnaireImportComponent,
        SupplementalQuestionnaireEditComponent,
    ],
    exports: [QuestionnaireComponent],
})
export class QuestionnaireModule {}
