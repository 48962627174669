import { ChangeDetectionStrategy, Component, computed, input, Signal } from '@angular/core';
import moment from 'moment';
import {
    AssessmentStatus,
    AssessmentView,
    CollectingInformationReasonLabels,
    FollowupType,
} from '@entities/assessment';
import { AssessmentEvent, AssessmentRemediationEvent, AssessmentStatusEvent } from '../../models/assessment-events';
import { RemediationRequest, RemediationStatus } from '@entities/remediation-request/remediation-request.model';
import { assessmentTimeline } from '../../assessment-status-event-constants';
import { remediationTimelineNodes } from '../../remediation-event-constants';
import _ from 'lodash';
import { AUTOMATION_EMAIL } from '@shared/constants/email.constants';

const TIMELINE_DATE_FORMAT = 'MMM DD, YYYY, h:mm:ss A';

@Component({
    selector: 'app-assessment-status-timeline',
    templateUrl: './assessment-status-timeline.component.html',
    styleUrls: ['./assessment-status-timeline.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentStatusTimelineComponent {
    assessment = input.required<AssessmentView>();
    assessmentEvents: Signal<AssessmentEvent[]>;

    constructor() {
        this.assessmentEvents = computed(() => this.buildTimeline());
    }

    isMoreInformationNeeded(): boolean {
        const [status, submittedDate] = [this.assessment()?.status, this.getReviewStartedDate(this.assessment())];
        return status === AssessmentStatus.COLLECTING_INFORMATION && !!submittedDate;
    }

    private buildTimeline(): AssessmentEvent[] {
        // Deep copy constants; without this we run the risk of updating the constants' values themselves.
        const timeline: AssessmentEvent[] = _.cloneDeep(
            assessmentTimeline[this.assessment()?.status || AssessmentStatus.NOT_ASSESSED],
        );

        if (!this.assessment()) {
            (timeline[0] as AssessmentStatusEvent).assessmentStatus = AssessmentStatus.NOT_ASSESSED;
            timeline[0].subtitle = 'Waiting for client';
            timeline[0].current = true;
        } else if (this.assessment()?.status !== AssessmentStatus.CANCELLED) {
            this.setupStartedNode(timeline[0] as AssessmentStatusEvent);
            this.setupCollectingInformationNode(timeline[1] as AssessmentStatusEvent);
            if (this.assessment()?.status === AssessmentStatus.AUDIT_COMPLETED) {
                this.setupAuditCompletedNode(timeline[2] as AssessmentStatusEvent);
            } else {
                this.setupReviewStartedNode(timeline[2] as AssessmentStatusEvent);
            }
            this.setupCompletedNode(timeline[3] as AssessmentStatusEvent);
            this.setupRemediationNodes(timeline);
        }
        return timeline;
    }

    private setupStartedNode(node: AssessmentStatusEvent): void {
        node.subtitle = node.current
            ? 'Waiting for third party'
            : moment(this.assessment()?.createdDate).format(TIMELINE_DATE_FORMAT);
        node.lowerText.value = this.getSentToEmail(this.assessment());
    }

    private setupCollectingInformationNode(node: AssessmentStatusEvent): void {
        if (this.assessment()?.documentsOnly) {
            node.subtitle = 'Third party not involved';
        } else {
            if (node.current && this.assessment()?.hasFollowupQuestionnaire && this.assessment()?.followupRequested) {
                node.lowerText.label = 'Returned';
                node.lowerText.value = `${this.assessment()?.followupType === FollowupType.MANUAL ? 'Manual' : 'Automatic'} follow-up sent`;
            } else if (this.assessment()?.continuedByEmail) {
                node.subtitle = this.getReviewStartedDate(this.assessment());
                node.lowerText.label = 'Continued with available information';
                node.lowerText.value = this.getContinuedByEmail(this.assessment());
            } else {
                node.subtitle = node.current
                    ? 'Waiting for third party'
                    : this.getCollectingInformationDate(this.assessment());
                node.lowerText.value = this.getSentToEmail(this.assessment());
            }
        }
        if (node.current && this.assessment()?.collectingInformationReason) {
            node.lowerText.label = `Returned by VISO Auditor`;
            node.lowerText.value = CollectingInformationReasonLabels[this.assessment()?.collectingInformationReason];
        }
    }

    private setupReviewStartedNode(node: AssessmentStatusEvent): void {
        if (this.isMoreInformationNeeded()) {
            node.subtitle = 'More information needed';
            node.current = true; // Both collecting info and review started highlighted
        } else {
            node.subtitle = node.current
                ? 'Waiting for VISO TRUST'
                : moment(this.getReviewStartedDate(this.assessment())).format(TIMELINE_DATE_FORMAT);
            node.lowerText.value = this.getSubmittedByEmail(this.assessment());
        }
    }

    private setupAuditCompletedNode(node: AssessmentStatusEvent): void {
        node.lowerText.value = this.getSubmittedByEmail(this.assessment());
    }

    private setupCompletedNode(node: AssessmentStatusEvent): void {
        node.subtitle = !!this.assessment()?.completedDate
            ? moment(this.assessment()?.completedDate).format(TIMELINE_DATE_FORMAT)
            : null;
        node.lowerText.value = this.getAssessmentDuration(this.assessment());
    }

    private setupRemediationNodes(timeline: AssessmentEvent[]): void {
        if (!this.assessment()?.remediationRequest) {
            return;
        }

        const remediationRequest = this.assessment()?.remediationRequest;

        // COMPLETED node as no longer the current, since we're now appending remediation node(s) to the timeline.
        // However, it is done because the client has made a verdict that they wanted to request remediation.
        const assessmentCompleteNode = timeline.find(
            (node) => (node as AssessmentStatusEvent)?.assessmentStatus === AssessmentStatus.COMPLETED,
        );
        assessmentCompleteNode.current = false;
        assessmentCompleteNode.done = true;
        const remediationNodes: AssessmentRemediationEvent[] = _.cloneDeep(
            remediationTimelineNodes[remediationRequest.status],
        );
        this.updateRemediationRequestedNode(remediationRequest, remediationNodes);
        this.updateRemediationCompletedNode(remediationRequest, remediationNodes);
        this.updateRemediationDeclinedNode(remediationRequest, remediationNodes);
        this.updateRemediationExpiredNode(remediationRequest, remediationNodes);
        this.updateRemediationCancelledNode(remediationRequest, remediationNodes);
        this.updateRemediationObsoleteNode(remediationRequest, remediationNodes);
        for (const node of remediationNodes) {
            timeline.push(node);
        }
    }

    private updateRemediationRequestedNode(
        remediationRequest: RemediationRequest,
        remediationNodes: AssessmentRemediationEvent[],
    ): void {
        const requestedByNode = remediationNodes.find((node) => node.remediationStatus === RemediationStatus.REQUESTED);
        const titleSuffix = ` ${moment(remediationRequest.targetDate).format('MMM Do, YYYY')}`;
        if (!!requestedByNode) {
            requestedByNode.title = remediationTimelineNodes.REQUESTED[0].title + titleSuffix;
            requestedByNode.subtitle = moment(remediationRequest.createdDate).format(TIMELINE_DATE_FORMAT);
            requestedByNode.lowerText = {
                label: 'Requested by',
                value: remediationRequest.creatorEmail,
            };
        }
    }

    private updateRemediationCompletedNode(
        remediationRequest: RemediationRequest,
        remediationNodes: AssessmentRemediationEvent[],
    ): void {
        const remediationCompleteNode = remediationNodes.find(
            (node) => node.remediationStatus === RemediationStatus.ARTIFACTS_PROVIDED,
        );
        const titleSuffix = ` ${moment(remediationRequest.targetDate).format('MMM Do, YYYY')}`;
        if (!!remediationCompleteNode) {
            remediationCompleteNode.title = remediationTimelineNodes.ARTIFACTS_PROVIDED[0].title + titleSuffix;
            remediationCompleteNode.subtitle = moment(remediationRequest.updatedDate).format(TIMELINE_DATE_FORMAT);
            remediationCompleteNode.lowerText = {
                label: 'Requested by',
                value: remediationRequest.creatorEmail,
            };
        }
    }

    private updateRemediationDeclinedNode(
        remediationRequest: RemediationRequest,
        remediationNodes: AssessmentRemediationEvent[],
    ): void {
        const remediationDeclinedNode = remediationNodes.find(
            (node) => node.remediationStatus === RemediationStatus.DECLINED,
        );
        if (!!remediationDeclinedNode) {
            remediationDeclinedNode.subtitle = moment(remediationRequest.updatedDate).format(TIMELINE_DATE_FORMAT);
            remediationDeclinedNode.lowerText = {
                label: 'Declined by',
                value: `${remediationRequest.declinedByEmail}`,
            };
        }
    }

    private updateRemediationExpiredNode(
        remediationRequest: RemediationRequest,
        remediationNodes: AssessmentRemediationEvent[],
    ): void {
        const remediationExpiredNode = remediationNodes.find(
            (node) => node.remediationStatus === RemediationStatus.EXPIRED,
        );
        if (!!remediationExpiredNode) {
            remediationExpiredNode.subtitle = moment(remediationRequest.updatedDate).format(TIMELINE_DATE_FORMAT);
        }
    }

    private updateRemediationCancelledNode(
        remediationRequest: RemediationRequest,
        remediationNodes: AssessmentRemediationEvent[],
    ): void {
        const remediationExpiredNode = remediationNodes.find(
            (node) => node.remediationStatus === RemediationStatus.CANCELLED,
        );
        if (!!remediationExpiredNode) {
            remediationExpiredNode.subtitle = moment(remediationRequest.updatedDate).format(TIMELINE_DATE_FORMAT);
            remediationExpiredNode.lowerText = {
                label: 'Cancelled by',
                value: this.getRemediationCancelledByText(remediationRequest.cancelledByEmail),
            };
        }
    }

    private updateRemediationObsoleteNode(
        remediationRequest: RemediationRequest,
        remediationNodes: AssessmentRemediationEvent[],
    ): void {
        const remediationObsoleteNode = remediationNodes.find(
            (node) => node.remediationStatus === RemediationStatus.OBSOLETE,
        );
        if (!!remediationObsoleteNode) {
            remediationObsoleteNode.subtitle = moment(remediationRequest.updatedDate).format(TIMELINE_DATE_FORMAT);
        }
    }

    private getRemediationCancelledByText(cancelledByEmail: string): string {
        return cancelledByEmail === AUTOMATION_EMAIL ? 'Automatic' : cancelledByEmail;
    }

    private getCollectingInformationDate(latestAssessment: AssessmentView): string {
        return moment(
            this.getLatestHistoryDateByStatus(latestAssessment, AssessmentStatus.COLLECTING_INFORMATION),
        ).format(TIMELINE_DATE_FORMAT);
    }

    private getSentToEmail(latestAssessment: AssessmentView): string {
        return latestAssessment?.sentToEmail;
    }

    private getReviewStartedDate(latestAssessment: AssessmentView): string {
        const date = this.getLatestHistoryDateByStatus(latestAssessment, AssessmentStatus.REVIEW_STARTED);
        return !!date ? moment(date).format(TIMELINE_DATE_FORMAT) : null;
    }

    private getSubmittedByEmail(latestAssessment: AssessmentView): string {
        return latestAssessment?.submittedByEmail;
    }

    private getContinuedByEmail(latestAssessment: AssessmentView): string {
        return latestAssessment.continuedByEmail;
    }

    private getAssessmentDuration(latestAssessment: AssessmentView): string {
        const duration = moment.duration(
            moment(latestAssessment.auditCompletedDate).diff(moment(latestAssessment.createdDate)),
        );

        const items = [
            [duration.months(), 'month'],
            [duration.weeks(), 'week'],
            [duration.days(), 'day'],
            [duration.hours(), 'hour'],
            [duration.minutes(), 'minute'],
            [duration.seconds(), 'second'],
        ];

        return items
            .filter(([durationItem]) => !!durationItem)
            .slice(0, 2)
            .map(([durationItem, durationLabel]) => `${durationItem} ${durationLabel}${durationItem !== 1 ? 's' : ''}`)
            .join(', ');
    }

    private getLatestHistoryDateByStatus(latestAssessment: AssessmentView, status: AssessmentStatus): Date {
        return latestAssessment?.statusHistories.find((statusHistory) => statusHistory.status === status)?.date;
    }
}
