<label class="fw-bold" for="followUpTypeSelection">Configure follow-up</label>
<p class="mb-0">
    If there are any unvalidated controls or unanswered questions after we review your assessment, you can follow up
    with the vendor.
</p>
<mat-form-field class="w-100 my-3">
    <mat-select id="followupTypeSelection" [formControl]="followupTypeControl()">
        @for (followupType of followupTypes(); track followupType) {
            <mat-option [value]="followupType">
                {{ FollowupTypeLabels[followupType] }}
            </mat-option>
        }
    </mat-select>
</mat-form-field>
@switch (followupTypeControl().value) {
    @case (FollowupType.MANUAL) {
        <p class="subtext">
            You'll have the option to review the results of the AI assessment and choose to follow up or close the
            assessment. If you choose to send a follow-up questionnaire, the vendor will have 7 days to respond before
            the assessment is marked complete.
        </p>
    }
    @case (FollowupType.AUTOMATIC) {
        <p class="subtext">Automatically follow-up if residual risk is greater than or equal to:</p>
        <mat-radio-group class="my-3 d-flex flex-column" [formControl]="followupRiskThreshold()">
            <mat-radio-button color="primary" [value]="Risk.LOW">
                <div class="fw-bold" [riskTextColor]="Risk.LOW">
                    {{ Risk.LOW | riskLevel | async }}
                </div>
            </mat-radio-button>
            <mat-radio-button color="primary" [value]="Risk.MEDIUM">
                <div class="fw-bold" [riskTextColor]="Risk.MEDIUM">
                    {{ Risk.MEDIUM | riskLevel | async }}
                </div>
            </mat-radio-button>
            <mat-radio-button color="primary" [value]="Risk.HIGH">
                <div class="fw-bold" [riskTextColor]="Risk.HIGH">
                    {{ Risk.HIGH | riskLevel | async }}
                </div>
            </mat-radio-button>
            <mat-radio-button color="primary" [value]="Risk.EXTREME">
                <div class="fw-bold" [riskTextColor]="Risk.EXTREME">
                    {{ Risk.EXTREME | riskLevel | async }}
                </div>
            </mat-radio-button>
        </mat-radio-group>
        <p class="subtext">
            For all other assessments, you’ll have the option to review the results of the AI assessment and choose to
            follow up or close the assessment. If you choose to send a follow-up questionnaire, the vendor will have 7
            days to respond before the assessment is marked complete.
        </p>
    }
    @case (FollowupType.CONCIERGE) {
        <p class="subtext">
            Our audit team will reach out to your vendor to collect documents. Then, we will schedule a call with the
            vendor for a detailed follow-up conversation about their cybersecurity strategy, roadmap, approach, and
            preparedness.
        </p>
        @if (remainingConciergeAssessments() > 0) {
            <small> {{ remainingConciergeAssessments() }} remaining </small>
        } @else if (remainingConciergeAssessments() === 0) {
            <p class="subtext text-danger fw-bold">
                You do not have any more available concierge assessments. Please contact
                <a href="mailto:{{ SALES_EMAIL }}">{{ SALES_EMAIL }}</a>
                for more information.
            </p>
        }
    }
}
