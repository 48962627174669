import { Injectable } from '@angular/core';
import { AssessmentSubmission, AssessmentSubmissionType } from '@entities/assessment';
import { AssessmentSubmissionContext, AssessmentSubmissionFormPayload, AttestationsGroups } from '../models';

interface Inputs {
    formPayload: AssessmentSubmissionFormPayload;
    assessmentToken: string;
    secret: string;
    attestationsGroup?: AttestationsGroups;
    submissionContext: AssessmentSubmissionContext;
}

@Injectable()
export class SubmissionHelperService {
    generateSubmissionPayload({
        formPayload,
        assessmentToken,
        secret,
        attestationsGroup,
        submissionContext,
    }: Inputs): AssessmentSubmission {
        const { email, firstName, lastName } = formPayload;
        const attestations = attestationsGroup?.attestations ?? {};
        const siblingArtifactAttestations = attestationsGroup?.siblingArtifactAttestations ?? {};
        const expiredArtifactAttestations = attestationsGroup?.expiredArtifactAttestations ?? {};
        return {
            assessmentToken,
            secret,
            email,
            firstName,
            lastName,
            attestations,
            siblingArtifactAttestations,
            expiredArtifactAttestations,
            notifyClient: true,
            tosAccepted: true,
            submissionType:
                submissionContext === AssessmentSubmissionContext.FILE_ARTIFACTS
                    ? AssessmentSubmissionType.ARTIFACTS
                    : AssessmentSubmissionType.QUESTIONNAIRE,
        };
    }
}
