export const STATIC_SITE_URL = 'https://visotrust.com/';
export const TALK_TO_SALES_URL =
    'https://visotrust.com/company/contact/?utm_source=product&utm_medium=cta&utm_campaign=talk_to_sales';
export const VENDOR_AGREEMENT_URL = 'https://visotrust.com/vendor-agreement/';
export const IMPORT_QUESTIONNAIRE_TEMPLATE_URL: string =
    'https://static.visotrust.com/csv/questionnaire_import_template.csv';
export const VISO_LOGO_URL = 'https://static.visotrust.com/images/viso_logo_full.png';
export const SHARE_FEEDBACK_URL =
    'https://docs.google.com/forms/d/e/1FAIpQLSe9PP-Ijv1m3Ihyhe1J_ypv03kXZE3Zm6OluwU2eMnaD745og/viewform';
export const HELP_DOCS_URL = 'https://support.visotrust.com/';
