import { AfterViewInit, Directive, ElementRef, HostListener, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, fromEvent, merge } from 'rxjs';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';
import { MatTooltip } from '@angular/material/tooltip';

@Directive({
    selector: '[appMiddleTruncate]',
    providers: [MatTooltip],
})
export class MiddleTruncateDirective implements OnInit, AfterViewInit, OnDestroy {
    @Input()
    truncateShowTooltip = false;

    private _innerText: string;
    private _textIsTruncated: boolean;
    private _observer!: IntersectionObserver;
    private _isVisible$ = new BehaviorSubject<boolean>(false);
    private _unsub$ = new Subject<void>();

    get el(): HTMLElement {
        return this._elementRef.nativeElement;
    }

    get scrollWidth(): number {
        return this.el.scrollWidth;
    }

    get offsetWidth(): number {
        return this.el.offsetWidth;
    }

    @HostListener('mouseover') mouseover() {
        if (this.truncateShowTooltip && this._textIsTruncated) {
            this._tooltip.show();
        }
    }

    @HostListener('mouseleave') mouseleave() {
        if (this.truncateShowTooltip) {
            this._tooltip.hide();
        }
    }

    constructor(
        private _tooltip: MatTooltip,
        private _elementRef: ElementRef<HTMLElement>,
        private _ngZone: NgZone,
    ) {}

    ngOnInit(): void {
        this._ngZone.runOutsideAngular(() => {
            this._observer = new IntersectionObserver((entries) => {
                entries.forEach((e) => this._isVisible$.next(e.isIntersecting));
            });
            this._observer.observe(this.el);
        });
    }

    ngAfterViewInit(): void {
        this._innerText = (this.el.textContent ?? '').trim();
        this._tooltip.message = this._innerText;
        merge(this._isVisible$.pipe(filter((visible) => visible)), fromEvent(window, 'resize').pipe(debounceTime(500)))
            .pipe(
                // Reset text content on resize, which allows the text to grow again if the window is expanded.
                tap(() => (this.el.textContent = this._innerText)),
                takeUntil(this._unsub$),
            )
            .subscribe(() => this.truncate());
    }

    ngOnDestroy(): void {
        this._unsub$.next();
        this._observer.disconnect();
    }

    private truncate() {
        const wholeWordArray = this._innerText.split('');
        const half = Math.ceil(wholeWordArray.length / 2);

        const leftPartArray = wholeWordArray.slice(0, half);
        const rightPartArray = wholeWordArray.slice(half);

        while (wholeWordArray.length > 0 && this.scrollWidth > this.offsetWidth) {
            leftPartArray.pop();
            rightPartArray.shift();
            this.el.innerText = `${leftPartArray.join('')}...${rightPartArray.join('')}`;
        }

        this._textIsTruncated = this.el.innerText.length < this._innerText.length;
    }
}
