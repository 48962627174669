<div class="row">
    <div class="col-sm-6 col-lg-3 order-lg-1 border-end mt-4">
        <div class="row mb-4">
            <div class="col">
                <app-relationship-details
                    [relationship]="relationship"
                    [export]="export"
                    [businessOwner]="businessOwner"
                ></app-relationship-details>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col">
                <app-third-party-contact
                    [relationshipId]="relationshipId"
                    [relationship]="relationship"
                    [primaryVendorContact]="primaryVendorContact"
                    [businessOwner]="businessOwner"
                ></app-third-party-contact>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <app-lifecycle-management
                    *disableOnTrial="true; popoverTemplate: recertificationPopover"
                    [relationshipId]="relationship.id"
                    [isOnboarded]="relationship.status === RequestStatus.ONBOARDED"
                    [dateOfNextArtifactExpiration]="dateOfNextArtifactExpiration"
                    [artifactUpdateType]="relationship.artifactUpdateType"
                    [recertificationType]="relationship.recertificationType"
                    [reviewFrequency]="relationship.reviewFrequency"
                    [recertificationDate]="relationship.recertificationDate"
                    [businessOwner]="businessOwner"
                />
                <ng-template #recertificationPopover>
                    <div class="header">
                        <strong>Lifecycle Management is a premium feature.</strong>
                        <em class="icon fa fa-calendar-check text-primary"></em>
                    </div>
                    <div class="body">
                        Lifecycle Management enables you to automatically reassess third parties and stay up to date on
                        how you're doing business with them. Our paid license comes with unlimited assessments.
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
    <div class="col-sm-6 col-lg-3 order-lg-3 border-start mt-4">
        <div class="row mb-4">
            <div class="col">
                <app-business-owner
                    [relationshipId]="relationshipId"
                    [businessOwner]="businessOwner"
                ></app-business-owner>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col">
                <app-subscribers
                    [relationshipId]="relationshipId"
                    [subscribers]="subscribers"
                    [export]="export"
                    (unsubscribeSubscriber)="unsubscribeSubscriber.emit($event)"
                ></app-subscribers>
            </div>
        </div>
        <div *jhiHasNotAuthority="[Roles.Auditor]" class="row">
            <div class="col">
                <app-tags
                    [tags]="tags"
                    [canDeleteTags]="!(currentUser | callback: isAuditorOrReadOnly)"
                    [businessOwner]="businessOwner"
                    (unlinkTag)="unlinkTag.emit($event)"
                    (linkTag)="linkTag.emit($event)"
                ></app-tags>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-lg-6 order-lg-2 mt-4">
        <div class="row mb-3">
            <div class="col">
                <h3 class="lead mb-0">Relationship context</h3>
            </div>
            <div class="col-auto">
                <ng-container *hideIfOrgUserIsNotBusinessOwner="businessOwner">
                    <a
                        *jhiHasNotAuthority="[Roles.Auditor, Roles.Admin, Roles.Support, Roles.ReadOnly]"
                        exportRemovableAction
                        class="text-primary"
                        [routerLink]="addContextLink"
                        [replaceUrl]="true"
                        data-pw="rdpAddContextLink"
                    >
                        {{ hasNoContext ? 'Add' : 'Edit' }}
                    </a>
                </ng-container>
            </div>
            <div class="col-12 mt-2">
                <p class="text-muted">These selections determine the threat surface and security controls in scope.</p>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <app-business-case [contextTypes]="contextTypes"></app-business-case>
            </div>
            <div class="col">
                <app-data-types [dataTypesConfig]="dataTypesConfig"></app-data-types>
            </div>
        </div>
        <div class="row mt-3" *ngIf="hasSupplementalQuestionnairesEnabled">
            <div class="col">
                <app-supplemental-questionnaires
                    [relationshipId]="relationshipId"
                    [businessOwner]="businessOwner"
                    [combinedSupplementalQuestionnaireConfig]="combinedSupplementalQuestionnaireConfig"
                />
            </div>
        </div>
    </div>
</div>
