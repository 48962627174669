<div class="row justify-content-center">
    <div class="col">
        <div class="row mb-4">
            <div class="col">
                <h2 class="assessment-collection-step-title">
                    {{ title }}
                </h2>
            </div>
        </div>

        <div class="row mb-2">
            <div class="col" *ngIf="followupRequested; else defaultWelcomeScreen">
                <p>
                    <strong>{{ clientName }}</strong> is using VISO TRUST to manage third-party risk, and our expert
                    auditors need some more information.
                </p>
                <p>
                    <strong>
                        We've already used AI to analyze your documents. Please answer the following questions to help
                        complete the assessment.
                    </strong>
                </p>
            </div>
            <ng-template #defaultWelcomeScreen>
                <div class="col">
                    @switch (collectionType) {
                        @case (CollectionTypes.RECERTIFICATION) {
                            <p>
                                <strong>{{ clientName }}</strong> is using VISO TRUST to manage third-party risk and
                                it's time to re-certify your program.
                            </p>
                        }
                        @case (CollectionTypes.ARTIFACT_UPDATE) {
                            <p>
                                <strong>{{ clientName }}</strong> is using VISO TRUST to manage third-party risk and
                                it's time to update some of your artifacts.
                            </p>
                        }
                        @default {
                            <p>
                                @if (isRemediationAssessment) {
                                    <strong>{{ clientName }}</strong> is using VISO TRUST to manage third-party risk and
                                    is requesting remediation by <strong>{{ remediationTargetDate | date }}</strong
                                    >.
                                } @else {
                                    <strong>{{ clientName }}</strong> is using VISO TRUST to manage third-party risk and
                                    <strong *ngIf="!sentByAutomation">{{ senderEmail }}</strong> wants to assess your
                                    security posture.
                                }
                            </p>
                        }
                    }
                </div>
            </ng-template>
        </div>

        <div class="row mb-4" *ngIf="!followupRequested">
            <div class="col">
                <p>
                    <strong
                        >Instead of questionnaires, we'll use your security artifacts to evaluate your program.</strong
                    >
                </p>

                <div class="row">
                    <div class="col">
                        <div class="d-flex flex-nowrap">
                            <ng-container
                                *ngIf="collectionType === CollectionTypes.ARTIFACT_UPDATE; else defaultFirstStep"
                            >
                                <div class="me-3">
                                    <mat-icon class="text-muted" fontSet="material-symbols-outlined">
                                        autorenew
                                    </mat-icon>
                                </div>
                                <p class="m-0 pt-1">
                                    <strong>Replace all expired artifacts, or give us new ones</strong><br />
                                    Keeping your artifacts up to date helps maintain an accurate risk analysis for this
                                    partnership.
                                </p>
                            </ng-container>
                            <ng-template #defaultFirstStep>
                                <div class="me-3">
                                    <mat-icon class="text-muted" fontSet="material-symbols-outlined">
                                        document_scanner
                                    </mat-icon>
                                </div>
                                <p class="m-0 pt-1">
                                    <strong>First, upload all of your artifacts</strong><br />
                                    The more information you provide, the better. Providing evidence from artifacts
                                    helps you avoid manually answering questions later.
                                </p>
                            </ng-template>
                        </div>
                    </div>
                </div>

                <div class="row my-2">
                    <div class="col">
                        <div class="d-flex flex-nowrap">
                            <div class="me-3">
                                <mat-icon class="text-muted" fontSet="material-symbols-outlined">
                                    find_in_page
                                </mat-icon>
                            </div>
                            <p class="m-0 pt-1">
                                <strong>Next, answer a few clarifying questions</strong><br />
                                If we think you're missing anything, we'll ask you to clarify.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <div class="d-flex flex-nowrap">
                            <div class="me-3">
                                <mat-icon class="text-muted" fontSet="material-symbols-outlined">
                                    celebration
                                </mat-icon>
                            </div>
                            <p class="m-0 pt-1">
                                <strong>Done</strong><br />
                                Our team of expert auditors will reach out to you with any questions.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mb-4">
            <div class="col text-center">
                <div>
                    <button mat-flat-button color="secondary" (click)="beginCollection.emit()" data-pw="continueButton">
                        Get started
                    </button>
                </div>
                @if (isExpirationExtensionAllowed) {
                    <div class="mt-3">
                        <button mat-stroked-button color="secondary" (click)="extendExpiration.emit()">
                            Need more time?
                        </button>
                    </div>
                }
                @if (showDeclineRemediation) {
                    <div class="mt-3">
                        <button mat-button color="warn" (click)="declineRemediation.emit()">Decline remediation</button>
                    </div>
                }
            </div>
        </div>

        <div class="row px-3 mb-4">
            <div class="col">
                <mat-divider class="px-5" />
            </div>
        </div>

        <div class="row">
            <div class="col bottom-links">
                <p *ngIf="!followupRequested">
                    Don't want to provide artifacts?
                    <span
                        role="button"
                        aria-label="Go to questionnaire"
                        class="mat-text-secondary text-bold"
                        (click)="navigateToQuestionnaire.emit()"
                    >
                        Answer a questionnaire instead.</span
                    >
                </p>

                <p>
                    Not the right person to complete this assessment?
                    <span
                        role="button"
                        aria-label="Forward assessment to a different email"
                        class="mat-text-secondary text-bold"
                        (click)="forwardRequest.emit()"
                    >
                        Forward the request.</span
                    >
                </p>
                <p>
                    No longer doing business with {{ clientName }}?
                    <span
                        role="button"
                        aria-label="Opt out of the assessment"
                        class="mat-text-secondary text-bold"
                        (click)="optOut.emit()"
                        >Opt out.</span
                    >
                </p>
                <p class="d-block text-muted">
                    Information submitted is subject to the terms and conditions of VISO TRUST's
                    <a [externalLink]="VENDOR_AGREEMENT_URL" class="mat-text-secondary" target="_blank"
                        ><strong>Vendor Agreement</strong></a
                    >.
                </p>
            </div>
        </div>
    </div>
</div>
