import {
    ArtifactRtpValidationStatus,
    ArtifactValidation,
    ArtifactValidationStatus,
    FileArtifact,
    FileArtifactType,
} from '@entities/artifact';
import { AUDIT_TYPES_TO_FILE_ARTIFACT_TYPES, AuditReportTypeCode } from '@entities/audit-report';
import {
    isRtpArtifactErrored,
    isRTPArtifactEvent,
    isRTPPageEvent,
    MLErrorType,
    RTPEvent,
    RTPEventType,
} from '@entities/rtp';
import { isFileTabular } from '@shared/utils/file-utils.service';

export class PublicAssessmentRTPFileArtifact {
    validation: ArtifactValidation;
    rtpStatus: ArtifactRtpValidationStatus;
    error: MLErrorType;

    constructor(
        public artifact: FileArtifact,
        public rtpEventsMap: Map<RTPEventType, RTPEvent>,
        public justUploaded: boolean,
    ) {
        const rtpArtifactEventsArray = [...this.rtpEventsMap.values()].filter(isRTPArtifactEvent);
        const latestValidationContainingEvent = rtpArtifactEventsArray[rtpArtifactEventsArray.length - 1];

        this.artifact.avStatus = latestValidationContainingEvent?.artifact?.avStatus || this.artifact.avStatus;

        if (!this.artifact.artifactType) {
            this.artifact.artifactType =
                AUDIT_TYPES_TO_FILE_ARTIFACT_TYPES[
                    latestValidationContainingEvent?.artifact?.validation?.auditReportType
                ];
        }

        const latestValidation = latestValidationContainingEvent?.artifact?.validation;
        this.validation = latestValidation ?? this.artifact.validation;

        const rtpPageEventsArray = [...this.rtpEventsMap.values()].filter(isRTPPageEvent);
        const latestPageEvent = rtpPageEventsArray[rtpPageEventsArray.length - 1];

        this.rtpStatus = latestPageEvent?.rtpStatus || this.validation?.rtpStatus;
        if (
            !this.rtpStatus &&
            !isFileTabular(artifact.fileName) &&
            (this.validation?.status === ArtifactValidationStatus.IN_PROGRESS ||
                this.validation?.status === ArtifactValidationStatus.NOT_VALIDATED ||
                !this.validation?.status)
        ) {
            this.rtpStatus = ArtifactRtpValidationStatus.ANALYZING;
        }

        const errorEvents = [...this.rtpEventsMap.values()].filter(isRtpArtifactErrored);
        const latestErrorEvent = errorEvents[errorEvents.length - 1];
        this.error = latestErrorEvent?.errorType || this.validation?.mlErrorType;
        if (!!this.error) {
            this.rtpStatus = ArtifactRtpValidationStatus.ERRORED;
        }
    }

    get hasClassification(): boolean {
        return (
            (!!this.auditReportTypeCode && this.auditReportTypeCode !== AuditReportTypeCode.NONE) ||
            isFileTabular(this.artifact.fileName)
        );
    }

    get rtpUnableToClassify(): boolean {
        return (
            (this.hasFatalError || this.isAnalyzed) &&
            (!this.auditReportTypeCode || this.auditReportTypeCode === AuditReportTypeCode.NONE)
        );
    }

    get needsPassword(): boolean {
        const errored = this.rtpStatus === ArtifactRtpValidationStatus.ERRORED;
        const isUnableToParseError = this.error === MLErrorType.INCORRECT_PASSWORD;
        return errored && isUnableToParseError;
    }

    get isAnalyzed(): boolean {
        return this.rtpStatus === ArtifactRtpValidationStatus.ANALYZED;
    }

    get hasFatalError(): boolean {
        return this.rtpStatus === ArtifactRtpValidationStatus.ERRORED && this.error !== MLErrorType.INCORRECT_PASSWORD;
    }

    get canExpire(): boolean {
        return !!this.validation?.auditReportCanExpire;
    }

    get hasExpirationDate(): boolean {
        return !!this.validation?.expirationDate;
    }

    get requiredRTPValidationsReceived(): boolean {
        // We can consider the artifact sufficiently classified when it has both its
        // audit type and expiration date set (if the artifact can expire).
        return this.hasClassification && (!this.canExpire || this.hasExpirationDate);
    }

    get waitingOnRequiredRTPValidations(): boolean {
        // Either RTP is finished (errored or otherwise) or we got
        // the validations we care about - whichever comes first.
        return (
            !this.hasFatalError && !this.rtpUnableToClassify && !this.isAnalyzed && !this.requiredRTPValidationsReceived
        );
    }

    get auditReportTypeCode(): AuditReportTypeCode {
        return this.validation?.auditReportType;
    }

    get vendorProvidedAuditTypeCode(): AuditReportTypeCode {
        return this.artifact.vendorAuditReport?.auditType;
    }

    get fileArtifactType(): FileArtifactType {
        return !!this.validation?.auditReportType
            ? AUDIT_TYPES_TO_FILE_ARTIFACT_TYPES[this.validation.auditReportType]
            : this.artifact.artifactType;
    }

    get fileArtifactTypeOverridden(): boolean {
        return (
            !!this.auditReportTypeCode &&
            this.auditReportTypeCode !== AuditReportTypeCode.NONE &&
            this.artifact.artifactType !== AUDIT_TYPES_TO_FILE_ARTIFACT_TYPES[this.validation.auditReportType]
        );
    }

    get auditReportTypeOverridden(): boolean {
        return (
            !!this.vendorProvidedAuditTypeCode &&
            this.auditReportTypeCode !== AuditReportTypeCode.NONE &&
            this.artifact.vendorAuditReport?.auditType !== this.validation.auditReportType
        );
    }

    get allowsRecommendations(): boolean {
        // If the artifact was overridden in any way, it's because the vendor disagreed with the classification by RTP.
        // So treat the output from RTP as unreliable and rely on auditors for this artifact.
        // Ignore the above if the validation is already complete (copied).
        return (
            this.validation?.status === ArtifactValidationStatus.COMPLETE ||
            (!this.fileArtifactTypeOverridden && !this.auditReportTypeOverridden)
        );
    }
}
