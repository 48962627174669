import { createAction, props } from '@ngrx/store';
import { SupplementalQuestionnaireConfig } from '@entities/org';
import {
    RelationshipSupplementalQuestionnaireConfigResponse,
    UpdateRelationshipSupplementalQuestionnaireConfigRequest,
} from '@entities/relationship';

export enum SupplementalQuestionnaireActions {
    GetOrgSupplementalQuestionnaireConfigRequest = '[Request] Get Org Supplemental Questionnaire Config Request',
    GetOrgSupplementalQuestionnaireConfigRequestSuccess = '[Request] Get Org Supplemental Questionnaire Config Request Success',
    GetOrgSupplementalQuestionnaireConfigRequestFailed = '[Request] Get Org Supplemental Questionnaire Config Request Failed',

    GetRelationshipSupplementalQuestionnaireConfigRequest = '[Request] Get Relationship Supplemental Questionnaire Config Request',
    GetRelationshipSupplementalQuestionnaireConfigRequestSuccess = '[Request] Get Relationship Supplemental Questionnaire Config Request Success',
    GetRelationshipSupplementalQuestionnaireConfigRequestFailed = '[Request] Get Relationship Supplemental Questionnaire Config Request Failed',

    DeleteRelationshipSupplementalQuestionnaireConfigRequest = '[Request] Delete Relationship Supplemental Questionnaire Config Request',
    DeleteRelationshipSupplementalQuestionnaireConfigRequestSuccess = '[Request] Delete Relationship Supplemental Questionnaire Config Request Success',
    DeleteRelationshipSupplementalQuestionnaireConfigRequestFailed = '[Request] Delete Relationship Supplemental Questionnaire Config Request Failed',

    UpdateRelationshipSupplementalQuestionnaireConfigRequest = '[Request] Update Relationship Supplemental Questionnaire Config Request',
    UpdateRelationshipSupplementalQuestionnaireConfigRequestSuccess = '[Request] Update Relationship Supplemental Questionnaire Config Request Success',
    UpdateRelationshipSupplementalQuestionnaireConfigRequestFailed = '[Request] Update Relationship Supplemental Questionnaire Config Request Failed',

    DownloadRelationshipSupplementalQuestionnaireRequest = '[Request] Download Relationship Supplemental Questionnaire Request',
    DownloadRelationshipSupplementalQuestionnaireRequestFailed = '[Request] Download Relationship Supplemental Questionnaire Request Failed',
}

export const getOrgSupplementalQuestionnaireConfigRequest = createAction(
    SupplementalQuestionnaireActions.GetOrgSupplementalQuestionnaireConfigRequest,
    props<{ clientId: number }>(),
);

export const getOrgSupplementalQuestionnaireConfigRequestSuccess = createAction(
    SupplementalQuestionnaireActions.GetOrgSupplementalQuestionnaireConfigRequestSuccess,
    props<{ orgSupplementalQuestionnaireConfig: SupplementalQuestionnaireConfig[] }>(),
);

export const getOrgSupplementalQuestionnaireConfigRequestFailed = createAction(
    SupplementalQuestionnaireActions.GetOrgSupplementalQuestionnaireConfigRequestFailed,
);

export const getRelationshipSupplementalQuestionnaireConfigRequest = createAction(
    SupplementalQuestionnaireActions.GetRelationshipSupplementalQuestionnaireConfigRequest,
    props<{ relationshipId: number }>(),
);

export const getRelationshipSupplementalQuestionnaireConfigRequestSuccess = createAction(
    SupplementalQuestionnaireActions.GetRelationshipSupplementalQuestionnaireConfigRequestSuccess,
    props<{
        relationshipSupplementalQuestionnaireConfigResponse: RelationshipSupplementalQuestionnaireConfigResponse;
    }>(),
);

export const getRelationshipSupplementalQuestionnaireConfigRequestFailed = createAction(
    SupplementalQuestionnaireActions.GetRelationshipSupplementalQuestionnaireConfigRequestFailed,
);

export const deleteRelationshipSupplementalQuestionnaireConfigRequest = createAction(
    SupplementalQuestionnaireActions.DeleteRelationshipSupplementalQuestionnaireConfigRequest,
    props<{ relationshipId: number }>(),
);

export const deleteRelationshipSupplementalQuestionnaireConfigRequestSuccess = createAction(
    SupplementalQuestionnaireActions.DeleteRelationshipSupplementalQuestionnaireConfigRequestSuccess,
);

export const deleteRelationshipSupplementalQuestionnaireConfigRequestFailed = createAction(
    SupplementalQuestionnaireActions.DeleteRelationshipSupplementalQuestionnaireConfigRequestFailed,
);

export const updateRelationshipSupplementalQuestionnaireConfigRequest = createAction(
    SupplementalQuestionnaireActions.UpdateRelationshipSupplementalQuestionnaireConfigRequest,
    props<{ relationshipId: number; updateRequest: UpdateRelationshipSupplementalQuestionnaireConfigRequest }>(),
);

export const updateRelationshipSupplementalQuestionnaireConfigRequestSuccess = createAction(
    SupplementalQuestionnaireActions.UpdateRelationshipSupplementalQuestionnaireConfigRequestSuccess,
);

export const updateRelationshipSupplementalQuestionnaireConfigRequestFailed = createAction(
    SupplementalQuestionnaireActions.UpdateRelationshipSupplementalQuestionnaireConfigRequestFailed,
);

export const downloadRelationshipSupplementalQuestionnaireRequest = createAction(
    SupplementalQuestionnaireActions.DownloadRelationshipSupplementalQuestionnaireRequest,
    props<{ relationshipId: number; suppQId: string }>(),
);

export const downloadRelationshipSupplementalQuestionnaireRequestFailed = createAction(
    SupplementalQuestionnaireActions.DownloadRelationshipSupplementalQuestionnaireRequestFailed,
);
