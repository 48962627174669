export enum MainTabs {
    RELATIONSHIP = 'tab-request-relationship',
    DIRECTORY_RECORD = 'tab-request-directory-record',
    ASSESSMENT = 'tab-request-assessment',
    ARTIFACTS_AND_RISK_ANALYSIS = 'tab-request-artifacts-risk-analysis',
    AI_QA = 'tab-request-ai-qa',
    RISK_TIMELINE = 'tab-request-risk-timeline',
    ATTACHMENTS = 'tab-request-attachments',
    DETAILS = 'tab-request-details',
    COMMENTS = 'tab-request-comments',
}

export enum RiskAnalysisTabs {
    SECURITY = 'tab-request-riskAnalysis-security',
    PRIVACY = 'tab-request-riskAnalysis-privacy',
    RESILIENCE = 'tab-request-riskAnalysis-resilience',
    AI_TRUST = 'tab-request-riskAnalysis-ai-trust',
    CYBER_INSURANCE = 'tab-request-riskAnalysis-cyber-insurance',
    PRODUCT_SECURITY = 'tab-request-riskAnalysis-product-security',
    SERVICE_LOCATIONS = 'tab-request-riskAnalysis-service-locations',
    SUPPLEMENTAL_QUESTIONNAIRES = 'tab-request-riskAnalysis-supplemental-questionnaires',
}
