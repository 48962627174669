import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { NgxSelectModule } from 'ngx-select-ex';

import { PagesModule } from './pages/pages.module';

import { routes } from './routes';

import { RelationshipsModule } from './relationships/relationships.module';
import { ControlsModule } from './controls/controls.module';
import { DatatypesModule } from './data-types/datatypes.module';
import { ContactManagementModule } from './contact-management/contact-management.module';
import { NgChartsModule } from 'ng2-charts';
import { UserProfileModule } from './user-profile/user-profile.module';
import { BusinessUnitManagementModule } from './business-unit-management/business-unit-management.module';
import { YourOrganizationModule } from './your-organization/your-organization.module';
import { RequestModule } from './request/request.module';
import { BulkImportModule } from './bulk-import/bulk-import.module';
import { SessionModule } from './session/session.module';
import { ContextTypesModule } from './context-types/context-types.module';
import { environment } from '@environments/environment';
import { BusinessUnitModule } from './business-unit/business-unit.module';
import { SubscriberModule } from './contact/contact.module';
import { UserRegistrationModule } from './user-registration/user-registration.module';
import { TagModule } from './tag/tag.module';
import { AnnouncementModule } from './announcement/announcement.module';
import { QuestionnaireModule } from './questionnaire/questionnaire.module';
import { IntegrationsModule } from './integrations/integrations.module';
import { AttestationsModule } from './attestations/attestations.module';
import { AssessmentSummaryManagementModule } from './assessment-summary-management/assessment-summary-management.module';
import { RiskModelSettingsModule } from './risk-model-settings/risk-model-settings.module';

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forRoot(routes, {
            enableTracing: environment.debugInfoEnabled,
        }),
        SessionModule,
        PagesModule,
        NgxSelectModule,
        UserProfileModule,
        QuestionnaireModule,
        AssessmentSummaryManagementModule,
        RelationshipsModule,
        NgChartsModule,
        ControlsModule,
        DatatypesModule,
        ContactManagementModule,
        BusinessUnitManagementModule,
        YourOrganizationModule,
        BulkImportModule,
        RequestModule,
        ContextTypesModule,
        BusinessUnitModule,
        SubscriberModule,
        UserRegistrationModule,
        TagModule,
        AnnouncementModule,
        IntegrationsModule,
        AttestationsModule,
        RiskModelSettingsModule,
    ],
    providers: [],
    exports: [RouterModule],
})
export class RoutesModule {}
