<div *dynamicContent="breadcrumbsContainerToken">
    <ol class="breadcrumb breadcrumb px-0 pb-0">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
        <li class="breadcrumb-item active">Your Organization</li>
    </ol>
</div>
<div class="content-heading">
    <div>Your Organization</div>
</div>
<div class="row" *ngIf="org">
    <div class="col">
        <tabset #orgTabset class="viso-tabset">
            <tab #profileTab heading="Profile">
                <div class="row pt-3">
                    <section class="col-md-auto public-profile-section">
                        <app-card>
                            <app-card-toolbar>
                                <div app-card-toolbar-item><strong>Public Profile</strong></div>
                                <app-card-toolbar-section [alignEnd]="true">
                                    <a
                                        class="text-primary"
                                        queryParamsHandling="merge"
                                        *jhiHasAnyAuthority="Roles.OrgAdmin"
                                        [routerLink]="['/', { outlets: { popup: 'your-organization/edit' } }]"
                                        [replaceUrl]="true"
                                    >
                                        Edit
                                    </a>
                                </app-card-toolbar-section>
                            </app-card-toolbar>
                            <app-card-content>
                                <div class="row">
                                    <div class="col-12 mb-2">
                                        <strong>Name</strong>
                                        <p>{{ !!org.name ? org.name : '-' }}</p>
                                    </div>
                                    <div class="col-12 mb-2">
                                        <strong>Legal Name</strong>
                                        <p>{{ !!org.legalName ? org.legalName : '-' }}</p>
                                    </div>
                                    <div class="col-12 mb-2">
                                        <strong>Homepage</strong>
                                        <a
                                            *ngIf="!!org.homepage"
                                            class="d-block mb-2 viso-link"
                                            [externalLink]="org.homepage"
                                            target="_blank"
                                            >{{ org.homepage }}</a
                                        >
                                        <p *ngIf="!org.homepage">-</p>
                                    </div>
                                    <div class="col-12 mb-2">
                                        <strong>Description</strong>
                                        <p>{{ !!org.description ? org.description : '-' }}</p>
                                    </div>
                                    <div class="col-12 mb-2">
                                        <strong>Industry</strong>
                                        <p>{{ !!org.industry ? org.industry : '-' }}</p>
                                    </div>
                                    <div class="col-12 mb-2">
                                        <strong>Company Size</strong>
                                        <p [class.text-muted]="org.orgSize === OrgSize.NONE">
                                            {{ OrgSizeLabels[org.orgSize] }}
                                        </p>
                                    </div>
                                    <div class="col-12 mb-2">
                                        <strong>Headquarters Location</strong>
                                        <p>{{ headquartersLocation$ | async }}</p>
                                    </div>
                                    <div class="col-12 mb-2">
                                        <strong>Privacy Policy</strong>
                                        <a
                                            *ngIf="!!org.privacyPolicyUrl"
                                            class="d-block mb-2"
                                            [externalLink]="org.privacyPolicyUrl"
                                            target="_blank"
                                            >{{ org.privacyPolicyUrl }}</a
                                        >
                                        <p class="text-muted" *ngIf="!org.privacyPolicyUrl">No URL added</p>
                                    </div>
                                    <div class="col-12 mb-2">
                                        <strong>Domains</strong>
                                        <div *ngFor="let domain of org.domains">
                                            {{ domain }}
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <strong>Support Email</strong>
                                        <p>{{ !!org.emailAddress ? org.emailAddress : '-' }}</p>
                                    </div>
                                </div>
                            </app-card-content>
                        </app-card>
                    </section>
                    <section class="col-md-6 company-logos-section">
                        <app-your-organization-logos [org]="org"></app-your-organization-logos>
                    </section>
                </div>
            </tab>

            <ng-container
                *ngIf="{
                    isCurrentUserOrgAdmin: isCurrentUserOrgAdmin$ | async,
                    selectedTab: tabContentTrigger$ | async,
                    hasDomainsBrandingEnabled: hasDomainsBrandingEnabled$ | async,
                } as ctx"
            >
                <tab #brandingTab [disabled]="!ctx.isCurrentUserOrgAdmin || !ctx.hasDomainsBrandingEnabled">
                    <ng-template tabHeading
                        ><span
                            [ngbTooltip]="disabledBrandingTooltip"
                            [disableTooltip]="ctx.isCurrentUserOrgAdmin && ctx.hasDomainsBrandingEnabled"
                        >
                            Branding
                        </span>
                    </ng-template>

                    <app-branding
                        [org]="org"
                        [isTabSelected]="ctx.selectedTab == YourOrganizationTabsOrder.BRANDING"
                    ></app-branding>
                </tab>

                <ng-template #disabledBrandingTooltip>
                    <ng-container *ngIf="ctx.hasDomainsBrandingEnabled; else brandingDisabled">
                        Branding restricted to org administrators.
                    </ng-container>
                    <ng-template #brandingDisabled>
                        Contact <strong>support&#64;visotrust.com</strong> to enable branding.
                    </ng-template>
                </ng-template>

                <tab #manageDomainsTab [disabled]="!ctx.isCurrentUserOrgAdmin || !ctx.hasDomainsBrandingEnabled">
                    <ng-template tabHeading
                        ><span
                            [ngbTooltip]="disabledManageDomainsTooltip"
                            [disableTooltip]="ctx.isCurrentUserOrgAdmin && ctx.hasDomainsBrandingEnabled"
                        >
                            Manage Domains
                        </span>
                    </ng-template>

                    <ng-template #disabledManageDomainsTooltip>
                        <ng-container *ngIf="ctx.hasDomainsBrandingEnabled; else mdDisabled">
                            Domain management restricted to org administrators.
                        </ng-container>
                        <ng-template #mdDisabled>
                            Contact <strong>support&#64;visotrust.com</strong> to enable domain management.
                        </ng-template>
                    </ng-template>

                    <div class="row pt-3">
                        <div class="col">
                            <app-manage-domains
                                [isTabSelected]="ctx.selectedTab === YourOrganizationTabsOrder.MANAGE_DOMAINS"
                            ></app-manage-domains>
                        </div>
                    </div>
                </tab>
            </ng-container>
        </tabset>
    </div>
</div>
