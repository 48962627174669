import { Component, OnDestroy, OnInit } from '@angular/core';
import { Org } from '@entities/org';
import { ClientProfileService } from './client-profile.service';
import { catchError, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { FormControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
    DataTypeConfig,
    OrgControlDomainConfig,
    OrgDataSensitivityConfig,
    OrgRiskConfig,
} from './client-profile.model';
import { VendorSearchResult } from '@shared/vendor-components/models/vendor-search-result';

@Component({
    selector: 'app-client-profile',
    templateUrl: './client-profile.component.html',
    styleUrls: ['./client-profile.component.scss'],
})
export class ClientProfileComponent implements OnInit, OnDestroy {
    clientProfileId: string;
    riskLevelsConfig: OrgRiskConfig[];
    dataSensitivityLevels: OrgDataSensitivityConfig[];
    dataTypesConfig: DataTypeConfig[];
    controlsConfig: OrgControlDomainConfig[];
    loadingProfile: boolean = false;
    clientProfileForm: UntypedFormGroup;

    private _refreshClientProfileTrigger$ = new BehaviorSubject<void>(null);
    private _unsub: Subject<void> = new Subject<void>();

    constructor(
        private _clientProfileService: ClientProfileService,
        private _fb: UntypedFormBuilder,
    ) {}

    get getOrgIdValue(): number {
        return this.clientProfileForm.controls.vendorRef.value?.id;
    }

    get orgName(): string {
        return this.clientProfileForm.controls.vendorRef.value?.name;
    }

    ngOnInit(): void {
        this.clientProfileForm = this._fb.group({
            vendorRef: new FormControl<VendorSearchResult>({} as VendorSearchResult),
        });

        this.clientProfileForm.controls.vendorRef.valueChanges
            .pipe(
                mergeMap((value) => this._refreshClientProfileTrigger$.pipe(map(() => value?.id))),
                tap(() => (this.loadingProfile = true)),
                tap((value?: number) => {
                    if (!!value) {
                        this._clientProfileService
                            .getForOrg(value)
                            .pipe(
                                catchError(() => of(null)),
                                tap(() => (this.loadingProfile = false)),
                            )
                            .subscribe((profile) => {
                                this.clientProfileId = profile?.id;
                                this.riskLevelsConfig = profile?.riskLevels;
                                this.dataSensitivityLevels = profile?.dataSensitivityLevels;
                                this.dataTypesConfig = profile?.dataTypes;
                                this.controlsConfig = profile?.orgControlDomainConfigs;
                            });
                    } else {
                        this.loadingProfile = false;
                        this.clientProfileId = null;
                        this.riskLevelsConfig = null;
                        this.dataSensitivityLevels = null;
                        this.dataTypesConfig = null;
                        this.controlsConfig = null;
                    }
                }),
                takeUntil(this._unsub),
            )
            .subscribe();
    }

    refreshClientProfile() {
        this._refreshClientProfileTrigger$.next();
    }

    trackOrgById(index: number, org: Org) {
        return org.id;
    }

    updateClientProfileData() {
        this.loadingProfile = true;
        this._clientProfileService
            .getForOrg(this.getOrgIdValue)
            .pipe(
                tap(() => (this.loadingProfile = false)),
                catchError(() => of(null)),
            )
            .subscribe((profile) => {
                this.dataTypesConfig = profile.dataTypes;
            });
    }

    ngOnDestroy(): void {
        this._unsub.next();
    }
}
