import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActiveStatus, SupplementalQuestionnaireConfig } from '@entities/org';
import { takeUntil, tap } from 'rxjs/operators';
import { ControlsOf } from '@shared/model/controls-of';
import { Subject } from 'rxjs';

interface SupplementalQuestionnaireConfigFormGroup {
    supplementalQuestionnaires: FormArray<FormGroup<ControlsOf<SupplementalQuestionnaireConfigForm>>>;
}

export interface SupplementalQuestionnaireConfigForm {
    id: string;
    questionnaireName: string;
    enabled: boolean;
    questionCount: number;
}

@Component({
    selector: 'app-supplemental-questionnaires-config-form',
    templateUrl: './supplemental-questionnaires-config-form.component.html',
    styleUrls: ['./supplemental-questionnaires-config-form.component.scss'],
})
export class SupplementalQuestionnairesConfigFormComponent implements OnInit, OnChanges {
    @Input({ required: true })
    supplementalQuestionnaireConfig: SupplementalQuestionnaireConfig[];

    @Input({ required: true })
    hasRelationshipSupplementalQuestionnaireConfig: boolean;

    @Output()
    onQuestionnaireConfigChanges = new EventEmitter<SupplementalQuestionnaireConfigForm[]>();

    supplementalQuestionnaireConfigFormGroup: FormGroup<SupplementalQuestionnaireConfigFormGroup>;

    showAllSupplementalQuestionnaires: boolean;

    private _unsub$ = new Subject<void>();

    constructor(private _fb: FormBuilder) {}

    get getSupplementalQuestionnaires(): { id: string; questionnaireName: string; questionCount: number }[] {
        return this.supplementalQuestionnaireConfigFormGroup.controls.supplementalQuestionnaires.controls.map((s) => {
            return {
                id: s.controls.id.value,
                questionnaireName: s.controls.questionnaireName.value,
                questionCount: s.controls.questionCount.value,
            };
        });
    }

    ngOnInit(): void {
        this.supplementalQuestionnaireConfigFormGroup = this._fb.group({
            supplementalQuestionnaires: this._fb.array<FormGroup<ControlsOf<SupplementalQuestionnaireConfigForm>>>(
                this.supplementalQuestionnaireConfig
                    ?.filter((suppQ) => suppQ.activeStatus === ActiveStatus.ACTIVE)
                    .map((suppQConfig) => {
                        return this._fb.group({
                            id: suppQConfig.id,
                            questionnaireName: suppQConfig.questionnaireName,
                            enabled: suppQConfig.enabled,
                            questionCount: suppQConfig.questions?.length,
                        });
                    }) || [],
            ),
        });

        this.supplementalQuestionnaireConfigFormGroup.controls.supplementalQuestionnaires.valueChanges
            .pipe(
                tap((selectContact: SupplementalQuestionnaireConfigForm[]) => {
                    this.onQuestionnaireConfigChanges.emit(selectContact);
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.supplementalQuestionnaireConfig && !!this.supplementalQuestionnaireConfigFormGroup) {
            this.supplementalQuestionnaireConfigFormGroup.controls.supplementalQuestionnaires.clear();
            this.supplementalQuestionnaireConfig
                ?.filter((suppQ) => suppQ.activeStatus === ActiveStatus.ACTIVE)
                .forEach((suppQConfig) => {
                    this.supplementalQuestionnaireConfigFormGroup.controls.supplementalQuestionnaires.push(
                        this._fb.group({
                            id: this._fb.control(suppQConfig.id),
                            questionnaireName: this._fb.control(suppQConfig.questionnaireName),
                            enabled: this._fb.control(suppQConfig.enabled),
                            questionCount: this._fb.control(suppQConfig.questions?.length),
                        }),
                    );
                });
        }
    }

    getFormControlBySupplementalQuestionnaire(supplementalQuestionnaireId: string): FormControl<boolean> {
        for (let assessmentSectionFormGroup of this.supplementalQuestionnaireConfigFormGroup.controls
            .supplementalQuestionnaires.controls) {
            if (assessmentSectionFormGroup.controls.id.getRawValue() === supplementalQuestionnaireId) {
                return assessmentSectionFormGroup.controls.enabled;
            }
        }
        return new FormControl<boolean>(false);
    }

    getQuestionCountText(questionCount: number): string {
        return `${questionCount} ${questionCount > 1 ? 'questions' : 'question'}`;
    }
}
