import { Component, Input } from '@angular/core';
import { QuestionnaireAnswer, QuestionnaireAnswerTypeTabLabels } from '@entities/assessment';
import { Artifact, ControlValidationDetection } from '@entities/artifact';
import { Detection } from '@shared/artifact-intelligence/models/detection.model';
import { BehaviorSubject } from 'rxjs';
import { ControlDomainType } from '@entities/control-domain';

@Component({
    selector: 'app-questionnaire-response',
    templateUrl: './questionnaire-response.component.html',
    styleUrls: ['./questionnaire-response.component.scss'],
})
export class QuestionnaireResponseComponent {
    @Input({ required: true })
    set questionnaireAnswers(value: QuestionnaireAnswer[]) {
        this._questionnaireAnswers$.next(value);
    }

    @Input({ required: true })
    set requestArtifacts(value: Artifact[]) {
        this._requestArtifacts$.next(value);
    }

    @Input({ required: true })
    supplementalQuestionnairesEnabled: boolean;

    QuestionnaireAnswerTypeTabLabels = QuestionnaireAnswerTypeTabLabels;

    private _questionnaireAnswers$ = new BehaviorSubject<QuestionnaireAnswer[]>([]);
    private _requestArtifacts$ = new BehaviorSubject<Artifact[]>([]);

    keepOrder = (a, b) => {
        return a;
    };

    filterByAnswerType(answerType: string): QuestionnaireAnswer[] {
        if (answerType === 'ALL') {
            return this._questionnaireAnswers$.getValue();
        } else if (answerType === 'NO_ANSWER') {
            return this._questionnaireAnswers$
                .getValue()
                .filter((answer) => answer.answerType === null && answer.mlAnswerType === null);
        } else {
            return this._questionnaireAnswers$
                .getValue()
                .filter((answer) => (answer.answerType || answer.mlAnswerType) === answerType);
        }
    }

    populateDetections = (detections: ControlValidationDetection[]): Detection[] => {
        return detections.map((detection) => {
            const mappedArtifact = this._requestArtifacts$
                .getValue()
                .find((artifact) => artifact.id === detection.referencedArtifactId);
            return {
                id: detection.id,
                artifactExpirationDate: mappedArtifact?.expirationDate,
                artifactExpired: mappedArtifact?.validation?.expired,
                artifactId: mappedArtifact?.id,
                artifactValidationAuditReportType: mappedArtifact?.validation?.auditReportType,
                artifactValidationExpired: mappedArtifact?.validation?.expired,
                auditorComment: detection.auditorComment,
                auditReportAssurance: mappedArtifact?.validation.auditReportAssurance,
                auditReportAssuranceLevel: mappedArtifact?.validation.auditReportAssuranceLevel,
                auditReportType: mappedArtifact?.validation.auditReportType,
                confidence: detection.confidence,
                controlIds: detection.controlIdsText,
                controlId: detection.controlId,
                detectionType: detection.type,
                managementResponse: detection.managementResponse,
                notes: detection.notes,
                sectionName: detection.sectionName,
                subservicers: detection.subservicers?.map((subservicer) => ({
                    name: subservicer.subservicerName,
                    orgId: subservicer.subservicerId,
                })),
                artifactName: mappedArtifact?.fileName,
                artifactType: mappedArtifact?.type,
                viewerPage: detection?.viewerPage,
            };
        });
    };

    displayAnswer(answer: QuestionnaireAnswer) {
        return (
            this.supplementalQuestionnairesEnabled ||
            answer.controlDomainType !== ControlDomainType.SUPPLEMENTAL_QUESTIONNAIRE
        );
    }
}
