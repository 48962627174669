import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, forkJoin, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { MenuService } from '../../core/menu/menu.service';
import { SettingsService } from '../../core/settings/settings.service';
import { getUserAccount } from '../../routes/session/redux/session.selectors';
import { FeatureFlagService } from '@shared/services/featureflag.service';
import { FeatureFlags } from '@shared/enums/feature-flags';
import { BaseMenu, menu, MenuWithSubmenus, Submenu } from './menu';
import { getCurrentEnvironment } from '@shared/utils/environment-utils';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
    menuItems$ = new BehaviorSubject<BaseMenu[]>([]);
    isTrustEnabled: boolean;
    isArtifactIntelligenceEnabled: boolean;
    isQuestionnaireAnsweringEnabled: boolean;
    isRiskModelSettingsEnabled: boolean;
    isSupplementalQuestionnairesEnabled: boolean;
    popperOptions = (options) => ({
        ...options,
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, -5],
                },
            },
        ],
    });
    env = getCurrentEnvironment();

    private _unsub$: Subject<void> = new Subject<void>();

    constructor(
        private _menu: MenuService,
        private _router: Router,
        private _store$: Store,
        private _settings: SettingsService,
        private _featureFlagService: FeatureFlagService,
    ) {}

    get isCollapsed(): boolean {
        return this._settings.layout.isCollapsed;
    }

    get asideScrollbar(): boolean {
        return this._settings.layout.asideScrollbar;
    }

    ngOnInit(): void {
        this.loadFeatureFlags();

        this._store$
            .pipe(
                select(getUserAccount),
                tap((account) => {
                    if (account) {
                        this._settings.layout.isCollapsed = false;
                    }
                }),
                map((account) => this._menu.filterMenuByAccount(menu, account)),
                takeUntil(this._unsub$),
            )
            .subscribe((menus) => this.menuItems$.next(menus));

        this._router.events.pipe(takeUntil(this._unsub$)).subscribe((val) => {
            this._settings.layout.asideToggled = false;
        });

        forkJoin([
            this._router.events.pipe(
                filter((event) => event instanceof NavigationEnd),
                map((event) => (event as NavigationEnd).url),
                take(1),
            ),
            this._store$.pipe(
                select(getUserAccount),
                filter((account) => !!account),
                switchMap(() =>
                    this.menuItems$.pipe(
                        filter((val) => !!val.length),
                        take(1),
                    ),
                ),
                take(1),
            ),
        ])
            .pipe(take(1))
            .subscribe(([route, menus]) => {
                const activeSubmenu = menus
                    .filter((x) => !!x.submenu)
                    .find((menu) => menu.submenu.some((submenu) => submenu.link === route));
                if (activeSubmenu) {
                    this.toggleCollapse(activeSubmenu);
                }
            });
    }

    toggleCollapse(menuWithSubmenu?: MenuWithSubmenus): void {
        const menus = this.menuItems$.value;
        this.menuItems$.next(
            menus.map((menu) => {
                if (menu === menuWithSubmenu) {
                    menu.isCollapsed = !menu.isCollapsed;
                } else if (typeof menu.isCollapsed !== 'undefined') {
                    menu.isCollapsed = true;
                }
                return menu;
            }),
        );
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }

    private loadFeatureFlags(): void {
        this._featureFlagService.flagsLoaded.pipe(takeUntil(this._unsub$)).subscribe((flags) => {
            this.isTrustEnabled = flags[FeatureFlags.TRUST] ?? false;
            this.isArtifactIntelligenceEnabled = flags[FeatureFlags.TRUST] ?? false;
            this.isQuestionnaireAnsweringEnabled = flags[FeatureFlags.TRUST] ?? false;
            this.isRiskModelSettingsEnabled = flags[FeatureFlags.RISK_TOLERANCE] ?? false;
            this.isSupplementalQuestionnairesEnabled = flags[FeatureFlags.SUPPLEMENTAL_QUESTIONNAIRES] ?? false;
        });
    }

    checkFeatureFlagEnabled(item: BaseMenu | Partial<Submenu>): boolean {
        if (!!item.featureFlag) {
            if (item.featureFlag === FeatureFlags.TRUST) {
                return this.isTrustEnabled;
            } else if (item.featureFlag === FeatureFlags.TRUST) {
                return this.isArtifactIntelligenceEnabled;
            } else if (item.featureFlag === FeatureFlags.TRUST) {
                return this.isQuestionnaireAnsweringEnabled;
            } else if (item.featureFlag === FeatureFlags.RISK_TOLERANCE) {
                return this.isRiskModelSettingsEnabled;
            } else if (item.featureFlag === FeatureFlags.SUPPLEMENTAL_QUESTIONNAIRES) {
                return this.isSupplementalQuestionnairesEnabled;
            }
        } else {
            return true;
        }
    }
}
