<div mat-dialog-title>
    Follow-up recommended
    <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <div class="mt-2">
        <app-assessment-risk-diff [completedRiskAssessment]="latestRiskAssessment" />
    </div>
    <p class="my-3">
        You have the power to choose how to proceed with this assessment. You can follow up by sending a short
        questionnaire to address the identified gaps, which may lower the residual risk. The vendor will have 7 days to
        respond before the assessment is marked complete. Alternatively, complete the assessment now and proceed with
        remediation or risk acceptance.
    </p>
    <mat-divider class="my-4" />
    <h4>Unvalidated controls ({{ followupControlDomainNames.length }})</h4>
    <ul>
        @for (controlDomainName of followupControlDomainNames; track $index) {
            <li>{{ controlDomainName }}</li>
        }
    </ul>
    @if (supplementalQuestionnairesEnabled && !!unansweredSupplementalQuestions?.length) {
        <mat-divider class="my-4" />
        <h4>Unanswered supplemental questions ({{ unansweredSupplementalQuestions.length }})</h4>
        <ul class="mb-0">
            @for (question of unansweredSupplementalQuestions.slice(0, 5); track $index) {
                <li>
                    <p class="mb-0">{{ question }}</p>
                </li>
            }
        </ul>
        @if (unansweredSupplementalQuestions.length > 5) {
            @if (showAllQuestions) {
                <ul class="mb-0">
                    @for (question of unansweredSupplementalQuestions.slice(5); track $index) {
                        <li>
                            <p class="mb-0">{{ question }}</p>
                        </li>
                    }
                </ul>
            } @else {
                <strong
                    class="text-secondary"
                    role="button"
                    aria-label="Show remaining unanswered questions"
                    (click)="showAllQuestions = true"
                    >Show all</strong
                >
            }
        }
    }
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-between">
    <button mat-stroked-button color="primary" class="me-2" type="button" mat-dialog-close>Cancel</button>
    <div>
        <button mat-stroked-button color="primary" class="me-2" type="button" (click)="completeAssessment()">
            Skip and complete assessment
        </button>
        <button mat-flat-button color="primary" (click)="sendFollowup()">Send follow-up questionnaire</button>
    </div>
</mat-dialog-actions>
