<mat-list class="pt-2">
    @for (
        supplementalQuestionnaire of getSupplementalQuestionnaires;
        track supplementalQuestionnaire;
        let index = $index
    ) {
        <mat-list-item
            class="form-group flex-row ps-0"
            *ngIf="(!showAllSupplementalQuestionnaires && index < 3) || showAllSupplementalQuestionnaires"
        >
            <div matListItemTitle>
                {{ supplementalQuestionnaire.questionnaireName }}
            </div>
            <div matListItemLine class="text-sm">
                {{ supplementalQuestionnaire.questionCount | callback: getQuestionCountText }}
            </div>

            <mat-slide-toggle
                matListItemMeta
                color="primary"
                labelPosition="before"
                [formControl]="getFormControlBySupplementalQuestionnaire(supplementalQuestionnaire.id)"
            ></mat-slide-toggle>
        </mat-list-item>
    } @empty {
        <p class="mt-4 text-bold">No supplemental questionnaires have been configured for this organization.</p>
    }
</mat-list>

<a
    *ngIf="getSupplementalQuestionnaires.length > 3"
    class="text-secondary cursor-pointer"
    (click)="showAllSupplementalQuestionnaires = !showAllSupplementalQuestionnaires"
>
    {{ showAllSupplementalQuestionnaires ? 'Show less' : 'Show all' }}
</a>
