import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { UploadArtifactsRequest } from '@shared/upload-artifacts';
import {
    CreateSupplementalQuestionnaireRequest,
    UpdateSupplementalQuestionnaireRequest,
} from '../model/questionnaire-management-models';
import {
    SupplementalQuestionnaireDialogStep,
    SupplementalQuestionnaireFormGroup,
} from '../components/supplemental-questionnaire-dialog/supplemental-questionnaire-dialog.component';
import { FormGroup } from '@angular/forms';
import { QuestionnaireImportReport } from '../../../bulk-import/import-job.model';
import {
    SupplementalQuestionnaireResponse,
    SupplementalQuestionnaireWithQuestions,
} from '@entities/supplemental-questionnaire';

export enum QuestionnaireManagementActions {
    GetSupplementalQuestionnairesRequest = '[Questionnaire Management] Get Supplemental Questionnaires Request',
    GetSupplementalQuestionnairesRequestSuccess = '[Questionnaire Management] Get Supplemental Questionnaires Request Success',
    GetSupplementalQuestionnairesRequestFailed = '[Questionnaire Management] Get Supplemental Questionnaires Request Failed',

    UploadSupplementalQuestionnairesRequest = '[Questionnaire Management] Upload Supplemental Questionnaires Request',
    UploadSupplementalQuestionnairesRequestSuccess = '[Questionnaire Management] Upload Supplemental Questionnaires Request Success',
    UploadSupplementalQuestionnairesRequestFailed = '[Questionnaire Management] Upload Supplemental Questionnaires Request Failed',

    DeleteSupplementalQuestionnaireRequest = '[Questionnaire Management] Delete Supplemental Questionnaire Request',
    DeleteSupplementalQuestionnaireRequestSuccess = '[Questionnaire Management] Delete Supplemental Questionnaire Request Success',
    DeleteSupplementalQuestionnaireRequestFailed = '[Questionnaire Management] Delete Supplemental Questionnaire Request Failed',

    OpenCreateSupplementalQuestionnaireDialog = '[Questionnaire Management] Open Create Supplemental Questionnaire Dialog',
    CancelCreateSupplementalQuestionnaireDialog = '[Questionnaire Management] Cancel Create Supplemental Questionnaire Dialog',
    CloseCreateSupplementalQuestionnaireDialog = '[Questionnaire Management] Close Create Supplemental Questionnaire Dialog',

    CreateSupplementalQuestionnaireRequest = '[Questionnaire Management] Create Supplemental Questionnaire Request',
    CreateSupplementalQuestionnaireRequestSuccess = '[Questionnaire Management] Create Supplemental Questionnaire Request Success',
    CreateSupplementalQuestionnaireRequestFailed = '[Questionnaire Management] Create Supplemental Questionnaire Request Failed',

    UpdateSupplementalQuestionnaireRequest = '[Questionnaire Management] Update Supplemental Questionnaire Request',
    UpdateSupplementalQuestionnaireRequestSuccess = '[Questionnaire Management] Update Supplemental Questionnaire Request Success',
    UpdateSupplementalQuestionnaireRequestFailed = '[Questionnaire Management] Update Supplemental Questionnaire Request Failed',

    ImportSupplementalQuestionnaireRequest = '[Questionnaire Management] Import Supplemental Questionnaire Request',
    ImportSupplementalQuestionnaireRequestSuccess = '[Questionnaire Management] Import Supplemental Questionnaire Request Success',
    ImportSupplementalQuestionnaireRequestFailed = '[Questionnaire Management] Import Supplemental Questionnaire Request Failed',

    ImportSupplementalQuestionnaireRequestCancelled = '[Questionnaire Management] Import Supplemental Questionnaire Request Cancelled',
    ImportSupplementalQuestionnaireRequestCancelledSuccess = '[Questionnaire Management] Import Supplemental Questionnaire Request Cancelled Success',
    ImportSupplementalQuestionnaireRequestCancelledFailed = '[Questionnaire Management] Import Supplemental Questionnaire Request Cancelled Failed',

    ImportSupplementalQuestionnaireRequestProgress = '[Questionnaire Management] Import Supplemental Questionnaire Request Progress',
    OnWhenAddingImportFileFailed = '[Questionnaire Management] On When Adding Import File Failed',
}

export const getSupplementalQuestionnairesRequest = createAction(
    QuestionnaireManagementActions.GetSupplementalQuestionnairesRequest,
    props<{ pageable?: any }>(),
);

export const getSupplementalQuestionnairesRequestSuccess = createAction(
    QuestionnaireManagementActions.GetSupplementalQuestionnairesRequestSuccess,
    props<{ questionnaires: SupplementalQuestionnaireResponse[]; headers: HttpHeaders }>(),
);

export const getSupplementalQuestionnairesRequestFailed = createAction(
    QuestionnaireManagementActions.GetSupplementalQuestionnairesRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const uploadSupplementalQuestionnairesRequest = createAction(
    QuestionnaireManagementActions.UploadSupplementalQuestionnairesRequest,
    props<UploadArtifactsRequest>(),
);

export const uploadSupplementalQuestionnairesRequestSuccess = createAction(
    QuestionnaireManagementActions.UploadSupplementalQuestionnairesRequestSuccess,
);

export const uploadSupplementalQuestionnairesRequestFailed = createAction(
    QuestionnaireManagementActions.UploadSupplementalQuestionnairesRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const deleteSupplementalQuestionnaireRequest = createAction(
    QuestionnaireManagementActions.DeleteSupplementalQuestionnaireRequest,
    props<{ questionnaireId: string }>(),
);

export const deleteSupplementalQuestionnaireRequestSuccess = createAction(
    QuestionnaireManagementActions.DeleteSupplementalQuestionnaireRequestSuccess,
);

export const deleteSupplementalQuestionnaireRequestFailed = createAction(
    QuestionnaireManagementActions.DeleteSupplementalQuestionnaireRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const createSupplementalQuestionnaireRequest = createAction(
    QuestionnaireManagementActions.CreateSupplementalQuestionnaireRequest,
    props<{ createRequest: CreateSupplementalQuestionnaireRequest }>(),
);

export const createSupplementalQuestionnaireRequestSuccess = createAction(
    QuestionnaireManagementActions.CreateSupplementalQuestionnaireRequestSuccess,
);

export const createSupplementalQuestionnaireRequestFailed = createAction(
    QuestionnaireManagementActions.CreateSupplementalQuestionnaireRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const updateSupplementalQuestionnaireRequest = createAction(
    QuestionnaireManagementActions.UpdateSupplementalQuestionnaireRequest,
    props<{ updateRequest: UpdateSupplementalQuestionnaireRequest }>(),
);

export const updateSupplementalQuestionnaireRequestSuccess = createAction(
    QuestionnaireManagementActions.UpdateSupplementalQuestionnaireRequestSuccess,
);

export const updateSupplementalQuestionnaireRequestFailed = createAction(
    QuestionnaireManagementActions.UpdateSupplementalQuestionnaireRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const openCreateSupplementalQuestionnaireDialog = createAction(
    QuestionnaireManagementActions.OpenCreateSupplementalQuestionnaireDialog,
    props<{
        questionnaireStep: SupplementalQuestionnaireDialogStep;
        questionnaireToEdit?: SupplementalQuestionnaireWithQuestions;
        supplementalQuestionnaireFormData?: FormGroup<SupplementalQuestionnaireFormGroup>;
    }>(),
);

export const cancelCreateSupplementalQuestionnaireDialog = createAction(
    QuestionnaireManagementActions.CancelCreateSupplementalQuestionnaireDialog,
    props<{
        supplementalQuestionnaireFormData: FormGroup<SupplementalQuestionnaireFormGroup>;
        importJobId?: number;
        questionnaireStep: SupplementalQuestionnaireDialogStep;
    }>(),
);

export const closeCreateSupplementalQuestionnaireDialog = createAction(
    QuestionnaireManagementActions.CloseCreateSupplementalQuestionnaireDialog,
    props<{ importJobId?: number }>(),
);

export const importSupplementalQuestionnaireRequest = createAction(
    QuestionnaireManagementActions.ImportSupplementalQuestionnaireRequest,
    props<{ file: File }>(),
);

export const importSupplementalQuestionnaireRequestSuccess = createAction(
    QuestionnaireManagementActions.ImportSupplementalQuestionnaireRequestSuccess,
    props<{ importReport: QuestionnaireImportReport }>(),
);

export const importSupplementalQuestionnaireRequestFailed = createAction(
    QuestionnaireManagementActions.ImportSupplementalQuestionnaireRequestFailed,
);

export const importSupplementalQuestionnaireRequestCancelled = createAction(
    QuestionnaireManagementActions.ImportSupplementalQuestionnaireRequestCancelled,
    props<{ importJobId?: number }>(),
);

export const importSupplementalQuestionnaireRequestCancelledSuccess = createAction(
    QuestionnaireManagementActions.ImportSupplementalQuestionnaireRequestCancelledSuccess,
);

export const importSupplementalQuestionnaireRequestCancelledFailed = createAction(
    QuestionnaireManagementActions.ImportSupplementalQuestionnaireRequestCancelledFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const importSupplementalQuestionnaireRequestProgress = createAction(
    QuestionnaireManagementActions.ImportSupplementalQuestionnaireRequestProgress,
    props<{ percent: number }>(),
);

export const onWhenAddingImportFileFailed = createAction(
    QuestionnaireManagementActions.OnWhenAddingImportFileFailed,
    props<{ message: string }>(),
);
