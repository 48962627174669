import { Component, OnInit } from '@angular/core';
import { Org } from '../../entities/org';
import { RiskAssessmentService } from '../../entities/risk-assessment';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from '../../shared/components/snackbar/snackbar.service';
import { finalize } from 'rxjs/operators';
import { VendorSearchResult } from '@shared/vendor-components/models/vendor-search-result';
import { RiskAssessmentRefresh } from '../../admin';

interface RiskAssessmentRefreshForm {
    vendorRef: FormControl<VendorSearchResult>;
    reasonDetail: FormControl<string>;
    createNew: FormControl<boolean>;
}

@Component({
    selector: 'app-risk-model',
    templateUrl: './risk-model.component.html',
    styleUrls: [],
})
export class RiskModelComponent implements OnInit {
    orgs: Org[];
    isSaving: boolean;
    riskAssessmentRefreshForm: FormGroup<RiskAssessmentRefreshForm>;

    constructor(
        private _riskAssessmentService: RiskAssessmentService,
        private _snackbarService: SnackbarService,
        private _fb: FormBuilder,
    ) {}

    ngOnInit(): void {
        this.riskAssessmentRefreshForm = this._fb.group({
            vendorRef: [null],
            reasonDetail: ['', Validators.required],
            createNew: [false],
        });
    }

    submit(): void {
        this.isSaving = true;
        const formValues = this.riskAssessmentRefreshForm.value;
        const orgName = formValues.vendorRef?.name;
        const payload = {
            ...formValues,
            orgId: formValues.vendorRef?.id,
            vendorRef: undefined,
        } as RiskAssessmentRefresh;
        this._riskAssessmentService
            .refreshModel(payload)
            .pipe(finalize(() => (this.isSaving = false)))
            .subscribe(() => {
                this._snackbarService.success(
                    `Risk model is now being refreshed for ${!!orgName ? orgName : 'all orgs'}.`,
                );
            });
    }

    trackOrgById(index: number, item: Org) {
        return item.id;
    }
}
