@if (!!questionnairePages().length) {
    <app-custom-stepper>
        @for (questionnairePage of questionnairePages(); track $index) {
            <cdk-step [id]="$index">
                <div class="container-top-half pb-0">
                    <div class="row">
                        <div class="col-4">
                            <p>Additional questionnaires ({{ $index + 1 }} of {{ questionnairePages().length }})</p>
                            <h1 class="assessment-collection-step-title">
                                {{ questionnairePage.pageTitle }}
                            </h1>
                            @if (forFollowup()) {
                                <p>{{ clientName() }} still has a few questions about your company's practices.</p>
                            } @else {
                                <p>
                                    Don't want to answer a lengthy questionnaire? <br />
                                    <a
                                        class="text-secondary"
                                        href="javascript:void(0)"
                                        (click)="wentToCollectArtifacts.emit()"
                                        >Upload artifacts</a
                                    >
                                    instead.
                                </p>
                            }
                        </div>
                        <div class="col-auto pb-4">
                            <mat-divider class="h-100" [vertical]="true"></mat-divider>
                        </div>
                        <div class="col pe-2 fixed-height">
                            @for (answerFormGroup of questionnairePage.questionsFormArray.controls; track $index) {
                                <form
                                    class="pt-2"
                                    [formGroup]="answerFormGroup"
                                    [ngClass]="{ 'question-margin': !$first }"
                                >
                                    <div class="question-heading ps-4 mb-3" [attr.question-number]="$index + 1">
                                        <p class="mb-2">{{ answerFormGroup.controls.question.value }}</p>
                                        @if (
                                            answerFormGroup.controls.controlDomainType.value !==
                                            ControlDomainType.SUPPLEMENTAL_QUESTIONNAIRE
                                        ) {
                                            <small>
                                                Control domain:
                                                {{
                                                    ControlDomainTypeLabels[
                                                        answerFormGroup.controls.controlDomainType.value
                                                    ]
                                                }}
                                                -
                                                {{ answerFormGroup.controls.controlDomainName.value }}
                                            </small>
                                        }
                                    </div>

                                    <div class="d-flex align-items-center mb-3">
                                        <mat-form-field class="w-100 dense-3" [floatLabel]="'auto'">
                                            <mat-label>Select response</mat-label>
                                            <mat-select [formControl]="answerFormGroup.controls.answerType">
                                                @for (
                                                    answerType of QuestionnaireAnswerType
                                                        | enumToArray: QuestionnaireAnswerTypeLabels;
                                                    track answerType.value
                                                ) {
                                                    <mat-option [value]="answerType.value">
                                                        {{ answerType.label }}
                                                    </mat-option>
                                                }
                                            </mat-select>
                                        </mat-form-field>
                                        @if (!!answerFormGroup.controls.controlDomainDescription?.value) {
                                            <div
                                                class="mx-3 text-secondary guidance-text"
                                                [appPopover]="guidancePopover"
                                                popoverPosition="left"
                                                popoverTheme="dark"
                                            >
                                                Guidance
                                            </div>
                                            <ng-template #guidancePopover>
                                                <div class="guidance-popover p-3">
                                                    <div>
                                                        {{ answerFormGroup.controls.controlDomainDescription.value }}
                                                    </div>
                                                </div>
                                            </ng-template>
                                        }
                                    </div>
                                    <mat-form-field class="w-100 dense-3 mb-3" [floatLabel]="'auto'">
                                        <mat-label>Add description</mat-label>
                                        <textarea matInput [formControl]="answerFormGroup.controls.answer"></textarea>
                                    </mat-form-field>
                                </form>
                            }
                        </div>
                    </div>
                </div>
                <div class="container-bottom-half">
                    <div class="d-flex justify-content-between">
                        <button type="button" mat-stroked-button color="secondary" (click)="goBack()">Go back</button>
                        <button
                            type="button"
                            mat-flat-button
                            color="secondary"
                            (click)="attemptToContinue()"
                            data-pw="continueButton"
                        >
                            Continue
                        </button>
                    </div>
                </div>
            </cdk-step>
        }
    </app-custom-stepper>
} @else {
    <div class="d-flex align-items-center justify-content-center">
        <div class="sk-spinner sk-spinner-pulse"></div>
    </div>
}
