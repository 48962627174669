<header class="content-heading">
    <section>
        Notifications
        <small>Create Link Notification</small>
    </section>
</header>

<div class="container-fluid">
    <div class="row">
        <div class="col col-lg-6">
            <div class="card card-default">
                <div class="card-header">
                    <p class="card-title">Create a new Link Notification</p>
                </div>
                <div class="card-body">
                    <form (ngSubmit)="createLinkNotification()" [formGroup]="notificationsCreateFormGroup">
                        <div class="form-group">
                            <label id="org">Select Organization</label>
                            <app-vendor-search formControlName="vendorRef" [customOption]="false" />
                            <small>Leave blank to send to all users across all client orgs.</small>
                        </div>
                        <div class="form-group required">
                            <label for="description">Description</label>
                            <input
                                id="description"
                                type="text"
                                class="form-control"
                                formControlName="description"
                                required
                            />
                        </div>
                        <div class="form-group required">
                            <label for="anchorText">Anchor Text</label>
                            <input
                                id="anchorText"
                                type="text"
                                class="form-control"
                                formControlName="anchorText"
                                required
                            />
                        </div>
                        <div class="form-group required">
                            <label for="url">Url</label>
                            <input id="url" type="text" class="form-control" formControlName="url" required />
                            <small *ngIf="showUrlPatternError" class="text-danger"
                                >Please provide a valid URl with <em>https://</em> as prefix</small
                            >
                        </div>
                        <div class="form-group text-end">
                            <button
                                type="submit"
                                mat-button
                                visoButton="primary"
                                [disabled]="!notificationsCreateFormGroup.valid || savingNotification"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col col-lg-6">
            <ng-container *ngIf="previewLinkNotification$ | async as linkNotification">
                <div class="notifications-previewer">
                    <app-link-notification
                        *ngIf="!!linkNotification"
                        [linkNotification]="linkNotification"
                    ></app-link-notification>
                </div>
            </ng-container>
        </div>
    </div>
</div>
