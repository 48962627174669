import { createAction, props } from '@ngrx/store';
import { PrimaryVendorContact } from '../../primary-vendor-contact';
import { Risk, RiskAssessment } from '@entities/risk-assessment';
import { VisoUser } from '@entities/viso-user';
import { FollowupType } from '@entities/assessment';
import { SupplementalQuestionnaireConfig } from '@entities/org';

export enum RequestActions {
    GetRequestRiskAssessmentsRequest = '[Request] Get Request Risk Assessments Request',
    GetRequestRiskAssessmentsRequestSuccess = '[Request] Get Request Risk Assessments Request Success',
    GetRequestRiskAssessmentsRequestFailed = '[Request] Get Request Risk Assessments Request Failed',
    SubscribeContactRequest = '[Request] Subscribe Contact Request',
    SubscribeContactRequestSuccess = '[Request] Subscribe Contact Request Success',
    SubscribeContactRequestFailed = '[Request] Subscribe Contact Request Failed',
    UnsubscribeContactRequest = '[Request] Unsubscribe Contact Request',
    UnsubscribeContactRequestSuccess = '[Request] Unsubscribe Contact Request Success',
    UnsubscribeContactRequestFailed = '[Request] Unsubscribe Contact Request Failed',
    GetRelationshipSubscribersRequest = '[Request] Get Relationship Subscribers Request',
    GetRelationshipSubscribersRequestSuccess = '[Request] Get Relationship Subscribers Request Success',
    GetRelationshipSubscribersRequestFailed = '[Request] Get Relationship Subscribers Request Failed',
    GetRequestPrimaryVendorContactRequest = '[Request] Get Request Primary Vendor Contact Request',
    GetRequestPrimaryVendorContactRequestSuccess = '[Request] Get Request Primary Vendor Contact Request Success',
    GetRequestPrimaryVendorContactRequestFailed = '[Request] Get Request Primary Vendor Contact Request Failed',
    AddPrimaryVendorContactRequest = '[Request] Add Primary Vendor Contact Request',
    AddPrimaryVendorContactRequestSuccess = '[Request] Add Primary Vendor Contact Request Success',
    AddPrimaryVendorContactRequestFailed = '[Request] Add Primary Vendor Contact Request Failed',
    RemovePrimaryVendorContactRequest = '[Request] Remove Primary Vendor Contact Request',
    RemovePrimaryVendorContactRequestSuccess = '[Request] Remove Primary Vendor Contact Request Success',
    RemovePrimaryVendorContactRequestFailed = '[Request] Remove Primary Vendor Contact Request Failed',
    LinkTagRequest = '[Request] Link Tag',
    LinkTagRequestSuccess = '[Request] Link Tag Success',
    LinkTagRequestFailed = '[Request] Link Tag Failed',
    UnlinkTagRequest = '[Request] Unlink Tag',
    UnlinkTagRequestSuccess = '[Request] Unlink Tag Success',
    UnlinkTagRequestFailed = '[Request] Unlink Tag Failed',
    BulkLinkTagsRequest = '[Request] Bulk Link Tags',
    BulkLinkTagsSuccess = '[Request] Bulk Link Tags Success',
    BulkLinkTagsFailed = '[Request] Bulk Link Tags Failed',
    ResetRelationshipState = '[Request] Reset Relationship State',
    OpenReviewRiskDialog = '[Request] Open Review Risk Dialog',
    OpenRemediationDialog = '[Request] Open Remediation Dialog',
    OpenCancelRemediationDialog = '[Request] Open Cancel Remediation Dialog',
    StartRemediationRequest = '[Request] Start Remediation Request',
    StartRemediationRequestSuccess = '[Request] Start Remediation Request Success',
    StartRemediationRequestFailed = '[Request] Start Remediation Request Failed',
    CancelRemediationRequest = '[Request] Cancel Remediation Request',
    CancelRemediationRequestSuccess = '[Request] Cancel Remediation Request Success',
    CancelRemediationRequestFailed = '[Request] Cancel Remediation Request Failed',
    AcceptRiskRequest = '[Request] Accept Risk Request',
    AcceptRiskRequestSuccess = '[Request] Accept Risk Request Success',
    AcceptRiskRequestFailed = '[Request] Accept Risk Request Failed',
    RevokeRiskAcceptanceRequest = '[Request] Revoke Risk Acceptance Request',
    RevokeRiskAcceptanceRequestSuccess = '[Request] Revoke Risk Acceptance Request Success',
    RevokeRiskAcceptanceRequestFailed = '[Request] Revoke Risk Acceptance Request Failed',
    OpenReviewFollowupDialog = '[Request] Open Review Followup Dialog',
    OpenEditFollowupMethodDialog = '[Request] Open Edit Followup Method Dialog',
    CompleteLatestAssessmentRequest = '[Request] Complete Latest Assessment Request',
    CompleteLatestAssessmentRequestSuccess = '[Request] Complete Latest Assessment Request Success',
    CompleteLatestAssessmentRequestFailed = '[Request] Complete Latest Assessment Request Failed',
    SendLatestAssessmentFollowupRequest = '[Request] Send Latest Assessment Followup Request',
    SendLatestAssessmentFollowupRequestSuccess = '[Request] Send Latest Assessment Followup Request Success',
    SendLatestAssessmentFollowupRequestFailed = '[Request] Send Latest Assessment Followup Request Failed',
    UpdateFollowupMethodRequest = '[Request] Update Followup Method Request',
    UpdateFollowupMethodRequestSuccess = '[Request] Update Followup Method Request Success',
    UpdateFollowupMethodRequestFailed = '[Request] Update Followup Method Request Failed',
    CombineSupplementalQuestionnaireConfiguration = '[Request] Combine Supplemental Questionnaire Configuration',
}

export const getRelationshipSubscribersRequest = createAction(
    RequestActions.GetRelationshipSubscribersRequest,
    props<{ requestId: number }>(),
);

export const getRelationshipSubscribersRequestSuccess = createAction(
    RequestActions.GetRelationshipSubscribersRequestSuccess,
    props<{ subscribers: VisoUser[] }>(),
);

export const getRelationshipSubscribersRequestFailed = createAction(
    RequestActions.GetRelationshipSubscribersRequestFailed,
);

export const getRequestPrimaryVendorContactRequest = createAction(
    RequestActions.GetRequestPrimaryVendorContactRequest,
    props<{ requestId: number }>(),
);

export const getRequestPrimaryVendorContactRequestSuccess = createAction(
    RequestActions.GetRequestPrimaryVendorContactRequestSuccess,
    props<{ primaryVendorContact: PrimaryVendorContact }>(),
);

export const getRequestPrimaryVendorContactRequestFailed = createAction(
    RequestActions.GetRequestPrimaryVendorContactRequestFailed,
);

export const getRequestRiskAssessmentsRequest = createAction(
    RequestActions.GetRequestRiskAssessmentsRequest,
    props<{ requestId: number }>(),
);

export const getRequestRiskAssessmentsRequestSuccess = createAction(
    RequestActions.GetRequestRiskAssessmentsRequestSuccess,
    props<{ riskAssessments: RiskAssessment[] }>(),
);

export const getRequestRiskAssessmentsRequestFailed = createAction(
    RequestActions.GetRequestRiskAssessmentsRequestFailed,
);

export const subscribeContactRequest = createAction(
    RequestActions.SubscribeContactRequest,
    props<{ contactId: number; requestId: number }>(),
);

export const subscribeContactRequestSuccess = createAction(RequestActions.SubscribeContactRequestSuccess);

export const subscribeContactRequestFailed = createAction(RequestActions.SubscribeContactRequestFailed);

export const unsubscribeContactRequest = createAction(
    RequestActions.UnsubscribeContactRequest,
    props<{ contactId: number; requestId: number }>(),
);

export const unsubscribeContactRequestSuccess = createAction(RequestActions.UnsubscribeContactRequestSuccess);

export const unsubscribeContactRequestFailed = createAction(RequestActions.UnsubscribeContactRequestFailed);

export const addPrimaryVendorContactRequest = createAction(
    RequestActions.AddPrimaryVendorContactRequest,
    props<{ primaryVendorContact: PrimaryVendorContact }>(),
);

export const addPrimaryVendorContactRequestSuccess = createAction(RequestActions.AddPrimaryVendorContactRequestSuccess);

export const addPrimaryVendorContactRequestFailed = createAction(RequestActions.AddPrimaryVendorContactRequestFailed);

export const removePrimaryVendorContactRequest = createAction(
    RequestActions.RemovePrimaryVendorContactRequest,
    props<{ primaryContactId: number; relationshipId: number; primaryContactEmail: string }>(),
);

export const removePrimaryVendorContactRequestSuccess = createAction(
    RequestActions.RemovePrimaryVendorContactRequestSuccess,
);

export const removePrimaryVendorContactRequestFailed = createAction(
    RequestActions.RemovePrimaryVendorContactRequestFailed,
);

export const linkTagRequest = createAction(
    RequestActions.LinkTagRequest,
    props<{ tagId: string; requestId: number }>(),
);

export const linkTagRequestSuccess = createAction(RequestActions.LinkTagRequestSuccess);

export const linkTagRequestFailed = createAction(RequestActions.LinkTagRequestFailed);

export const unlinkTagRequest = createAction(
    RequestActions.UnlinkTagRequest,
    props<{ tagId: string; requestId: number }>(),
);

export const unlinkTagRequestSuccess = createAction(RequestActions.UnlinkTagRequestSuccess);

export const unlinkTagRequestFailed = createAction(RequestActions.UnlinkTagRequestFailed);

export const bulkLinkTagsRequest = createAction(
    RequestActions.BulkLinkTagsRequest,
    props<{ tagIds: string[]; relationshipIds: number[] }>(),
);

export const bulkLinkTagsRequestSuccess = createAction(RequestActions.BulkLinkTagsSuccess);

export const bulkLinkTagsRequestFailed = createAction(RequestActions.BulkLinkTagsFailed);

export const resetRelationshipState = createAction(RequestActions.ResetRelationshipState);

export const openReviewRiskDialog = createAction(
    RequestActions.OpenReviewRiskDialog,
    props<{ riskReviewNote: string }>(),
);

export const openRemediationDialog = createAction(
    RequestActions.OpenRemediationDialog,
    props<{ riskReviewNote: string }>(),
);

export const openCancelRemediationDialog = createAction(
    RequestActions.OpenCancelRemediationDialog,
    props<{ riskReviewNote: string }>(),
);

export const startRemediationRequest = createAction(
    RequestActions.StartRemediationRequest,
    props<{
        relationshipId: number;
        targetDate: Date;
        sendRemediationToSubscribers: boolean;
        remediationComment?: string;
        riskReviewNote?: string;
    }>(),
);

export const cancelRemediationRequest = createAction(
    RequestActions.CancelRemediationRequest,
    props<{ relationshipId: number; riskReviewNote: string }>(),
);

export const cancelRemediationRequestSuccess = createAction(RequestActions.CancelRemediationRequestSuccess);

export const cancelRemediationRequestFailed = createAction(RequestActions.CancelRemediationRequestFailed);

export const startRemediationRequestSuccess = createAction(RequestActions.StartRemediationRequestSuccess);

export const startRemediationRequestFailed = createAction(RequestActions.StartRemediationRequestFailed);

export const acceptRiskRequest = createAction(
    RequestActions.AcceptRiskRequest,
    props<{ riskReviewNote?: string; riskOverride?: Risk }>(),
);

export const acceptRiskRequestSuccess = createAction(RequestActions.AcceptRiskRequestSuccess);

export const acceptRiskRequestFailed = createAction(RequestActions.AcceptRiskRequestFailed);

export const revokeRiskAcceptanceRequest = createAction(
    RequestActions.RevokeRiskAcceptanceRequest,
    props<{ riskReviewNote: string }>(),
);

export const revokeRiskAcceptanceRequestSuccess = createAction(RequestActions.RevokeRiskAcceptanceRequestSuccess);

export const revokeRiskAcceptanceRequestFailed = createAction(RequestActions.RevokeRiskAcceptanceRequestFailed);

export const openReviewFollowupDialog = createAction(RequestActions.OpenReviewFollowupDialog);

export const openEditFollowupMethodDialog = createAction(RequestActions.OpenEditFollowupMethodDialog);

export const completeLatestAssessmentRequest = createAction(
    RequestActions.CompleteLatestAssessmentRequest,
    props<{ relationshipId: number }>(),
);

export const completeLatestAssessmentRequestSuccess = createAction(
    RequestActions.CompleteLatestAssessmentRequestSuccess,
);

export const completeLatestAssessmentRequestFailed = createAction(RequestActions.CompleteLatestAssessmentRequestFailed);

export const sendLatestAssessmentFollowupRequest = createAction(
    RequestActions.SendLatestAssessmentFollowupRequest,
    props<{ relationshipId: number }>(),
);

export const sendLatestAssessmentFollowupRequestSuccess = createAction(
    RequestActions.SendLatestAssessmentFollowupRequestSuccess,
);

export const sendLatestAssessmentFollowupRequestFailed = createAction(
    RequestActions.SendLatestAssessmentFollowupRequestFailed,
);

export const updateFollowupMethodRequest = createAction(
    RequestActions.UpdateFollowupMethodRequest,
    props<{ relationshipId: number; followupType: FollowupType; followupRiskThreshold: Risk }>(),
);

export const updateFollowupTypeRequestSuccess = createAction(RequestActions.UpdateFollowupMethodRequestSuccess);

export const updateFollowupTypeRequestFailed = createAction(RequestActions.UpdateFollowupMethodRequestFailed);

export const combineSupplementalQuestionnaireConfiguration = createAction(
    RequestActions.CombineSupplementalQuestionnaireConfiguration,
    props<{ usingOrgDefaults: boolean; questionnaires: SupplementalQuestionnaireConfig[] }>(),
);
