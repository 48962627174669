import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { ControlDomain, ControlDomainType } from '@entities/control-domain';
import {
    ArtifactType,
    ArtifactValidationStatus,
    ControlValidation,
    ControlValidationDetectionType,
    ControlValidationStatus,
} from '@entities/artifact';
import {
    AssuranceLevels,
    ControlDomainArtifact,
    ControlDomainAssessment,
    ControlDomainAssessmentStatus,
    ControlDomainAssessmentValidationStatus,
    ControlDomainControlValidationDetectionWithArtifact,
    ControlDomainQuestionnaireArtifact,
    defaultAssuranceLevel,
    GroupedSecurityControlDomain,
    PopulatedControlDomainControlAssessment,
    SecurityControlDomain,
    SecurityControlDomainGroupStatus,
} from '@entities/relationship/models/security-control-domain';
import { AuditReportTypeCode } from '@entities/audit-report';
import { VisoUserRole } from '@entities/viso-user';
import { Relationship } from '@entities/relationship';
import { getUserAuthority } from '../../../../../routes/session/redux/session.selectors';
import { AssessmentStatus, QuestionnaireAnswerType } from '@entities/assessment';
import { ControlDetectionToggle } from '../../../../../routes/request/models';
import {
    trackSecurityControlDomainAllControlDetectionsToggle,
    trackSecurityControlDomainControlDetectionToggle,
} from '../../../../../routes/request/redux/actions/security-control-domains.actions';
import { Risk } from '@entities/risk-assessment';
import { labelControlNameForAuditor } from '../../artifacts/helper-functions';
import { FrameworkMapping, FrameworkMappingType } from '@entities/framework/models/framework-mapping.model';

@Component({
    selector: 'app-security-control-domains',
    templateUrl: './security-control-domains.component.html',
    styleUrls: ['./security-control-domains.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SecurityControlDomainsComponent implements OnInit, AfterViewInit {
    @Input()
    relationship: Relationship;

    @Input()
    selectedFramework: FrameworkMappingType;

    @Input()
    set frameworkMappings(value: FrameworkMapping) {
        this._frameworkMappings$.next(value);
    }

    @Input()
    set securityControlDomain(value: SecurityControlDomain) {
        this._securityControlDomain$.next(value);
    }

    @Input()
    set controls(value: ControlDomain[]) {
        this._controlDomains$.next(value);
    }

    @Input()
    set latestAssessmentStatus(value: AssessmentStatus) {
        this._latestAssessmentStatus$.next(value);
    }

    @Input()
    set assessmentSentToEmail(value: string) {
        this.assessmentSentToEmail$.next(value);
    }

    @Input()
    set isLatestAssessmentNonDocumentsOnly(value: boolean) {
        this.isLatestAssessmentNonDocumentsOnly$.next(value);
    }

    @Input({ required: true })
    set artifactSupersession(artifactSupersession: Map<number, number>) {
        this._supersededArtifactIds$.next(
            new Set(Array.from(artifactSupersession.entries()).map(([, supersededArtifactId]) => supersededArtifactId)),
        );
    }

    @Input()
    showValidationInProgressLabel: boolean;

    @Input()
    showOutOfScopeDomains: boolean;

    @Input()
    isStatusRiskBased: boolean;

    @Input()
    isStatusCompliantBased: boolean;

    @Input()
    isStatusInformationBased: boolean;

    @Input()
    supplementalQuestionnaireId: string;

    @Input()
    set isOutOfScope(value: boolean) {
        this._isOutOfScope$.next(value);
    }

    @Input()
    set isNotEnabled(value: boolean) {
        this._isNotEnabled$.next(value);
    }

    @Input()
    hideStatusBox: boolean;

    @Input()
    onExport: boolean;

    @Output()
    downloadSupplementalQuestionnaire = new EventEmitter<string>();

    groupedSecurityControlDomain$: Observable<GroupedSecurityControlDomain[]>;
    supplementalQuestionnaireAnswersByControlDomain$: Observable<GroupedSecurityControlDomain[]>;
    totalControlsCount$: Observable<number>;
    controlsInScopeCount$: Observable<number>;
    controlsPresentCount$: Observable<number>;
    controlsInsufficientCount$: Observable<number>;
    controlsNoInformationCount$: Observable<number>;
    controlsNotApplicableCount$: Observable<number>;
    controlsWithExceptionsCount$: Observable<number>;
    controlsWithSubservicerCount$: Observable<number>;
    controlsWithSharedResponsibilityCount$: Observable<number>;
    controlsWithCuecCount$: Observable<number>;
    controlsWithDetectionsCount$: Observable<number>;
    controlsWithoutDetectionsCount$: Observable<number>;
    noAssessment$: Observable<boolean>;
    latestAssessmentInProgress$: Observable<boolean>;
    latestAssessmentCompleted$: Observable<boolean>;
    artifactValidationInProgress$: Observable<boolean>;
    noControlsInScope$: Observable<boolean>;
    statusTemplate$: Observable<TemplateRef<any>>;
    isInformationStatus$: Observable<boolean>;
    isCompliantStatus$: Observable<boolean>;
    AssuranceLevels = AssuranceLevels;
    assessmentSentToEmail$ = new BehaviorSubject<string>(null);
    isLatestAssessmentNonDocumentsOnly$ = new BehaviorSubject<boolean>(false);

    SecurityControlDomainGroupStatus = SecurityControlDomainGroupStatus;
    QuestionnaireAnswerType = QuestionnaireAnswerType;

    private _supersededArtifactIds$ = new BehaviorSubject<Set<number>>(new Set<number>());
    private _securityControlDomain$ = new BehaviorSubject<SecurityControlDomain>(null);
    private _frameworkMappings$ = new BehaviorSubject<FrameworkMapping>(null);
    private _latestAssessmentStatus$ = new BehaviorSubject<AssessmentStatus>(null);
    private _isOutOfScope$ = new BehaviorSubject<boolean>(null);
    private _isNotEnabled$ = new BehaviorSubject<boolean>(null);
    private _controlDomains$ = new BehaviorSubject<ControlDomain[]>([]);
    @ViewChild('riskBasedStatus')
    private _riskBasedStatusTemplate: TemplateRef<any>;
    @ViewChild('compliantBasedStatus')
    private _compliantBasedStatusTemplate: TemplateRef<any>;
    @ViewChild('informationBasedStatus')
    private _informationBasedStatusTemplate: TemplateRef<any>;
    @ViewChild('outOfScopeStatus')
    private _outOfScopeStatusTemplate: TemplateRef<any>;
    @ViewChild('notEnabledStatus')
    private _notEnabledStatusTemplate: TemplateRef<any>;
    @ViewChild('supplementalQuestionnaire')
    private _supplementalQuestionnaireTemplate: TemplateRef<any>;

    get isRelationshipNoContext(): boolean {
        if (!this.relationship) {
            return true;
        }
        return this.relationship?.latestRiskAssessment?.risk === Risk.NO_CONTEXT;
    }

    get latestRiskLevel(): Risk {
        return this.relationship?.latestRiskAssessment?.risk;
    }

    constructor(private _store$: Store) {}

    ngOnInit(): void {
        this.groupedSecurityControlDomain$ = combineLatest([
            this._securityControlDomain$,
            this._controlDomains$,
            this._supersededArtifactIds$,
            this._frameworkMappings$,
        ]).pipe(
            filter(([securityControlDomain, controls]) => !!securityControlDomain && !!controls && !!controls.length),
            withLatestFrom(this._store$.pipe(select(getUserAuthority([VisoUserRole.Auditor])))),
            map(([[securityControlDomain, controlDomains, supersededArtifactIds, frameworkMappings], isAuditor]) =>
                securityControlDomain.controlDomainAssessments
                    .map((controlDomainAssessment) =>
                        !controlDomainAssessment.relevant
                            ? { ...controlDomainAssessment, status: ControlDomainAssessmentStatus.OUT_OF_SCOPE }
                            : controlDomainAssessment,
                    )
                    .concat(
                        ...controlDomains
                            .filter(
                                (controlDomain) =>
                                    !securityControlDomain.controlDomainAssessments.find(
                                        (controlDomainAssessment) =>
                                            controlDomainAssessment.controlDomainId === controlDomain.id,
                                    ),
                            )
                            .map<ControlDomainAssessment>((control) => {
                                return {
                                    assuranceLevel: null,
                                    answer: null,
                                    status:
                                        control.controlDomainType === ControlDomainType.SUPPLEMENTAL_QUESTIONNAIRE
                                            ? ControlValidationStatus.MISSING_INFORMATION
                                            : ControlDomainAssessmentStatus.DISABLED,
                                    controlAssessments: [],
                                    controlDomainId: control.id,
                                    coverage: 0,
                                    followupNeeded: false,
                                    id: null,
                                    controlName: null,
                                    longDescription: control.longDescription,
                                    individualControls: [],
                                    relevant: false,
                                    validated: false,
                                    forSuppQ: false,
                                };
                            }),
                    )
                    .filter(
                        (controlDomainAssessment) =>
                            !!controlDomains.find(
                                (controlDomain) => controlDomain.id === controlDomainAssessment.controlDomainId,
                            ),
                    )
                    .map<ControlDomainAssessment>((controlDomainAssessment) => {
                        const matchingControlDomain = controlDomains.find(
                            (controlDomain) => controlDomain.id === controlDomainAssessment.controlDomainId,
                        );

                        const questionnaireArtifacts = securityControlDomain.artifacts.filter(
                            (artifact) => artifact.type === ArtifactType.QUESTIONNAIRE_ARTIFACT,
                        );

                        const latestQuestionnaireArtifact = questionnaireArtifacts.reduce(
                            (latest, current) =>
                                current.validation?.createdDate > latest.validation?.createdDate ? current : latest,
                            questionnaireArtifacts[0],
                        ) as ControlDomainQuestionnaireArtifact | undefined;

                        const answerForControlDomain = latestQuestionnaireArtifact?.answers.find(
                            (answer) => answer.controlDomainId === matchingControlDomain.id,
                        );

                        return {
                            ...controlDomainAssessment,
                            answer: answerForControlDomain?.answer || answerForControlDomain?.mlAnswer,
                            controlName: this.isRelationshipNoContext
                                ? matchingControlDomain.name
                                : isAuditor
                                  ? labelControlNameForAuditor(matchingControlDomain)
                                  : matchingControlDomain.name,
                            status:
                                isAuditor &&
                                this.isLatestAssessmentReviewStarted() &&
                                this.isLatestAssessmentNonDocumentsOnly$.value &&
                                matchingControlDomain?.relevant &&
                                controlDomainAssessment.followupNeeded
                                    ? ControlValidationStatus.MISSING_INFORMATION
                                    : controlDomainAssessment.status,
                            forSuppQ:
                                matchingControlDomain.controlDomainType ===
                                ControlDomainType.SUPPLEMENTAL_QUESTIONNAIRE,
                        };
                    })
                    .sort((a, b) => (a.forSuppQ ? 1 : a.controlName > b.controlName ? 1 : -1)) // Bypass sort for supp q domains - they're already sorted.
                    .map<PopulatedControlDomainControlAssessment>((controlDomainAssessment) => {
                        const artifactsWithCorrespondingDomain = securityControlDomain.artifacts.filter(
                            (artifact: ControlDomainArtifact) =>
                                (artifact.validation?.detectedControls || []).some(
                                    (detectedControl) =>
                                        detectedControl.controlDomainId === controlDomainAssessment.controlDomainId,
                                ),
                        );
                        const filteredOutSupplementalQuestionnaires = artifactsWithCorrespondingDomain.filter(
                            (artifact) => {
                                if (artifact.type === ArtifactType.QUESTIONNAIRE_ARTIFACT) {
                                    return !(artifact as ControlDomainQuestionnaireArtifact).supplemental;
                                }
                                return true;
                            },
                        );
                        const filteredArtifactsWithCorrespondingDomain = filteredOutSupplementalQuestionnaires.filter(
                            (artifact) => !supersededArtifactIds.has(artifact.id),
                        );
                        const artifactValidationsWithFilteredDetections = filteredArtifactsWithCorrespondingDomain.map(
                            (artifact) => ({
                                artifact,
                                ...artifact.validation,
                                detectedControls: (artifact.validation?.detectedControls || []).filter(
                                    (detectedControl) =>
                                        detectedControl.controlDomainId === controlDomainAssessment.controlDomainId,
                                ),
                            }),
                        );
                        const detections = [...artifactValidationsWithFilteredDetections]
                            .filter((x) => !!x)
                            .reduce<ControlDomainControlValidationDetectionWithArtifact[]>(
                                (detections, artifactValidation) => [
                                    ...detections,
                                    ...artifactValidation.detectedControls
                                        .map((controlValidation) =>
                                            controlValidation.validationDetections.map<ControlDomainControlValidationDetectionWithArtifact>(
                                                (detection) => ({
                                                    ...detection,
                                                    controlId: controlValidation.controlId,
                                                    controlDomainId: controlValidation.controlDomainId,
                                                    frameworkMapping: this.generateFrameworkMappings(
                                                        controlValidation,
                                                        controlDomains,
                                                        frameworkMappings,
                                                    ),
                                                    auditReportType: artifactValidation.auditReportType,
                                                    artifact: {
                                                        ...artifactValidation.artifact,
                                                        expired: artifactValidation.expired,
                                                        expirationDate: artifactValidation.expirationDate,
                                                        validationMessage: artifactValidation.validationMessage,
                                                        subservicer: artifactValidation.subservicer,
                                                        subservicerName: artifactValidation.subservicerName,
                                                        auditReportAssurance: artifactValidation.expired
                                                            ? defaultAssuranceLevel
                                                            : artifactValidation.auditReportAssurance,
                                                        auditReportAssuranceLevel: artifactValidation.expired
                                                            ? AssuranceLevels.LIMITED
                                                            : artifactValidation.auditReportAssuranceLevel,
                                                    },
                                                }),
                                            ),
                                        )
                                        .reduce(
                                            (flatDetections, detectionArray) => [...flatDetections, ...detectionArray],
                                            [],
                                        ),
                                ],
                                [],
                            );
                        const sharedResponsibility = detections.some(
                            (detection) =>
                                detection.type === ControlValidationDetectionType.SHARED_RESPONSIBILITY_MODEL &&
                                detection.auditReportType !== AuditReportTypeCode.SUPERSEDED,
                        );

                        const exceptionsCount = detections.filter(
                            (detection) =>
                                detection.type === ControlValidationDetectionType.EXCEPTION &&
                                detection.auditReportType !== AuditReportTypeCode.SUPERSEDED,
                        ).length;

                        const subservicerCount = detections.filter(
                            (detection) =>
                                detection.type === ControlValidationDetectionType.SUBSERVICE &&
                                detection.auditReportType !== AuditReportTypeCode.SUPERSEDED,
                        ).length;

                        const sharedResponsibilityCount = detections.filter(
                            (detection) =>
                                detection.type === ControlValidationDetectionType.SHARED_RESPONSIBILITY_MODEL &&
                                detection.auditReportType !== AuditReportTypeCode.SUPERSEDED,
                        ).length;

                        const cuecCount = detections.filter(
                            (detection) =>
                                detection.type === ControlValidationDetectionType.CUEC &&
                                detection.auditReportType !== AuditReportTypeCode.SUPERSEDED,
                        ).length;

                        controlDomains.forEach((controlDomain) => {
                            if (controlDomainAssessment.controlDomainId === controlDomain.id) {
                                controlDomainAssessment.individualControls = controlDomain.controls.map((control) => {
                                    const individualControlDetections = detections.filter(
                                        (detection) => detection.controlId && detection.controlId === control.id,
                                    );
                                    return {
                                        controlId: control.id,
                                        controlName: control.name,
                                        detections: this.sortDetectionsByDetectionType(individualControlDetections),
                                        detectionsCount: individualControlDetections.length,
                                    };
                                });
                            }
                        });

                        const domainDetections = this.sortDetectionsByDetectionType(
                            detections.filter((detection) => !detection.controlId),
                        );

                        const domainDetectionsCount = domainDetections.length;
                        const individualControlDetectionsCount = controlDomainAssessment.individualControls.reduce(
                            (r, o) => r + o.detectionsCount,
                            0,
                        );
                        const detectionsTotalCount = domainDetectionsCount + individualControlDetectionsCount;

                        const allDetections = this.sortDetectionsByDetectionType(detections);

                        return {
                            ...controlDomainAssessment,
                            exceptionsCount,
                            subservicerCount,
                            sharedResponsibilityCount,
                            cuecCount,
                            detections: domainDetections,
                            allDetections,
                            sharedResponsibility,
                            detectionsTotalCount,
                        };
                    }),
            ),
            map((controlAssessments) =>
                controlAssessments.reduce(
                    (dictionary, controlAssessment) => {
                        let groupStatus: SecurityControlDomainGroupStatus;
                        if (!this.supplementalQuestionnaireId) {
                            const isCompleted = this._latestAssessmentStatus$.value === AssessmentStatus.COMPLETED;
                            const inProgress = this.isAssessmentInProgress(this._latestAssessmentStatus$.value);

                            groupStatus = this.getSecurityControlDomainGroupStatus(
                                controlAssessment.status,
                                isCompleted,
                                inProgress,
                                controlAssessment.detectionsTotalCount,
                            );
                        } else {
                            if (controlAssessment.detectionsTotalCount > 0) {
                                groupStatus = SecurityControlDomainGroupStatus.PRESENT;
                            } else {
                                groupStatus = SecurityControlDomainGroupStatus.NO_INFORMATION;
                            }
                        }

                        dictionary[groupStatus] = [...(dictionary[groupStatus] || []), controlAssessment];
                        return dictionary;
                    },
                    {} as { [key: string]: PopulatedControlDomainControlAssessment[] },
                ),
            ),
            map((controlAssessmentsDictionary) =>
                Object.keys(controlAssessmentsDictionary)
                    .map<GroupedSecurityControlDomain>((controlValidationStatus: SecurityControlDomainGroupStatus) => ({
                        controlValidationStatus,
                        controlDomainAssessments: controlAssessmentsDictionary[controlValidationStatus],
                    }))
                    .sort((a, b) =>
                        this.sortBySecurityControlDomainGroupStatus(
                            a.controlValidationStatus,
                            b.controlValidationStatus,
                        ),
                    ),
            ),
        );

        this.totalControlsCount$ = this._controlDomains$.pipe(
            map((controls) => (controls || []).filter((control) => !control.disabled).length),
        );

        this.controlsInScopeCount$ = this._controlDomains$.pipe(
            map((controls) => (controls || []).filter((control) => control.relevant && !control.disabled).length),
        );

        this.noControlsInScope$ = this.controlsInScopeCount$.pipe(map((count) => !count));

        this.controlsPresentCount$ = combineLatest([this._securityControlDomain$, this._controlDomains$]).pipe(
            filter(([securityControlDomain, controls]) => !!securityControlDomain && !!controls),
            map(
                ([securityControlDomain, controls]) =>
                    securityControlDomain.controlDomainAssessments.filter((controlAssessment) => {
                        const matchingControl = controls.find((c) => c.id === controlAssessment.controlDomainId);
                        return (
                            matchingControl?.relevant &&
                            !matchingControl?.disabled &&
                            (controlAssessment.status === ControlValidationStatus.PRESENT ||
                                controlAssessment.status === ControlValidationStatus.DESCRIPTION_ONLY)
                        );
                    }).length,
            ),
        );

        this.controlsInsufficientCount$ = combineLatest([this._securityControlDomain$, this._controlDomains$]).pipe(
            filter(([securityControlDomain, controls]) => !!securityControlDomain && !!controls),
            map(
                ([securityControlDomain, controls]) =>
                    securityControlDomain.controlDomainAssessments.filter((controlAssessment) => {
                        const matchingControl = controls.find((c) => c.id === controlAssessment.controlDomainId);
                        return (
                            matchingControl?.relevant &&
                            !matchingControl?.disabled &&
                            controlAssessment.status === ControlValidationStatus.NOT_PRESENT
                        );
                    }).length,
            ),
        );

        this.controlsNoInformationCount$ = combineLatest([this._securityControlDomain$, this._controlDomains$]).pipe(
            filter(([securityControlDomain, controls]) => !!securityControlDomain && !!controls),
            map(
                ([securityControlDomain, controls]) =>
                    securityControlDomain.controlDomainAssessments.filter((controlAssessment) => {
                        const matchingControl = controls.find((c) => c.id === controlAssessment.controlDomainId);
                        return (
                            matchingControl?.relevant &&
                            !matchingControl?.disabled &&
                            controlAssessment.status === ControlValidationStatus.UNVALIDATED
                        );
                    }).length,
            ),
        );

        this.controlsNotApplicableCount$ = combineLatest([this._securityControlDomain$, this._controlDomains$]).pipe(
            filter(([securityControlDomain, controls]) => !!securityControlDomain && !!controls),
            map(
                ([securityControlDomain, controls]) =>
                    securityControlDomain.controlDomainAssessments.filter((controlAssessment) => {
                        const matchingControl = controls.find((c) => c.id === controlAssessment.controlDomainId);
                        return (
                            matchingControl?.relevant &&
                            !matchingControl?.disabled &&
                            controlAssessment.status === ControlValidationStatus.NOT_APPLICABLE
                        );
                    }).length,
            ),
        );

        this.controlsWithExceptionsCount$ = this.groupedSecurityControlDomain$.pipe(
            filter((groupedSecurityControlDomain) => !!groupedSecurityControlDomain),
            map((groupedSecurityControlDomain) =>
                groupedSecurityControlDomain.map(
                    (group) => group.controlDomainAssessments.filter((c) => c.exceptionsCount > 0).length,
                ),
            ),
            map((exceptionsCountArray) => exceptionsCountArray.reduce((previous, current) => previous + current, 0)),
        );

        this.controlsWithSubservicerCount$ = this.groupedSecurityControlDomain$.pipe(
            filter((groupedSecurityControlDomain) => !!groupedSecurityControlDomain),
            map((groupedSecurityControlDomain) =>
                groupedSecurityControlDomain.map(
                    (group) => group.controlDomainAssessments.filter((c) => c.subservicerCount > 0).length,
                ),
            ),
            map((subservicerCountArray) => subservicerCountArray.reduce((previous, current) => previous + current, 0)),
        );

        this.controlsWithSharedResponsibilityCount$ = this.groupedSecurityControlDomain$.pipe(
            filter((groupedSecurityControlDomain) => !!groupedSecurityControlDomain),
            map((groupedSecurityControlDomain) =>
                groupedSecurityControlDomain.map(
                    (group) => group.controlDomainAssessments.filter((c) => c.sharedResponsibilityCount > 0).length,
                ),
            ),
            map((sharedResponsibilityCountArray) =>
                sharedResponsibilityCountArray.reduce((previous, current) => previous + current, 0),
            ),
        );

        this.controlsWithCuecCount$ = this.groupedSecurityControlDomain$.pipe(
            filter((groupedSecurityControlDomain) => !!groupedSecurityControlDomain),
            map((groupedSecurityControlDomain) =>
                groupedSecurityControlDomain.map(
                    (group) => group.controlDomainAssessments.filter((c) => c.cuecCount > 0).length,
                ),
            ),
            map((cuecCountArray) => cuecCountArray.reduce((previous, current) => previous + current, 0)),
        );

        this.controlsWithDetectionsCount$ = this.groupedSecurityControlDomain$.pipe(
            filter(Boolean),
            map((groupedSecurityControlDomain) =>
                groupedSecurityControlDomain.reduce(
                    (totalSum, group) =>
                        totalSum +
                        group.controlDomainAssessments.filter((assessment) => assessment.detectionsTotalCount > 0)
                            .length,
                    0,
                ),
            ),
        );

        this.controlsWithoutDetectionsCount$ = this.groupedSecurityControlDomain$.pipe(
            filter(Boolean),
            map((groupedSecurityControlDomain) =>
                groupedSecurityControlDomain.reduce(
                    (totalSum, group) =>
                        totalSum +
                        group.controlDomainAssessments.filter((assessment) => assessment.detectionsTotalCount === 0)
                            .length,
                    0,
                ),
            ),
        );

        this.noAssessment$ = this._latestAssessmentStatus$.pipe(map((assessment) => !assessment));
        this.latestAssessmentInProgress$ = this._latestAssessmentStatus$.pipe(
            map((assessmentStatus) => this.isAssessmentInProgress(assessmentStatus)),
        );
        this.latestAssessmentCompleted$ = this._latestAssessmentStatus$.pipe(
            map((assessmentStatus) => assessmentStatus === AssessmentStatus.COMPLETED),
        );

        this.artifactValidationInProgress$ = this._securityControlDomain$.pipe(
            filter((securityControlDomain) => !!securityControlDomain),
            map((securityControlDomain) =>
                securityControlDomain.artifacts.some(
                    (a) => a.validation?.status === ArtifactValidationStatus.IN_PROGRESS,
                ),
            ),
        );

        this.isInformationStatus$ = this.groupedSecurityControlDomain$.pipe(
            map((groups) =>
                groups.every((group) =>
                    [
                        SecurityControlDomainGroupStatus.PRESENT,
                        SecurityControlDomainGroupStatus.OUT_OF_SCOPE,
                        SecurityControlDomainGroupStatus.DISABLED,
                        SecurityControlDomainGroupStatus.NOT_APPLICABLE,
                    ].includes(group.controlValidationStatus),
                ),
            ),
        );

        this.isCompliantStatus$ = this.groupedSecurityControlDomain$.pipe(
            map((groups) =>
                groups.every((group) =>
                    [
                        SecurityControlDomainGroupStatus.PRESENT,
                        SecurityControlDomainGroupStatus.OUT_OF_SCOPE,
                        SecurityControlDomainGroupStatus.DISABLED,
                        SecurityControlDomainGroupStatus.NOT_APPLICABLE,
                    ].includes(group.controlValidationStatus),
                ),
            ),
        );
    }

    ngAfterViewInit(): void {
        this.statusTemplate$ = combineLatest([this._isOutOfScope$, this._isNotEnabled$]).pipe(
            map(([isOutOfScope, isNotEnabled]) => {
                if (isNotEnabled) {
                    return this._notEnabledStatusTemplate;
                }
                if (isOutOfScope) {
                    return this._outOfScopeStatusTemplate;
                }
                if (this.isStatusRiskBased) {
                    return this._riskBasedStatusTemplate;
                }
                if (this.isStatusCompliantBased) {
                    return this._compliantBasedStatusTemplate;
                }
                if (this.isStatusInformationBased) {
                    return this._informationBasedStatusTemplate;
                }
                if (!!this.supplementalQuestionnaireId) {
                    return this._supplementalQuestionnaireTemplate;
                }
            }),
        );
    }

    accordionToggled(payload: ControlDetectionToggle): void {
        this.trackControlDetectionToggle(payload);
    }

    trackGroupedSecurityControlDomain(index: number, group: GroupedSecurityControlDomain): string {
        return group.controlValidationStatus;
    }

    private getSecurityControlDomainGroupStatus(
        controlDomainAssessmentValidationStatus: ControlDomainAssessmentValidationStatus,
        isLatestAssessmentCompleted: boolean,
        isLatestAssessmentInProgress: boolean,
        detectionsTotalCount: number,
    ): SecurityControlDomainGroupStatus {
        switch (controlDomainAssessmentValidationStatus) {
            case ControlValidationStatus.PRESENT:
            case ControlValidationStatus.DESCRIPTION_ONLY:
                return SecurityControlDomainGroupStatus.PRESENT;
            case ControlValidationStatus.NOT_PRESENT:
                return SecurityControlDomainGroupStatus.INSUFFICIENT;
            case ControlValidationStatus.NOT_APPLICABLE:
                return SecurityControlDomainGroupStatus.NOT_APPLICABLE;
            case ControlValidationStatus.MISSING_INFORMATION:
                return isLatestAssessmentCompleted
                    ? SecurityControlDomainGroupStatus.NO_INFORMATION
                    : SecurityControlDomainGroupStatus.MISSING_INFORMATION;
            case ControlValidationStatus.UNVALIDATED:
                return isLatestAssessmentInProgress && detectionsTotalCount === 0
                    ? SecurityControlDomainGroupStatus.UNVALIDATED
                    : SecurityControlDomainGroupStatus.NO_INFORMATION;
            case ControlDomainAssessmentStatus.OUT_OF_SCOPE:
                return SecurityControlDomainGroupStatus.OUT_OF_SCOPE;
            case ControlDomainAssessmentStatus.DISABLED:
                return SecurityControlDomainGroupStatus.DISABLED;
            default:
                return;
        }
    }

    private sortBySecurityControlDomainGroupStatus(
        groupStatusA: SecurityControlDomainGroupStatus,
        groupStatusB: SecurityControlDomainGroupStatus,
    ): number {
        const [groupStatusAValue, groupStatusBValue] = [groupStatusA, groupStatusB].map((groupStatus) =>
            Object.keys(SecurityControlDomainGroupStatus).indexOf(groupStatus),
        );
        return groupStatusAValue - groupStatusBValue;
    }

    private isLatestAssessmentReviewStarted(): boolean {
        return this._latestAssessmentStatus$.value === AssessmentStatus.REVIEW_STARTED;
    }

    private trackControlDetectionToggle({ controlId, controlName, opened }: ControlDetectionToggle): void {
        if (!this.relationship) {
            return;
        }
        const {
            clientId: clientId,
            clientName: clientName,
            id: relationshipId,
            vendorName: relationshipName,
        } = this.relationship;
        this._store$.dispatch(
            trackSecurityControlDomainControlDetectionToggle({
                controlId,
                controlName,
                opened,
                clientId,
                clientName,
                relationshipId,
                relationshipName,
            }),
        );
    }

    // @ts-ignore
    private trackAllControlDetectionsToggle(opened: boolean): void {
        if (!this.relationship) {
            return;
        }
        const {
            clientId: clientId,
            clientName: clientName,
            id: relationshipId,
            vendorName: relationshipName,
        } = this.relationship;
        this._store$.dispatch(
            trackSecurityControlDomainAllControlDetectionsToggle({
                opened,
                clientId,
                clientName,
                relationshipId,
                relationshipName,
            }),
        );
    }

    private isAssessmentInProgress(assessmentStatus: AssessmentStatus): boolean {
        return [
            AssessmentStatus.STARTED,
            AssessmentStatus.COLLECTING_INFORMATION,
            AssessmentStatus.REVIEW_STARTED,
        ].includes(assessmentStatus);
    }

    private sortDetectionsByDetectionType(
        detections: ControlDomainControlValidationDetectionWithArtifact[],
    ): ControlDomainControlValidationDetectionWithArtifact[] {
        const detectionsOrder = [
            ControlValidationDetectionType.EXCEPTION,
            ControlValidationDetectionType.SUBSERVICE,
            ControlValidationDetectionType.NORMAL,
            ControlValidationDetectionType.SHARED_RESPONSIBILITY_MODEL,
            ControlValidationDetectionType.CUEC,
        ];
        return [...detections].sort((detection1, detection2) =>
            detectionsOrder.indexOf(detection1.type) > detectionsOrder.indexOf(detection2.type)
                ? 1
                : detectionsOrder.indexOf(detection1.type) < detectionsOrder.indexOf(detection2.type)
                  ? -1
                  : 0,
        );
    }

    private generateFrameworkMappings(
        controlValidation: ControlValidation,
        controlDomains: ControlDomain[],
        frameworkMappings: FrameworkMapping,
    ): string {
        return (
            !!controlValidation.controlId
                ? [controlValidation.controlId]
                : controlDomains
                      .find((controlDomain) => controlDomain.id === controlValidation.controlDomainId)
                      .controls.map((control) => control.id)
        )
            .map((controlId) => (frameworkMappings || {})[controlId])
            .filter((x) => !!x)
            .map((frameworkMapping) => frameworkMapping.split(', '))
            .flat()
            .filter((value, index, self) => self.indexOf(value) === index)
            .sort((a, b) => a.localeCompare(b))
            .join(', ');
    }
}
