import { ErrorKey } from '@shared/model/error-keys';

export enum AuthenticationFailedReasons {
    UNKNOWN,
    EXPIRED = ErrorKey.ASSESSMENT_EXPIRED,
    INVALID_SECRET = 'INVALID_SECRET',
}

export enum StepIds {
    WELCOME,
    INITIAL_ARTIFACT_UPLOAD,
    ADDITIONAL_INFORMATION,
    SUBMIT,
}

export enum AdditionalInformationSubSteps {
    UPLOAD_RECOMMENDATIONS,
    EXPIRED_DOCUMENTS,
    ANSWER_QUESTIONNAIRE,
}

export enum AssessmentSubmissionContext {
    FILE_ARTIFACTS = 'FILE_ARTIFACTS',
    QUESTIONNAIRE = 'QUESTIONNAIRE',
    FOLLOWUP_QUESTIONNAIRE = 'FOLLOWUP_QUESTIONNAIRE',
}
